import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import 'react-toastify/dist/ReactToastify.css'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import RcmStepper from './RcmStepper'
import { RcmSideHeader } from './RcmComponents'

export default function RcmViewStage3() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('fetchRcm', fetchRcm)
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number

  const [equipment, setEquipment]: any = useState(
    [
      ...(currentAnalysisData?.equipmentList || []),
      ...(currentAnalysisData?.itemsList || []),
      ...(currentAnalysisData?.tagsList || [])
    ]?.map((i: any) => ({
      equipmentNumber: i?.equipmentNumber || i?.itemNumber || i?.tagNo,
      equipmentDescription:
        i?.equipmentDescription || i?.itemDescription || i?.tagDescription,
      manufacturer: i?.manufacturer,
      criticality: i?.criticality,
      type: i?.equipmentNumber
        ? 'Equipment'
        : i?.itemNumber
        ? 'Maintainable Item'
        : i?.tagNo
        ? 'Tag'
        : ''
    }))
  )

  useEffect(
    () => {
      if (equipment?.length === 0) {
        setEquipment(
          [
            ...(currentAnalysisData?.equipmentList || []),
            ...(currentAnalysisData?.itemsList || []),
            ...(currentAnalysisData?.tagsList || [])
          ]?.map((i: any) => ({
            equipmentNumber: i?.equipmentNumber || i?.itemNumber || i?.tagNo,
            equipmentDescription:
              i?.equipmentDescription ||
              i?.itemDescription ||
              i?.tagDescription,
            manufacturer: i?.manufacturer,
            criticality: i?.criticality,
            type: i?.equipmentNumber
              ? 'Equipment'
              : i?.itemNumber
              ? 'Maintainable Item'
              : i?.tagNo && 'Tag'
          }))
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data?.analysis?.equipmentList,
      data?.analysis?.itemsList,
      data?.analysis?.tagsList
    ]
  )

  const handleDropdownChange = (e: any, index: any, key: any) => {
    const newEquipment = [...equipment]
    newEquipment[index][key] = e.target.value
    setEquipment(newEquipment)
  }

  const categorizeEquipment = () => {
    const categorized: any = {}

    equipment.forEach((item: any) => {
      const subSystemKey = item.subSystem || 'Compressor'
      if (!categorized[subSystemKey]) {
        categorized[subSystemKey] = []
      }
      categorized[subSystemKey].push(item)
    })

    return categorized
  }

  const functionNames = () => {
    const categorized: any = {}

    equipment.forEach((item: any) => {
      const subSystemKey = item.subSystem || 'Centrifugal Compressor'
      if (!categorized[subSystemKey]) {
        categorized[subSystemKey] = []
      }
      categorized[subSystemKey] = item?.function
    })

    return categorized
  }

  if (isLoading) {
    return (
      <RcmLayout onBack={'/cmms/rcm'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh'
          }}
        >
          <Spinner />
        </div>
      </RcmLayout>
    )
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={10} />
          <RcmSideHeader children="Define Functions" />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            <Table className="min-vh-50">
              <thead>
                <tr
                  style={{ backgroundColor: 'lightgray' }}
                  className="text-center"
                >
                  <th className="top-left">S.No</th>
                  {[
                    'Equipment No',
                    'Equipment Description',
                    'Equipment /Item  /Tag Type'
                  ]?.map((h: any) => <th key={h}>{h}</th>)}
                  <th className="top-right">Function</th>
                </tr>
              </thead>
              <tbody>
                {equipment?.map((entry: any, Eindex: number) => (
                  <tr key={entry?.equipmentNumber}>
                    <td className="text-center">{Eindex + 1}</td>
                    <td style={{ width: '8vw' }}>{entry?.equipmentNumber}</td>
                    <td>{entry?.equipmentDescription}</td>
                    <td className="text-center">
                      <Input
                        type="select"
                        value={entry?.subSystem}
                        onChange={(e) =>
                          handleDropdownChange(e, Eindex, 'subSystem')
                        }
                      >
                        <option value={undefined}>Select</option>
                        {[
                          'BAYLOR GENERATOR',
                          'CAT 3516B Engine',
                          'Engine Starter System',
                          'Engine Fuel System',
                          'Engine Exhaust System',
                          'Engine Lube Oil System',
                          'Engine Cooling System',
                          'Engine Safety Devices',
                          'Generator System',
                          'Generator Protective Devices',
                          'Generator Protective Device',
                          'CAT Generator',
                          'Compressor',
                          'Antisurge System',
                          'Actuating device',
                          'Control System',
                          'Control Valves',
                          'Lubrication System',
                          'Coupling to driver',
                          'Pump',
                          'Motor',
                          'Coolers',
                          'Instrument, flow',
                          'Instrument, general',
                          'Instrument, level',
                          'Instrument, pressure',
                          'Instrument, speed',
                          'Instrument, temperature',
                          'Instrument, vibration'
                        ]?.map((o: any) => (
                          <option key={o} value={o}>
                            {o}
                          </option>
                        ))}
                      </Input>
                    </td>
                    <td>
                      <Input
                        type="textarea"
                        style={{ height: 40 }}
                        onChange={(e) =>
                          handleDropdownChange(e, Eindex, 'function')
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {currentAnalysisData?.status === 'Stage3' && (
            <ModalFooter>
              <Button
                color="warning"
                onClick={
                  () => setSubmit(true)
                  // : toast('Please Select Subsystem for all Equipment')
                }
              >
                Submit
              </Button>
            </ModalFooter>
          )}
          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()
              const body: any = {
                // equipmentList: equipment,
                categorizedEquipment: categorizeEquipment(),
                status: 'Stage4',
                functions: functionNames()
              }
              console.log(body)
              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Define Function"
          />
        </Col>
      </Row>
      <ToastContainer
        theme="dark"
        position="top-center"
        style={{ fontSize: 16 }}
      />
    </RcmLayout>
  )
}
