import { Row, Spinner } from 'reactstrap'
import RcmLayout from '../../components/rcm/RcmLayout'

import React from 'react'

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie
} from 'recharts'

import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { useQuery } from 'react-query'
import { theme } from './constants/brandkit'
import { RcmSideHeader } from './RcmComponents'

const BarCharts = () => {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100
    }
  ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="pv" fill="#8884d8" />
        <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomPieChart = () => {
  const { accounts, instance } = useMsal()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading }: any = useQuery('fetchRcmCounts', fetchRcm)

  const { Stage2, Stage3, Stage4, Stage5, Stage6, Stage7 } =
    data?.countsByStatus || {}

  const pieData = [
    // { name: 'Created', value: Stage2 },
    { name: 'Stage 2', value: Stage2 || 0 },
    { name: 'Stage 3', value: Stage3 || 0 },
    { name: 'Stage 4', value: Stage4 || 0 },
    { name: 'Stage 5', value: Stage5 || 0 },
    { name: 'Stage 6', value: Stage6 || 0 },
    { name: 'Stage 7', value: Stage7 || 0 }
  ]

  const COLORS = [
    '#8884d8',
    '#82ca9d',
    '#ffc658',
    '#ff6b6b',
    '#4e79a7',
    '#f28e2b',
    '#76b7b2'
  ]

  return (
    <ResponsiveContainer width="50%" height={360}>
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          // label={({ name, value }) => `${name}: ${value}`}
          labelLine={false}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* <Tooltip /> */}
        {/* <Legend layout="horizontal" align="center" verticalAlign="bottom" /> */}
      </PieChart>
    </ResponsiveContainer>
  )
}

const StatsCounter = ({ data }: { data: any }) => {
  return (
    <div
      style={{
        height: '25vh',
        width: '25vh',
        border: `15px solid ${theme.colors.white}`,
        backgroundColor: theme.colors.accent,
        borderRadius: theme.corners.sm,
        boxShadow:
          'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
      }}
    >
      <div
        style={{
          height: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 100,
          padding: 10,
          color: '#000',
          opacity: 0.9
        }}
      >
        <p className="m-0">{data?.value || <Spinner />}</p>
      </div>
      <div
        style={{
          height: '20%',
          backgroundColor: theme.colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p
          className="m-0"
          style={{ color: theme.colors.accent, fontSize: 18, fontWeight: 900 }}
        >
          {data?.label?.toUpperCase()}
        </p>
      </div>
    </div>
  )
}

const Graph = ({ data }: { data: any }) => {
  return (
    <div
      style={{
        height: '35vh',
        width: '35vw',
        border: `35px solid ${theme.colors.white}`,
        backgroundColor: theme.colors.accent,
        borderRadius: theme.corners.sm,
        boxShadow:
          'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
      }}
    >
      <div
        style={{
          height: '15%',
          backgroundColor: theme.colors.primary,
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p
          className="m-0"
          style={{ color: theme.colors.accent, fontSize: 18, fontWeight: 900 }}
        >
          {data?.label?.toUpperCase()}
        </p>
      </div>
      <div
        style={{
          height: '85%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 100,
          padding: 10,
          color: '#000',
          opacity: 0.9
        }}
      >
        <img
          src={require('./../../images/bar chart.jpeg')}
          alt="graph"
          style={{ height: '100%' }}
        />
      </div>
    </div>
  )
}

const PieGraph = ({ data }: { data: any }) => {
  return (
    <div
      style={{
        height: '35vh',
        width: '35vw',
        border: `35px solid ${theme.colors.white}`,
        backgroundColor: theme.colors.accent,
        borderRadius: theme.corners.sm,
        boxShadow:
          'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
      }}
    >
      <div
        style={{
          height: '15%',
          backgroundColor: theme.colors.primary,
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p
          className="m-0"
          style={{ color: theme.colors.accent, fontSize: 18, fontWeight: 900 }}
        >
          {data?.label?.toUpperCase()}
        </p>
      </div>
      <div
        style={{
          height: '85%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 100,
          padding: 10,
          color: '#000',
          opacity: 0.9
        }}
      >
        {/* <img
          src={require('./../../images/bar chart.jpeg')}
          alt="graph"
          style={{ height: '100%' }}
        /> */}
        <CustomPieChart />
      </div>
    </div>
  )
}

export default function RcmKpi() {
  const { accounts, instance } = useMsal()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/counts`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, isLoading }: any = useQuery('fetchRcmCounts', fetchRcm)

  const { Stage9, totalFailureModes, totalTasks } = data?.countsByStatus || {}

  const countersData = [
    { label: 'Completed Analysis', value: Stage9 },
    {
      label: 'Failure Mode Analysed',
      value: totalFailureModes?.[0]?.totalFailureModes
    },
    { label: 'Task Analysed', value: totalTasks?.[0]?.totalTasks },
    { label: 'Cost Optimised', value: '20%' }
  ]

  const graphData = [
    { label: 'Risk Score Comparision for Failure Mode', value: <BarCharts /> },
    {
      label: 'Tolerable Vs Untolerable Failure Modes',
      value: totalFailureModes?.[0]?.totalFailureModes
    }
  ]

  const pieData = [
    { label: 'TASK BREAKUP', value: <BarCharts /> },
    {
      label: 'TASK STATUS',
      value: totalFailureModes?.[0]?.totalFailureModes
    }
  ]

  return (
    <RcmLayout onBack="/cmms/rcm">
      <Row style={{ margin: 15 }}>
        <RcmSideHeader>KPI</RcmSideHeader>
        <div
          style={{
            margin: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '80vw',
            overflow: 'hidden',
            backgroundColor: theme.colors.white,
            borderRadius: theme.corners.sm
          }}
        >
          <div
            style={{
              margin: 20,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: theme.colors.primaryLight,
              borderRadius: theme.corners.sm,
              padding: 30,
              width: '-webkit-fill-available'
            }}
          >
            {/* <CustomPieChart /> */}
            {countersData?.map((cd: any) => (
              <StatsCounter key={cd?.label} data={cd} />
            ))}
          </div>
        </div>
        <div
          style={{
            margin: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '80vw',
            overflow: 'hidden',
            backgroundColor: theme.colors.white,
            borderRadius: theme.corners.sm
          }}
        >
          <div
            style={{
              margin: 20,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: theme.colors.primaryLight,
              borderRadius: theme.corners.sm,
              padding: 30,
              width: '-webkit-fill-available'
            }}
          >
            {graphData?.map((cd: any) => <Graph key={cd?.label} data={cd} />)}
          </div>
        </div>
        <div
          style={{
            margin: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '80vw',
            overflow: 'hidden',
            backgroundColor: theme.colors.white,
            borderRadius: theme.corners.sm
          }}
        >
          <div
            style={{
              margin: 20,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: theme.colors.primaryLight,
              borderRadius: theme.corners.sm,
              padding: 30,
              width: '-webkit-fill-available'
            }}
          >
            {pieData?.map((cd: any) => <PieGraph key={cd?.label} data={cd} />)}
          </div>
        </div>
      </Row>
    </RcmLayout>
  )
}
