export const chartPoints = [
  {
    id: 'EFFECTIVENESS_CRITERIA',
    tagName: 'image',
    x: '241',
    y: '3464',
    height: '1016',
    width: '1460'
  },
  {
    id: 'ES_E1',
    tagName: 'image',
    x: '246',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'EO1',
    tagName: 'image',
    x: '762',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'ENO1',
    tagName: 'image',
    x: '1280',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HS_E1',
    tagName: 'image',
    x: '3103',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HO1',
    tagName: 'image',
    x: '3619',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HNO1',
    tagName: 'image',
    x: '4137',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'TASK1',
    tagName: 'image',
    x: '4723',
    y: '1487',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK2',
    tagName: 'image',
    x: '4723',
    y: '2133',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK3',
    tagName: 'image',
    x: '4723',
    y: '2779',
    height: '584',
    width: '732'
  },
  {
    id: 'path-22',
    tagName: 'path'
  },
  {
    id: 'path-21',
    tagName: 'path'
  },
  {
    id: 'path-20',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3',
    tagName: 'path'
  },
  {
    id: 'QE3Y',
    tagName: 'use',
    x: '936',
    y: '1438'
  },
  {
    id: 'QE2Y',
    tagName: 'use',
    x: '290',
    y: '1438'
  },
  {
    id: 'QE11Y',
    tagName: 'use',
    x: '288',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy',
    tagName: 'path'
  },
  {
    id: 'path-19',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9',
    tagName: 'path'
  },
  {
    id: 'Shape_4_copy',
    tagName: 'path'
  },
  {
    id: 'path-18',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_7',
    tagName: 'path'
  },
  {
    id: 'QE4Y',
    tagName: 'use',
    x: '1062',
    y: '1438'
  },
  {
    id: 'QE11N',
    tagName: 'use',
    x: '538',
    y: '3006'
  },
  {
    id: 'QE2N',
    tagName: 'use',
    x: '680',
    y: '1258'
  },
  {
    id: 'QE3N',
    tagName: 'use',
    x: '1198',
    y: '1258'
  },
  {
    id: 'QE4N',
    tagName: 'use',
    x: '1470',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4',
    tagName: 'path'
  },
  {
    id: 'path-17',
    tagName: 'path'
  },
  {
    id: 'Triangle_1',
    tagName: 'path'
  },
  {
    id: 'QE5N',
    tagName: 'use',
    x: '540',
    y: '1948'
  },
  {
    id: 'QE5Y',
    tagName: 'use',
    x: '454',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2',
    tagName: 'path'
  },
  {
    id: 'AE1',
    tagName: 'use',
    x: '260',
    y: '1778'
  },
  {
    id: 'Group_4_copy',
    tagName: 'g'
  },
  {
    id: 'path-16',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-2',
    tagName: 'path'
  },
  {
    id: 'QE6N',
    tagName: 'use',
    x: '1062',
    y: '1948'
  },
  {
    id: 'QE6Y',
    tagName: 'use',
    x: '976',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-2',
    tagName: 'path'
  },
  {
    id: 'AE2',
    tagName: 'use',
    x: '782',
    y: '1778'
  },
  {
    id: 'path-15',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-3',
    tagName: 'path'
  },
  {
    id: 'QE7N',
    tagName: 'use',
    x: '1584',
    y: '1948'
  },
  {
    id: 'QE7Y',
    tagName: 'use',
    x: '1498',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-3',
    tagName: 'path'
  },
  {
    id: 'AE3',
    tagName: 'use',
    x: '1304',
    y: '1778'
  },
  {
    id: 'path-14',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-4',
    tagName: 'path'
  },
  {
    id: 'QE10N',
    tagName: 'use',
    x: '1584',
    y: '2462'
  },
  {
    id: 'QE10Y',
    tagName: 'use',
    x: '1498',
    y: '2330'
  },
  {
    id: 'AE6',
    tagName: 'use',
    x: '1304',
    y: '2292'
  },
  {
    id: 'path-13',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-5',
    tagName: 'path'
  },
  {
    id: 'QE9N',
    tagName: 'use',
    x: '1062',
    y: '2462'
  },
  {
    id: 'QE9Y',
    tagName: 'use',
    x: '976',
    y: '2330'
  },
  {
    id: 'path-12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-6',
    tagName: 'path'
  },
  {
    id: 'QE8N',
    tagName: 'use',
    x: '540',
    y: '2462'
  },
  {
    id: 'QE8Y',
    tagName: 'use',
    x: '454',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-4',
    tagName: 'path'
  },
  {
    id: 'AE4',
    tagName: 'use',
    x: '260',
    y: '2292'
  },
  {
    id: 'AE5',
    tagName: 'use',
    x: '782',
    y: '2292'
  },
  {
    id: 'path-11',
    tagName: 'path'
  },
  {
    id: 'path-10',
    tagName: 'path'
  },
  {
    id: 'path-9',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3-2',
    tagName: 'path'
  },
  {
    id: 'QH3Y',
    tagName: 'use',
    x: '3796',
    y: '1438'
  },
  {
    id: 'QH2Y',
    tagName: 'use',
    x: '3150',
    y: '1438'
  },
  {
    id: 'QH11Y',
    tagName: 'use',
    x: '3148',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy-2',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9-2',
    tagName: 'path'
  },
  {
    id: 'Shape_5_copy',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_10',
    tagName: 'path'
  },
  {
    id: 'QH12Y',
    tagName: 'use',
    x: '3668',
    y: '3008'
  },
  {
    id: 'Shape_5_copy_2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_11',
    tagName: 'path'
  },
  {
    id: 'QH13Y',
    tagName: 'use',
    x: '4194',
    y: '3008'
  },
  {
    id: 'Shape_4_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-7',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_13',
    tagName: 'path'
  },
  {
    id: 'QH4Y',
    tagName: 'use',
    x: '3922',
    y: '1438'
  },
  {
    id: 'QH11N',
    tagName: 'use',
    x: '3398',
    y: '3006'
  },
  {
    id: 'QH12N',
    tagName: 'use',
    x: '3930',
    y: '3006'
  },
  {
    id: 'QH13N',
    tagName: 'use',
    x: '4456',
    y: '3006'
  },
  {
    id: 'QH2N',
    tagName: 'use',
    x: '3540',
    y: '1258'
  },
  {
    id: 'QH3N',
    tagName: 'use',
    x: '4058',
    y: '1258'
  },
  {
    id: 'QH4N',
    tagName: 'use',
    x: '4330',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4-2',
    tagName: 'path'
  },
  {
    id: 'path-6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-7',
    tagName: 'path'
  },
  {
    id: 'QH5N',
    tagName: 'use',
    x: '3400',
    y: '1948'
  },
  {
    id: 'QH5Y',
    tagName: 'use',
    x: '3314',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-5',
    tagName: 'path'
  },
  {
    id: 'AH1',
    tagName: 'use',
    x: '3120',
    y: '1778'
  },
  {
    id: 'path-5',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-8',
    tagName: 'path'
  },
  {
    id: 'QH6N',
    tagName: 'use',
    x: '3922',
    y: '1948'
  },
  {
    id: 'QH6Y',
    tagName: 'use',
    x: '3836',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-6',
    tagName: 'path'
  },
  {
    id: 'AH2',
    tagName: 'use',
    x: '3642',
    y: '1778'
  },
  {
    id: 'path-4',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-9',
    tagName: 'path'
  },
  {
    id: 'QH7N',
    tagName: 'use',
    x: '4444',
    y: '1948'
  },
  {
    id: 'QH7Y',
    tagName: 'use',
    x: '4358',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-7',
    tagName: 'path'
  },
  {
    id: 'AH3',
    tagName: 'use',
    x: '4164',
    y: '1778'
  },
  {
    id: 'path-3',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-10',
    tagName: 'path'
  },
  {
    id: 'QH10N',
    tagName: 'use',
    x: '4444',
    y: '2462'
  },
  {
    id: 'QH10Y',
    tagName: 'use',
    x: '4358',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-8',
    tagName: 'path'
  },
  {
    id: 'AH6',
    tagName: 'use',
    x: '4164',
    y: '2292'
  },
  {
    id: 'path-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-11',
    tagName: 'path'
  },
  {
    id: 'QH9N',
    tagName: 'use',
    x: '3922',
    y: '2462'
  },
  {
    id: 'QH9Y',
    tagName: 'use',
    x: '3836',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-9',
    tagName: 'path'
  },
  {
    id: 'AH5',
    tagName: 'use',
    x: '3642',
    y: '2292'
  },
  {
    id: 'path',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-12',
    tagName: 'path'
  },
  {
    id: 'QH8N',
    tagName: 'use',
    x: '3400',
    y: '2462'
  },
  {
    id: 'QH8Y',
    tagName: 'use',
    x: '3314',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-10',
    tagName: 'path'
  },
  {
    id: 'AH4',
    tagName: 'use',
    x: '3120',
    y: '2292'
  },
  {
    id: 'Q1N',
    tagName: 'image',
    x: '3128',
    y: '820',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1Y',
    tagName: 'image',
    x: '942',
    y: '824',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1',
    tagName: 'image',
    x: '1774',
    y: '724',
    height: '438',
    width: '1252'
  },
  {
    id: 'QE4',
    tagName: 'image',
    x: '1283',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE3',
    tagName: 'image',
    x: '765',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE2',
    tagName: 'image',
    x: '247',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH4',
    tagName: 'image',
    x: '4139',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH3',
    tagName: 'image',
    x: '3621',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH2',
    tagName: 'image',
    x: '3103',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE5',
    tagName: 'use',
    x: '246',
    y: '1578'
  },
  {
    id: 'QE6',
    tagName: 'use',
    x: '764',
    y: '1578'
  },
  {
    id: 'QE7',
    tagName: 'use',
    x: '1282',
    y: '1578'
  },
  {
    id: 'QH5',
    tagName: 'use',
    x: '3100',
    y: '1578'
  },
  {
    id: 'QH6',
    tagName: 'use',
    x: '3618',
    y: '1578'
  },
  {
    id: 'QH7',
    tagName: 'use',
    x: '4136',
    y: '1578'
  },
  {
    id: 'QE8',
    tagName: 'use',
    x: '246',
    y: '2092'
  },
  {
    id: 'QE9',
    tagName: 'use',
    x: '764',
    y: '2092'
  },
  {
    id: 'QH8',
    tagName: 'use',
    x: '3100',
    y: '2092'
  },
  {
    id: 'QH9',
    tagName: 'use',
    x: '3618',
    y: '2092'
  },
  {
    id: 'QH10',
    tagName: 'use',
    x: '4136',
    y: '2092'
  },
  {
    id: 'QE11',
    tagName: 'image',
    x: '246',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH11',
    tagName: 'image',
    x: '3100',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH12',
    tagName: 'use',
    x: '3618',
    y: '2606'
  },
  {
    id: 'QH13',
    tagName: 'use',
    x: '4136',
    y: '2606'
  },
  {
    id: 'AE7',
    tagName: 'image',
    x: '190',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE8',
    tagName: 'use',
    x: '450',
    y: '3134'
  },
  {
    id: 'AE9',
    tagName: 'image',
    x: '850',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE10',
    tagName: 'image',
    x: '1364',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH7',
    tagName: 'use',
    x: '3056',
    y: '3134'
  },
  {
    id: 'AH8',
    tagName: 'use',
    x: '3316',
    y: '3134'
  },
  {
    id: 'AH9',
    tagName: 'image',
    x: '3576',
    y: '3134',
    height: '158',
    width: '246'
  },

  {
    id: 'AH12',
    tagName: 'image',
    x: '4368',
    y: '3436',
    height: '158',
    width: '246'
  },
  {
    id: 'ES_E',
    tagName: 'image',
    x: '245',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EO',
    tagName: 'image',
    x: '763',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'ENO',
    tagName: 'image',
    x: '1281',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EVIDENT-2',
    tagName: 'image',
    x: '249',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'HS_E',
    tagName: 'image',
    x: '3103',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HO',
    tagName: 'image',
    x: '3621',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HNO',
    tagName: 'image',
    x: '4139',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HIDDEN-2',
    tagName: 'image',
    x: '3107',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'E-SE-L1-T1',
    tagName: 'image',
    x: '245',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L1-T1',
    tagName: 'image',
    x: '763',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L1-T1',
    tagName: 'image',
    x: '1281',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L2-T1',
    tagName: 'image',
    x: '245',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L2-T1',
    tagName: 'image',
    x: '763',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L2-T1',
    tagName: 'image',
    x: '1281',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L4-T2',
    tagName: 'image',
    x: '833',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L4-T2',
    tagName: 'image',
    x: '1349',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T1',
    tagName: 'image',
    x: '157',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T2',
    tagName: 'image',
    x: '449',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L1-T1',
    tagName: 'image',
    x: '3099',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L1-T1',
    tagName: 'image',
    x: '3617',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L1-T1',
    tagName: 'image',
    x: '4135',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L2-T1',
    tagName: 'image',
    x: '3099',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L2-T1',
    tagName: 'image',
    x: '3617',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L2-T1',
    tagName: 'image',
    x: '4135',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L3-T1',
    tagName: 'image',
    x: '3060',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-SE-L3-T2',
    tagName: 'image',
    x: '3315',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L3-T1',
    tagName: 'image',
    x: '3580',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L3-T1',
    tagName: 'image',
    x: '4100',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L4-T2',
    tagName: 'image',
    x: '3575',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L4-T2',
    tagName: 'image',
    x: '4119',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'EFFECTIVENESS_CRITERIA',
    tagName: 'image',
    x: '241',
    y: '3464',
    height: '1016',
    width: '1460'
  },
  {
    id: 'ES_E1',
    tagName: 'image',
    x: '246',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'EO1',
    tagName: 'image',
    x: '762',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'ENO1',
    tagName: 'image',
    x: '1280',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HS_E1',
    tagName: 'image',
    x: '3103',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HO1',
    tagName: 'image',
    x: '3619',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HNO1',
    tagName: 'image',
    x: '4137',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'TECHNICAL_FEASIBILITY_CRITERIA_-_Smart_Object_Group',
    tagName: 'image',
    x: '4722',
    y: '1122',
    height: '353',
    width: '736'
  },
  {
    id: 'TASK1',
    tagName: 'image',
    x: '4723',
    y: '1487',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK2',
    tagName: 'image',
    x: '4723',
    y: '2133',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK3',
    tagName: 'image',
    x: '4723',
    y: '2779',
    height: '584',
    width: '732'
  },
  {
    id: 'path-22',
    tagName: 'path'
  },
  {
    id: 'path-21',
    tagName: 'path'
  },
  {
    id: 'path-20',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3',
    tagName: 'path'
  },
  {
    id: 'QE3Y',
    tagName: 'use',
    x: '936',
    y: '1438'
  },
  {
    id: 'QE2Y',
    tagName: 'use',
    x: '290',
    y: '1438'
  },
  {
    id: 'QE11Y',
    tagName: 'use',
    x: '288',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy',
    tagName: 'path'
  },
  {
    id: 'path-19',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9',
    tagName: 'path'
  },
  {
    id: 'Shape_4_copy',
    tagName: 'path'
  },
  {
    id: 'path-18',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_7',
    tagName: 'path'
  },
  {
    id: 'QE4Y',
    tagName: 'use',
    x: '1062',
    y: '1438'
  },
  {
    id: 'QE11N',
    tagName: 'use',
    x: '538',
    y: '3006'
  },
  {
    id: 'QE2N',
    tagName: 'use',
    x: '680',
    y: '1258'
  },
  {
    id: 'QE3N',
    tagName: 'use',
    x: '1198',
    y: '1258'
  },
  {
    id: 'QE4N',
    tagName: 'use',
    x: '1470',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4',
    tagName: 'path'
  },
  {
    id: 'path-17',
    tagName: 'path'
  },
  {
    id: 'Triangle_1',
    tagName: 'path'
  },
  {
    id: 'QE5N',
    tagName: 'use',
    x: '540',
    y: '1948'
  },
  {
    id: 'QE5Y',
    tagName: 'use',
    x: '454',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2',
    tagName: 'path'
  },
  {
    id: 'AE1',
    tagName: 'use',
    x: '260',
    y: '1778'
  },
  {
    id: 'Group_4_copy',
    tagName: 'g'
  },
  {
    id: 'path-16',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-2',
    tagName: 'path'
  },
  {
    id: 'QE6N',
    tagName: 'use',
    x: '1062',
    y: '1948'
  },
  {
    id: 'QE6Y',
    tagName: 'use',
    x: '976',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-2',
    tagName: 'path'
  },
  {
    id: 'AE2',
    tagName: 'use',
    x: '782',
    y: '1778'
  },
  {
    id: 'path-15',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-3',
    tagName: 'path'
  },
  {
    id: 'QE7N',
    tagName: 'use',
    x: '1584',
    y: '1948'
  },
  {
    id: 'QE7Y',
    tagName: 'use',
    x: '1498',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-3',
    tagName: 'path'
  },
  {
    id: 'AE3',
    tagName: 'use',
    x: '1304',
    y: '1778'
  },
  {
    id: 'path-14',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-4',
    tagName: 'path'
  },
  {
    id: 'QE10N',
    tagName: 'use',
    x: '1584',
    y: '2462'
  },
  {
    id: 'QE10Y',
    tagName: 'use',
    x: '1498',
    y: '2330'
  },
  {
    id: 'AE6',
    tagName: 'use',
    x: '1304',
    y: '2292'
  },
  {
    id: 'path-13',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-5',
    tagName: 'path'
  },
  {
    id: 'QE9N',
    tagName: 'use',
    x: '1062',
    y: '2462'
  },
  {
    id: 'QE9Y',
    tagName: 'use',
    x: '976',
    y: '2330'
  },
  {
    id: 'path-12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-6',
    tagName: 'path'
  },
  {
    id: 'QE8N',
    tagName: 'use',
    x: '540',
    y: '2462'
  },
  {
    id: 'QE8Y',
    tagName: 'use',
    x: '454',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-4',
    tagName: 'path'
  },
  {
    id: 'AE4',
    tagName: 'use',
    x: '260',
    y: '2292'
  },
  {
    id: 'AE5',
    tagName: 'use',
    x: '782',
    y: '2292'
  },
  {
    id: 'path-11',
    tagName: 'path'
  },
  {
    id: 'path-10',
    tagName: 'path'
  },
  {
    id: 'path-9',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3-2',
    tagName: 'path'
  },
  {
    id: 'QH3Y',
    tagName: 'use',
    x: '3796',
    y: '1438'
  },
  {
    id: 'QH2Y',
    tagName: 'use',
    x: '3150',
    y: '1438'
  },
  {
    id: 'QH11Y',
    tagName: 'use',
    x: '3148',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy-2',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9-2',
    tagName: 'path'
  },
  {
    id: 'Shape_5_copy',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_10',
    tagName: 'path'
  },
  {
    id: 'QH12Y',
    tagName: 'use',
    x: '3668',
    y: '3008'
  },
  {
    id: 'Shape_5_copy_2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_11',
    tagName: 'path'
  },
  {
    id: 'QH13Y',
    tagName: 'use',
    x: '4194',
    y: '3008'
  },
  {
    id: 'Shape_4_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-7',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_13',
    tagName: 'path'
  },
  {
    id: 'QH4Y',
    tagName: 'use',
    x: '3922',
    y: '1438'
  },
  {
    id: 'QH11N',
    tagName: 'use',
    x: '3398',
    y: '3006'
  },
  {
    id: 'QH12N',
    tagName: 'use',
    x: '3930',
    y: '3006'
  },
  {
    id: 'QH13N',
    tagName: 'use',
    x: '4456',
    y: '3006'
  },
  {
    id: 'QH2N',
    tagName: 'use',
    x: '3540',
    y: '1258'
  },
  {
    id: 'QH3N',
    tagName: 'use',
    x: '4058',
    y: '1258'
  },
  {
    id: 'QH4N',
    tagName: 'use',
    x: '4330',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4-2',
    tagName: 'path'
  },
  {
    id: 'path-6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-7',
    tagName: 'path'
  },
  {
    id: 'QH5N',
    tagName: 'use',
    x: '3400',
    y: '1948'
  },
  {
    id: 'QH5Y',
    tagName: 'use',
    x: '3314',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-5',
    tagName: 'path'
  },
  {
    id: 'AH1',
    tagName: 'use',
    x: '3120',
    y: '1778'
  },
  {
    id: 'path-5',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-8',
    tagName: 'path'
  },
  {
    id: 'QH6N',
    tagName: 'use',
    x: '3922',
    y: '1948'
  },
  {
    id: 'QH6Y',
    tagName: 'use',
    x: '3836',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-6',
    tagName: 'path'
  },
  {
    id: 'AH2',
    tagName: 'use',
    x: '3642',
    y: '1778'
  },
  {
    id: 'path-4',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-9',
    tagName: 'path'
  },
  {
    id: 'QH7N',
    tagName: 'use',
    x: '4444',
    y: '1948'
  },
  {
    id: 'QH7Y',
    tagName: 'use',
    x: '4358',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-7',
    tagName: 'path'
  },
  {
    id: 'AH3',
    tagName: 'use',
    x: '4164',
    y: '1778'
  },
  {
    id: 'path-3',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-10',
    tagName: 'path'
  },
  {
    id: 'QH10N',
    tagName: 'use',
    x: '4444',
    y: '2462'
  },
  {
    id: 'QH10Y',
    tagName: 'use',
    x: '4358',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-8',
    tagName: 'path'
  },
  {
    id: 'AH6',
    tagName: 'use',
    x: '4164',
    y: '2292'
  },
  {
    id: 'path-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-11',
    tagName: 'path'
  },
  {
    id: 'QH9N',
    tagName: 'use',
    x: '3922',
    y: '2462'
  },
  {
    id: 'QH9Y',
    tagName: 'use',
    x: '3836',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-9',
    tagName: 'path'
  },
  {
    id: 'AH5',
    tagName: 'use',
    x: '3642',
    y: '2292'
  },
  {
    id: 'path',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-12',
    tagName: 'path'
  },
  {
    id: 'QH8N',
    tagName: 'use',
    x: '3400',
    y: '2462'
  },
  {
    id: 'QH8Y',
    tagName: 'use',
    x: '3314',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-10',
    tagName: 'path'
  },
  {
    id: 'AH4',
    tagName: 'use',
    x: '3120',
    y: '2292'
  },
  {
    id: 'Q1N',
    tagName: 'image',
    x: '3128',
    y: '820',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1Y',
    tagName: 'image',
    x: '942',
    y: '824',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1',
    tagName: 'image',
    x: '1774',
    y: '724',
    height: '438',
    width: '1252'
  },
  {
    id: 'QE4',
    tagName: 'image',
    x: '1283',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE3',
    tagName: 'image',
    x: '765',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE2',
    tagName: 'image',
    x: '247',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH4',
    tagName: 'image',
    x: '4139',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH3',
    tagName: 'image',
    x: '3621',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH2',
    tagName: 'image',
    x: '3103',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE5',
    tagName: 'use',
    x: '246',
    y: '1578'
  },
  {
    id: 'QE6',
    tagName: 'use',
    x: '764',
    y: '1578'
  },
  {
    id: 'QE7',
    tagName: 'use',
    x: '1282',
    y: '1578'
  },
  {
    id: 'QH5',
    tagName: 'use',
    x: '3100',
    y: '1578'
  },
  {
    id: 'QH6',
    tagName: 'use',
    x: '3618',
    y: '1578'
  },
  {
    id: 'QH7',
    tagName: 'use',
    x: '4136',
    y: '1578'
  },
  {
    id: 'QE8',
    tagName: 'use',
    x: '246',
    y: '2092'
  },
  {
    id: 'QE9',
    tagName: 'use',
    x: '764',
    y: '2092'
  },
  {
    id: 'QH8',
    tagName: 'use',
    x: '3100',
    y: '2092'
  },
  {
    id: 'QH9',
    tagName: 'use',
    x: '3618',
    y: '2092'
  },
  {
    id: 'QH10',
    tagName: 'use',
    x: '4136',
    y: '2092'
  },
  {
    id: 'QE11',
    tagName: 'image',
    x: '246',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH11',
    tagName: 'image',
    x: '3100',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH12',
    tagName: 'use',
    x: '3618',
    y: '2606'
  },
  {
    id: 'QH13',
    tagName: 'use',
    x: '4136',
    y: '2606'
  },
  {
    id: 'AE7',
    tagName: 'image',
    x: '190',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE8',
    tagName: 'use',
    x: '450',
    y: '3134'
  },
  {
    id: 'AE9',
    tagName: 'image',
    x: '850',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE10',
    tagName: 'image',
    x: '1364',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH7',
    tagName: 'use',
    x: '3056',
    y: '3134'
  },
  {
    id: 'AH8',
    tagName: 'use',
    x: '3316',
    y: '3134'
  },
  {
    id: 'AH9',
    tagName: 'image',
    x: '3576',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH11',
    tagName: 'use',
    x: '4096',
    y: '3134',
    height: '158',
    width: '246'
  },

  {
    id: 'AH12',
    tagName: 'image',
    x: '4368',
    y: '3436',
    height: '158',
    width: '246'
  },
  {
    id: 'ES_E',
    tagName: 'image',
    x: '245',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EO',
    tagName: 'image',
    x: '763',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'ENO',
    tagName: 'image',
    x: '1281',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EVIDENT-2',
    tagName: 'image',
    x: '249',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'HS_E',
    tagName: 'image',
    x: '3103',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HO',
    tagName: 'image',
    x: '3621',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HNO',
    tagName: 'image',
    x: '4139',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HIDDEN-2',
    tagName: 'image',
    x: '3107',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'E-SE-L1-T1',
    tagName: 'image',
    x: '245',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L1-T1',
    tagName: 'image',
    x: '763',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L1-T1',
    tagName: 'image',
    x: '1281',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L2-T1',
    tagName: 'image',
    x: '245',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L2-T1',
    tagName: 'image',
    x: '763',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L2-T1',
    tagName: 'image',
    x: '1281',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L4-T2',
    tagName: 'image',
    x: '833',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L4-T2',
    tagName: 'image',
    x: '1349',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T1',
    tagName: 'image',
    x: '157',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T2',
    tagName: 'image',
    x: '449',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L1-T1',
    tagName: 'image',
    x: '3099',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L1-T1',
    tagName: 'image',
    x: '3617',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L1-T1',
    tagName: 'image',
    x: '4135',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L2-T1',
    tagName: 'image',
    x: '3099',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L2-T1',
    tagName: 'image',
    x: '3617',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L2-T1',
    tagName: 'image',
    x: '4135',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L3-T1',
    tagName: 'image',
    x: '3060',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-SE-L3-T2',
    tagName: 'image',
    x: '3315',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L3-T1',
    tagName: 'image',
    x: '3580',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L3-T1',
    tagName: 'image',
    x: '4100',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L4-T2',
    tagName: 'image',
    x: '3575',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L4-T2',
    tagName: 'image',
    x: '4119',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'EFFECTIVENESS_CRITERIA',
    tagName: 'image',
    x: '241',
    y: '3464',
    height: '1016',
    width: '1460'
  },
  {
    id: 'ES_E1',
    tagName: 'image',
    x: '246',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'EO1',
    tagName: 'image',
    x: '762',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'ENO1',
    tagName: 'image',
    x: '1280',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HS_E1',
    tagName: 'image',
    x: '3103',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HO1',
    tagName: 'image',
    x: '3619',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'HNO1',
    tagName: 'image',
    x: '4137',
    y: '3742',
    height: '738',
    width: '417'
  },
  {
    id: 'TECHNICAL_FEASIBILITY_CRITERIA_-_Smart_Object_Group',
    tagName: 'image',
    x: '4722',
    y: '1122',
    height: '353',
    width: '736'
  },
  {
    id: 'TASK1',
    tagName: 'image',
    x: '4723',
    y: '1487',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK2',
    tagName: 'image',
    x: '4723',
    y: '2133',
    height: '584',
    width: '732'
  },
  {
    id: 'TASK3',
    tagName: 'image',
    x: '4723',
    y: '2779',
    height: '584',
    width: '732'
  },
  {
    id: 'path-22',
    tagName: 'path'
  },
  {
    id: 'path-21',
    tagName: 'path'
  },
  {
    id: 'path-20',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3',
    tagName: 'path'
  },
  {
    id: 'QE3Y',
    tagName: 'use',
    x: '936',
    y: '1438'
  },
  {
    id: 'QE2Y',
    tagName: 'use',
    x: '290',
    y: '1438'
  },
  {
    id: 'QE11Y',
    tagName: 'use',
    x: '288',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy',
    tagName: 'path'
  },
  {
    id: 'path-19',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9',
    tagName: 'path'
  },
  {
    id: 'Shape_4_copy',
    tagName: 'path'
  },
  {
    id: 'path-18',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_7',
    tagName: 'path'
  },
  {
    id: 'QE4Y',
    tagName: 'use',
    x: '1062',
    y: '1438'
  },
  {
    id: 'QE11N',
    tagName: 'use',
    x: '538',
    y: '3006'
  },
  {
    id: 'QE2N',
    tagName: 'use',
    x: '680',
    y: '1258'
  },
  {
    id: 'QE3N',
    tagName: 'use',
    x: '1198',
    y: '1258'
  },
  {
    id: 'QE4N',
    tagName: 'use',
    x: '1470',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4',
    tagName: 'path'
  },
  {
    id: 'path-17',
    tagName: 'path'
  },
  {
    id: 'Triangle_1',
    tagName: 'path'
  },
  {
    id: 'QE5N',
    tagName: 'use',
    x: '540',
    y: '1948'
  },
  {
    id: 'QE5Y',
    tagName: 'use',
    x: '454',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2',
    tagName: 'path'
  },
  {
    id: 'AE1',
    tagName: 'use',
    x: '260',
    y: '1778'
  },
  {
    id: 'Group_4_copy',
    tagName: 'g'
  },
  {
    id: 'path-16',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-2',
    tagName: 'path'
  },
  {
    id: 'QE6N',
    tagName: 'use',
    x: '1062',
    y: '1948'
  },
  {
    id: 'QE6Y',
    tagName: 'use',
    x: '976',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-2',
    tagName: 'path'
  },
  {
    id: 'AE2',
    tagName: 'use',
    x: '782',
    y: '1778'
  },
  {
    id: 'path-15',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-3',
    tagName: 'path'
  },
  {
    id: 'QE7N',
    tagName: 'use',
    x: '1584',
    y: '1948'
  },
  {
    id: 'QE7Y',
    tagName: 'use',
    x: '1498',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-3',
    tagName: 'path'
  },
  {
    id: 'AE3',
    tagName: 'use',
    x: '1304',
    y: '1778'
  },
  {
    id: 'path-14',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-4',
    tagName: 'path'
  },
  {
    id: 'QE10N',
    tagName: 'use',
    x: '1584',
    y: '2462'
  },
  {
    id: 'QE10Y',
    tagName: 'use',
    x: '1498',
    y: '2330'
  },
  {
    id: 'AE6',
    tagName: 'use',
    x: '1304',
    y: '2292'
  },
  {
    id: 'path-13',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-5',
    tagName: 'path'
  },
  {
    id: 'QE9N',
    tagName: 'use',
    x: '1062',
    y: '2462'
  },
  {
    id: 'QE9Y',
    tagName: 'use',
    x: '976',
    y: '2330'
  },
  {
    id: 'path-12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-6',
    tagName: 'path'
  },
  {
    id: 'QE8N',
    tagName: 'use',
    x: '540',
    y: '2462'
  },
  {
    id: 'QE8Y',
    tagName: 'use',
    x: '454',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-4',
    tagName: 'path'
  },
  {
    id: 'AE4',
    tagName: 'use',
    x: '260',
    y: '2292'
  },
  {
    id: 'AE5',
    tagName: 'use',
    x: '782',
    y: '2292'
  },
  {
    id: 'path-11',
    tagName: 'path'
  },
  {
    id: 'path-10',
    tagName: 'path'
  },
  {
    id: 'path-9',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_3-2',
    tagName: 'path'
  },
  {
    id: 'QH3Y',
    tagName: 'use',
    x: '3796',
    y: '1438'
  },
  {
    id: 'QH2Y',
    tagName: 'use',
    x: '3150',
    y: '1438'
  },
  {
    id: 'QH11Y',
    tagName: 'use',
    x: '3148',
    y: '3008'
  },
  {
    id: 'Triangle_1_copy-2',
    tagName: 'path'
  },
  {
    id: 'Shape_3_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-8',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_8-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_9-2',
    tagName: 'path'
  },
  {
    id: 'Shape_5_copy',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_10',
    tagName: 'path'
  },
  {
    id: 'QH12Y',
    tagName: 'use',
    x: '3668',
    y: '3008'
  },
  {
    id: 'Shape_5_copy_2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_11',
    tagName: 'path'
  },
  {
    id: 'QH13Y',
    tagName: 'use',
    x: '4194',
    y: '3008'
  },
  {
    id: 'Shape_4_copy-2',
    tagName: 'path'
  },
  {
    id: 'path-7',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_12',
    tagName: 'path'
  },
  {
    id: 'Triangle_1_copy_13',
    tagName: 'path'
  },
  {
    id: 'QH4Y',
    tagName: 'use',
    x: '3922',
    y: '1438'
  },
  {
    id: 'QH11N',
    tagName: 'use',
    x: '3398',
    y: '3006'
  },
  {
    id: 'QH12N',
    tagName: 'use',
    x: '3930',
    y: '3006'
  },
  {
    id: 'QH13N',
    tagName: 'use',
    x: '4456',
    y: '3006'
  },
  {
    id: 'QH2N',
    tagName: 'use',
    x: '3540',
    y: '1258'
  },
  {
    id: 'QH3N',
    tagName: 'use',
    x: '4058',
    y: '1258'
  },
  {
    id: 'QH4N',
    tagName: 'use',
    x: '4330',
    y: '1452'
  },
  {
    id: 'Triangle_1_copy_4-2',
    tagName: 'path'
  },
  {
    id: 'path-6',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-7',
    tagName: 'path'
  },
  {
    id: 'QH5N',
    tagName: 'use',
    x: '3400',
    y: '1948'
  },
  {
    id: 'QH5Y',
    tagName: 'use',
    x: '3314',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-5',
    tagName: 'path'
  },
  {
    id: 'AH1',
    tagName: 'use',
    x: '3120',
    y: '1778'
  },
  {
    id: 'path-5',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-8',
    tagName: 'path'
  },
  {
    id: 'QH6N',
    tagName: 'use',
    x: '3922',
    y: '1948'
  },
  {
    id: 'QH6Y',
    tagName: 'use',
    x: '3836',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-6',
    tagName: 'path'
  },
  {
    id: 'AH2',
    tagName: 'use',
    x: '3642',
    y: '1778'
  },
  {
    id: 'path-4',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-9',
    tagName: 'path'
  },
  {
    id: 'QH7N',
    tagName: 'use',
    x: '4444',
    y: '1948'
  },
  {
    id: 'QH7Y',
    tagName: 'use',
    x: '4358',
    y: '1816'
  },
  {
    id: 'Triangle_1_copy_2-7',
    tagName: 'path'
  },
  {
    id: 'AH3',
    tagName: 'use',
    x: '4164',
    y: '1778'
  },
  {
    id: 'path-3',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-10',
    tagName: 'path'
  },
  {
    id: 'QH10N',
    tagName: 'use',
    x: '4444',
    y: '2462'
  },
  {
    id: 'QH10Y',
    tagName: 'use',
    x: '4358',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-8',
    tagName: 'path'
  },
  {
    id: 'AH6',
    tagName: 'use',
    x: '4164',
    y: '2292'
  },
  {
    id: 'path-2',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-11',
    tagName: 'path'
  },
  {
    id: 'QH9N',
    tagName: 'use',
    x: '3922',
    y: '2462'
  },
  {
    id: 'QH9Y',
    tagName: 'use',
    x: '3836',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-9',
    tagName: 'path'
  },
  {
    id: 'AH5',
    tagName: 'use',
    x: '3642',
    y: '2292'
  },
  {
    id: 'path',
    tagName: 'path'
  },
  {
    id: 'Triangle_1-12',
    tagName: 'path'
  },
  {
    id: 'QH8N',
    tagName: 'use',
    x: '3400',
    y: '2462'
  },
  {
    id: 'QH8Y',
    tagName: 'use',
    x: '3314',
    y: '2330'
  },
  {
    id: 'Triangle_1_copy_2-10',
    tagName: 'path'
  },
  {
    id: 'AH4',
    tagName: 'use',
    x: '3120',
    y: '2292'
  },
  {
    id: 'Q1N',
    tagName: 'image',
    x: '3128',
    y: '820',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1Y',
    tagName: 'image',
    x: '942',
    y: '824',
    height: '71',
    width: '71'
  },
  {
    id: 'Q1',
    tagName: 'image',
    x: '1774',
    y: '724',
    height: '438',
    width: '1252'
  },
  {
    id: 'QE4',
    tagName: 'image',
    x: '1283',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE3',
    tagName: 'image',
    x: '765',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE2',
    tagName: 'image',
    x: '247',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH4',
    tagName: 'image',
    x: '4139',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH3',
    tagName: 'image',
    x: '3621',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QH2',
    tagName: 'image',
    x: '3103',
    y: '1184',
    height: '223',
    width: '416'
  },
  {
    id: 'QE5',
    tagName: 'use',
    x: '246',
    y: '1578'
  },
  {
    id: 'QE6',
    tagName: 'use',
    x: '764',
    y: '1578'
  },
  {
    id: 'QE7',
    tagName: 'use',
    x: '1282',
    y: '1578'
  },
  {
    id: 'QH5',
    tagName: 'use',
    x: '3100',
    y: '1578'
  },
  {
    id: 'QH6',
    tagName: 'use',
    x: '3618',
    y: '1578'
  },
  {
    id: 'QH7',
    tagName: 'use',
    x: '4136',
    y: '1578'
  },
  {
    id: 'QE8',
    tagName: 'use',
    x: '246',
    y: '2092'
  },
  {
    id: 'QE9',
    tagName: 'use',
    x: '764',
    y: '2092'
  },
  {
    id: 'QE10',
    tagName: 'image',
    x: '1282',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'QH8',
    tagName: 'use',
    x: '3100',
    y: '2092'
  },
  {
    id: 'QH9',
    tagName: 'use',
    x: '3618',
    y: '2092'
  },
  {
    id: 'QH10',
    tagName: 'use',
    x: '4136',
    y: '2092'
  },
  {
    id: 'QE11',
    tagName: 'image',
    x: '246',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH11',
    tagName: 'image',
    x: '3100',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH12',
    tagName: 'use',
    x: '3618',
    y: '2606'
  },
  {
    id: 'QH13',
    tagName: 'use',
    x: '4136',
    y: '2606'
  },
  {
    id: 'AE7',
    tagName: 'image',
    x: '190',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE8',
    tagName: 'use',
    x: '450',
    y: '3134'
  },
  {
    id: 'AE9',
    tagName: 'image',
    x: '850',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AE10',
    tagName: 'image',
    x: '1364',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH7',
    tagName: 'use',
    x: '3056',
    y: '3134'
  },
  {
    id: 'AH8',
    tagName: 'use',
    x: '3316',
    y: '3134'
  },
  {
    id: 'AH9',
    tagName: 'image',
    x: '3576',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH10',
    tagName: 'image',
    x: '3838',
    y: '3436',
    height: '158',
    width: '246'
  },
  {
    id: 'AH12',
    tagName: 'image',
    x: '4368',
    y: '3436',
    height: '158',
    width: '246'
  },
  {
    id: 'ES_E',
    tagName: 'image',
    x: '245',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EO',
    tagName: 'image',
    x: '763',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'ENO',
    tagName: 'image',
    x: '1281',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'EVIDENT-2',
    tagName: 'image',
    x: '249',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'HS_E',
    tagName: 'image',
    x: '3103',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HO',
    tagName: 'image',
    x: '3621',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HNO',
    tagName: 'image',
    x: '4139',
    y: '505',
    height: '146',
    width: '419'
  },
  {
    id: 'HIDDEN-2',
    tagName: 'image',
    x: '3107',
    y: '194',
    height: '221',
    width: '1450'
  },
  {
    id: 'E-SE-L1-T1',
    tagName: 'image',
    x: '245',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L1-T1',
    tagName: 'image',
    x: '763',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L1-T1',
    tagName: 'image',
    x: '1281',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L2-T1',
    tagName: 'image',
    x: '245',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L2-T1',
    tagName: 'image',
    x: '763',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L2-T1',
    tagName: 'image',
    x: '1281',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'E-OC-L4-T2',
    tagName: 'image',
    x: '833',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-NC-L4-T2',
    tagName: 'image',
    x: '1349',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T1',
    tagName: 'image',
    x: '157',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'E-SE-L3-T2',
    tagName: 'image',
    x: '449',
    y: '3323',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L1-T1',
    tagName: 'image',
    x: '3099',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L1-T1',
    tagName: 'image',
    x: '3617',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L1-T1',
    tagName: 'image',
    x: '4135',
    y: '1971',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L2-T1',
    tagName: 'image',
    x: '3099',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-OC-L2-T1',
    tagName: 'image',
    x: '3617',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-NC-L2-T1',
    tagName: 'image',
    x: '4135',
    y: '2483',
    height: '68',
    width: '276'
  },
  {
    id: 'H-SE-L3-T1',
    tagName: 'image',
    x: '3060',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-SE-L3-T2',
    tagName: 'image',
    x: '3315',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L3-T1',
    tagName: 'image',
    x: '3580',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L3-T1',
    tagName: 'image',
    x: '4100',
    y: '3305',
    height: '55',
    width: '242'
  },
  {
    id: 'H-OC-L4-T2',
    tagName: 'image',
    x: '3575',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'H-NC-L4-T2',
    tagName: 'image',
    x: '4119',
    y: '3525',
    height: '55',
    width: '242'
  },
  {
    id: 'QE5',
    tagName: 'QE5',
    x: '246',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'QE6',
    tagName: 'QE6',
    x: '764',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'QE7',
    tagName: 'QE7',
    x: '1282',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'AE1',
    tagName: 'AE1',
    x: '260',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'AE2',
    tagName: 'AE2',
    x: '782',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'AE3',
    tagName: 'AE3',
    x: '1304',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'QE8',
    tagName: 'QE8',
    x: '246',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'QE9',
    tagName: 'QE9',
    x: '764',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'AE4',
    tagName: 'AE4',
    x: '260',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'AE5',
    tagName: 'AE5',
    x: '782',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'AE6',
    tagName: 'AE6',
    x: '1304',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'AE8',
    tagName: 'AE8',
    x: '450',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'QH5',
    tagName: 'QH5',
    x: '3100',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'QH6',
    tagName: 'QH6',
    x: '3618',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'QH7',
    tagName: 'QH7',
    x: '4136',
    y: '1578',
    height: '156',
    width: '416'
  },
  {
    id: 'AH1',
    tagName: 'AH1',
    x: '3120',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'AH2',
    tagName: 'AH2',
    x: '3642',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'AH3',
    tagName: 'AH3',
    x: '4164',
    y: '1778',
    height: '180',
    width: '142'
  },
  {
    id: 'QH8',
    tagName: 'QH8',
    x: '3100',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'QH9',
    tagName: 'QH9',
    x: '3618',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'QH10',
    tagName: 'QH10',
    x: '4136',
    y: '2092',
    height: '156',
    width: '416'
  },
  {
    id: 'AH4',
    tagName: 'AH4',
    x: '3120',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'AH5',
    tagName: 'AH5',
    x: '3642',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'AH6',
    tagName: 'AH3',
    x: '4164',
    y: '2292',
    height: '180',
    width: '142'
  },
  {
    id: 'QH12',
    tagName: 'QH12',
    x: '3618',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'QH13',
    tagName: 'QH13',
    x: '4136',
    y: '2606',
    height: '156',
    width: '416'
  },
  {
    id: 'AH7',
    tagName: 'image',
    x: '3056',
    y: '3134',
    height: '158',
    width: '246'
  },
  {
    id: 'AH8',
    tagName: 'image',
    x: '3316',
    y: '3134',
    height: '158',
    width: '246'
  }
]

export const svgPoints: any = {
  EFFECTIVENESS_CRITERIA: {
    id: 'EFFECTIVENESS_CRITERIA',
    tagName: 'image',
    x: '241',
    y: '3464',
    height: '1016',
    width: '1460'
  },
  ES_E1: {
    id: 'ES_E1',
    tagName: 'image',
    x: '246',
    y: '3742',
    height: '738',
    width: '417'
  },
  EO1: {
    id: 'EO1',
    tagName: 'image',
    x: '762',
    y: '3742',
    height: '738',
    width: '417'
  },
  ENO1: {
    id: 'ENO1',
    tagName: 'image',
    x: '1280',
    y: '3742',
    height: '738',
    width: '417'
  },
  HS_E1: {
    id: 'HS_E1',
    tagName: 'image',
    x: '3103',
    y: '3742',
    height: '738',
    width: '417'
  },
  HO1: {
    id: 'HO1',
    tagName: 'image',
    x: '3619',
    y: '3742',
    height: '738',
    width: '417'
  },
  HNO1: {
    id: 'HNO1',
    tagName: 'image',
    x: '4137',
    y: '3742',
    height: '738',
    width: '417'
  },
  TASK1: {
    id: 'TASK1',
    tagName: 'image',
    x: '4723',
    y: '1487',
    height: '584',
    width: '732'
  },
  TASK2: {
    id: 'TASK2',
    tagName: 'image',
    x: '4723',
    y: '2133',
    height: '584',
    width: '732'
  },
  TASK3: {
    id: 'TASK3',
    tagName: 'image',
    x: '4723',
    y: '2779',
    height: '584',
    width: '732'
  },

  QE3Y: {
    id: 'QE3Y',
    tagName: 'use',
    x: '936',
    y: '1438'
  },
  QE2Y: {
    id: 'QE2Y',
    tagName: 'use',
    x: '290',
    y: '1438'
  },
  QE11Y: {
    id: 'QE11Y',
    tagName: 'use',
    x: '288',
    y: '3008'
  },
  Triangle_1_copy: {
    id: 'Triangle_1_copy',
    tagName: 'path'
  },
  Shape_3_copy: {
    id: 'Shape_3_copy',
    tagName: 'path'
  },
  'path-19': {
    id: 'path-19',
    tagName: 'path'
  },
  Triangle_1_copy_6: {
    id: 'Triangle_1_copy_6',
    tagName: 'path'
  },
  Triangle_1_copy_8: {
    id: 'Triangle_1_copy_8',
    tagName: 'path'
  },
  Triangle_1_copy_9: {
    id: 'Triangle_1_copy_9',
    tagName: 'path'
  },
  Shape_4_copy: {
    id: 'Shape_4_copy',
    tagName: 'path'
  },
  'path-18': {
    id: 'path-18',
    tagName: 'path'
  },
  Triangle_1_copy_7: {
    id: 'Triangle_1_copy_7',
    tagName: 'path'
  },
  QE4Y: {
    id: 'QE4Y',
    tagName: 'use',
    x: '1062',
    y: '1438'
  },
  QE11N: {
    id: 'QE11N',
    tagName: 'use',
    x: '538',
    y: '3006'
  },
  QE2N: {
    id: 'QE2N',
    tagName: 'use',
    x: '680',
    y: '1258'
  },
  QE3N: {
    id: 'QE3N',
    tagName: 'use',
    x: '1198',
    y: '1258'
  },
  QE4N: {
    id: 'QE4N',
    tagName: 'use',
    x: '1470',
    y: '1452'
  },
  Triangle_1_copy_4: {
    id: 'Triangle_1_copy_4',
    tagName: 'path'
  },
  'path-17': {
    id: 'path-17',
    tagName: 'path'
  },
  Triangle_1: {
    id: 'Triangle_1',
    tagName: 'path'
  },
  QE5N: {
    id: 'QE5N',
    tagName: 'use',
    x: '540',
    y: '1948'
  },
  QE5Y: {
    id: 'QE5Y',
    tagName: 'use',
    x: '454',
    y: '1816'
  },
  Triangle_1_copy_2: {
    id: 'Triangle_1_copy_2',
    tagName: 'path'
  },
  q7: {
    id: 'AE1',
    tagName: 'AE1',
    x: '260',
    y: '1778',
    height: '180',
    width: '142'
  },
  Group_4_copy: {
    id: 'Group_4_copy',
    tagName: 'g'
  },
  'path-16': {
    id: 'path-16',
    tagName: 'path'
  },
  'Triangle_1-2': {
    id: 'Triangle_1-2',
    tagName: 'path'
  },
  QE6N: {
    id: 'QE6N',
    tagName: 'use',
    x: '1062',
    y: '1948'
  },
  QE6Y: {
    id: 'QE6Y',
    tagName: 'use',
    x: '976',
    y: '1816'
  },
  'Triangle_1_copy_2-2': {
    id: 'Triangle_1_copy_2-2',
    tagName: 'path'
  },
  q47: {
    id: 'AE2',
    tagName: 'AE2',
    x: '782',
    y: '1778',
    height: '180',
    width: '142'
  },
  'path-15': {
    id: 'path-15',
    tagName: 'path'
  },
  'Triangle_1-3': {
    id: 'Triangle_1-3',
    tagName: 'path'
  },
  QE7N: {
    id: 'QE7N',
    tagName: 'use',
    x: '1584',
    y: '1948'
  },
  QE7Y: {
    id: 'QE7Y',
    tagName: 'use',
    x: '1498',
    y: '1816'
  },
  'Triangle_1_copy_2-3': {
    id: 'Triangle_1_copy_2-3',
    tagName: 'path'
  },
  q22: {
    id: 'AE3',
    tagName: 'AE3',
    x: '1304',
    y: '1778',
    height: '180',
    width: '142'
  },
  'path-14': {
    id: 'path-14',
    tagName: 'path'
  },
  'Triangle_1-4': {
    id: 'Triangle_1-4',
    tagName: 'path'
  },
  QE10N: {
    id: 'QE10N',
    tagName: 'use',
    x: '1584',
    y: '2462'
  },
  QE10Y: {
    id: 'QE10Y',
    tagName: 'use',
    x: '1498',
    y: '2330'
  },
  q20: {
    id: 'AE6',
    tagName: 'AE6',
    x: '1304',
    y: '2292',
    height: '180',
    width: '142'
  },
  'path-13': {
    id: 'path-13',
    tagName: 'path'
  },
  'Triangle_1-5': {
    id: 'Triangle_1-5',
    tagName: 'path'
  },
  QE9N: {
    id: 'QE9N',
    tagName: 'use',
    x: '1062',
    y: '2462'
  },
  QE9Y: {
    id: 'QE9Y',
    tagName: 'use',
    x: '976',
    y: '2330'
  },
  'path-12': {
    id: 'path-12',
    tagName: 'path'
  },
  'Triangle_1-6': {
    id: 'Triangle_1-6',
    tagName: 'path'
  },
  QE8N: {
    id: 'QE8N',
    tagName: 'use',
    x: '540',
    y: '2462'
  },
  QE8Y: {
    id: 'QE8Y',
    tagName: 'use',
    x: '454',
    y: '2330'
  },
  'Triangle_1_copy_2-4': {
    id: 'Triangle_1_copy_2-4',
    tagName: 'path'
  },
  q8: {
    id: 'AE4',
    tagName: 'AE4',
    x: '260',
    y: '2292',
    height: '180',
    width: '142'
  },
  q49: {
    id: 'AE5',
    tagName: 'AE5',
    x: '782',
    y: '2292',
    height: '180',
    width: '142'
  },
  'path-11': {
    id: 'path-11',
    tagName: 'path'
  },
  'path-10': {
    id: 'path-10',
    tagName: 'path'
  },
  'path-9': {
    id: 'path-9',
    tagName: 'path'
  },
  'Triangle_1_copy_3-2': {
    id: 'Triangle_1_copy_3-2',
    tagName: 'path'
  },
  QH3Y: {
    id: 'QH3Y',
    tagName: 'use',
    x: '3796',
    y: '1438'
  },
  QH2Y: {
    id: 'QH2Y',
    tagName: 'use',
    x: '3150',
    y: '1438'
  },
  QH11Y: {
    id: 'QH11Y',
    tagName: 'use',
    x: '3148',
    y: '3008'
  },
  'Triangle_1_copy-2': {
    id: 'Triangle_1_copy-2',
    tagName: 'path'
  },
  'Shape_3_copy-2': {
    id: 'Shape_3_copy-2',
    tagName: 'path'
  },
  'path-8': {
    id: 'path-8',
    tagName: 'path'
  },
  'Triangle_1_copy_8-2': {
    id: 'Triangle_1_copy_8-2',
    tagName: 'path'
  },
  'Triangle_1_copy_9-2': {
    id: 'Triangle_1_copy_9-2',
    tagName: 'path'
  },
  Shape_5_copy: {
    id: 'Shape_5_copy',
    tagName: 'path'
  },
  Triangle_1_copy_10: {
    id: 'Triangle_1_copy_10',
    tagName: 'path'
  },
  QH12Y: {
    id: 'QH12Y',
    tagName: 'use',
    x: '3668',
    y: '3008'
  },
  Shape_5_copy_2: {
    id: 'Shape_5_copy_2',
    tagName: 'path'
  },
  Triangle_1_copy_11: {
    id: 'Triangle_1_copy_11',
    tagName: 'path'
  },
  QH13Y: {
    id: 'QH13Y',
    tagName: 'use',
    x: '4194',
    y: '3008'
  },
  'Shape_4_copy-2': {
    id: 'Shape_4_copy-2',
    tagName: 'path'
  },
  'path-7': {
    id: 'path-7',
    tagName: 'path'
  },
  Triangle_1_copy_12: {
    id: 'Triangle_1_copy_12',
    tagName: 'path'
  },
  Triangle_1_copy_13: {
    id: 'Triangle_1_copy_13',
    tagName: 'path'
  },
  QH4Y: {
    id: 'QH4Y',
    tagName: 'use',
    x: '3922',
    y: '1438'
  },
  QH11N: {
    id: 'QH11N',
    tagName: 'use',
    x: '3398',
    y: '3006'
  },
  QH12N: {
    id: 'QH12N',
    tagName: 'use',
    x: '3930',
    y: '3006'
  },
  QH13N: {
    id: 'QH13N',
    tagName: 'use',
    x: '4456',
    y: '3006'
  },
  QH2N: {
    id: 'QH2N',
    tagName: 'use',
    x: '3540',
    y: '1258'
  },
  QH3N: {
    id: 'QH3N',
    tagName: 'use',
    x: '4058',
    y: '1258'
  },
  QH4N: {
    id: 'QH4N',
    tagName: 'use',
    x: '4330',
    y: '1452'
  },
  'Triangle_1_copy_4-2': {
    id: 'Triangle_1_copy_4-2',
    tagName: 'path'
  },
  'path-6': {
    id: 'path-6',
    tagName: 'path'
  },
  'Triangle_1-7': {
    id: 'Triangle_1-7',
    tagName: 'path'
  },
  QH5N: {
    id: 'QH5N',
    tagName: 'use',
    x: '3400',
    y: '1948'
  },
  QH5Y: {
    id: 'QH5Y',
    tagName: 'use',
    x: '3314',
    y: '1816'
  },
  'Triangle_1_copy_2-5': {
    id: 'Triangle_1_copy_2-5',
    tagName: 'path'
  },
  q25: {
    id: 'AH1',
    tagName: 'AH1',
    x: '3120',
    y: '1778',
    height: '180',
    width: '142'
  },
  'path-5': {
    id: 'path-5',
    tagName: 'path'
  },
  'Triangle_1-8': {
    id: 'Triangle_1-8',
    tagName: 'path'
  },
  QH6N: {
    id: 'QH6N',
    tagName: 'use',
    x: '3922',
    y: '1948'
  },
  QH6Y: {
    id: 'QH6Y',
    tagName: 'use',
    x: '3836',
    y: '1816'
  },
  'Triangle_1_copy_2-6': {
    id: 'Triangle_1_copy_2-6',
    tagName: 'path'
  },
  q34: {
    id: 'AH2',
    tagName: 'AH2',
    x: '3642',
    y: '1778',
    height: '180',
    width: '142'
  },
  'path-4': {
    id: 'path-4',
    tagName: 'path'
  },
  'Triangle_1-9': {
    id: 'Triangle_1-9',
    tagName: 'path'
  },
  QH7N: {
    id: 'QH7N',
    tagName: 'use',
    x: '4444',
    y: '1948'
  },
  QH7Y: {
    id: 'QH7Y',
    tagName: 'use',
    x: '4358',
    y: '1816'
  },
  'Triangle_1_copy_2-7': {
    id: 'Triangle_1_copy_2-7',
    tagName: 'path'
  },
  q40: {
    id: 'AH3',
    tagName: 'AH3',
    x: '4164',
    y: '1778',
    height: '180',
    width: '142'
  },
  'path-3': {
    id: 'path-3',
    tagName: 'path'
  },
  'Triangle_1-10': {
    id: 'Triangle_1-10',
    tagName: 'path'
  },
  QH10N: {
    id: 'QH10N',
    tagName: 'use',
    x: '4444',
    y: '2462'
  },
  QH10Y: {
    id: 'QH10Y',
    tagName: 'use',
    x: '4358',
    y: '2330'
  },
  'Triangle_1_copy_2-8': {
    id: 'Triangle_1_copy_2-8',
    tagName: 'path'
  },
  q42: {
    id: 'AH6',
    tagName: 'AH3',
    x: '4164',
    y: '2292',
    height: '180',
    width: '142'
  },
  'path-2': {
    id: 'path-2',
    tagName: 'path'
  },
  'Triangle_1-11': {
    id: 'Triangle_1-11',
    tagName: 'path'
  },
  QH9N: {
    id: 'QH9N',
    tagName: 'use',
    x: '3922',
    y: '2462'
  },
  QH9Y: {
    id: 'QH9Y',
    tagName: 'use',
    x: '3836',
    y: '2330'
  },
  'Triangle_1_copy_2-9': {
    id: 'Triangle_1_copy_2-9',
    tagName: 'path'
  },
  q36: {
    id: 'AH5',
    tagName: 'AH5',
    x: '3642',
    y: '2292',
    height: '180',
    width: '142'
  },
  path: {
    id: 'path',
    tagName: 'path'
  },
  'Triangle_1-12': {
    id: 'Triangle_1-12',
    tagName: 'path'
  },
  QH8N: {
    id: 'QH8N',
    tagName: 'use',
    x: '3400',
    y: '2462'
  },
  QH8Y: {
    id: 'QH8Y',
    tagName: 'use',
    x: '3314',
    y: '2330'
  },
  'Triangle_1_copy_2-10': {
    id: 'Triangle_1_copy_2-10',
    tagName: 'path'
  },
  q27: {
    id: 'AH4',
    tagName: 'AH4',
    x: '3120',
    y: '2292',
    height: '180',
    width: '142'
  },
  Q1N: {
    id: 'Q1N',
    tagName: 'image',
    x: '3128',
    y: '820',
    height: '71',
    width: '71'
  },
  Q1Y: {
    id: 'Q1Y',
    tagName: 'image',
    x: '942',
    y: '824',
    height: '71',
    width: '71'
  },
  q1: {
    id: 'Q1',
    tagName: 'image',
    x: '1774',
    y: '724',
    height: '438',
    width: '1252'
  },
  q12: {
    id: 'QE4',
    tagName: 'image',
    x: '1283',
    y: '1184',
    height: '223',
    width: '416'
  },
  QE3: {
    id: 'QE3',
    tagName: 'image',
    x: '765',
    y: '1184',
    height: '223',
    width: '416'
  },
  q5: {
    id: 'QE3',
    tagName: 'image',
    x: '765',
    y: '1184',
    height: '223',
    width: '416'
  },
  q2: {
    id: 'QE2',
    tagName: 'image',
    x: '247',
    y: '1184',
    height: '223',
    width: '416'
  },
  q31: {
    id: 'QH4',
    tagName: 'image',
    x: '4139',
    y: '1184',
    height: '223',
    width: '416'
  },
  q24: {
    id: 'QH3',
    tagName: 'image',
    x: '3621',
    y: '1184',
    height: '223',
    width: '416'
  },
  q3: {
    id: 'QH2',
    tagName: 'image',
    x: '3103',
    y: '1184',
    height: '223',
    width: '416'
  },
  q4: {
    id: 'QE5',
    tagName: 'QE5',
    x: '246',
    y: '1578',
    height: '156',
    width: '416'
  },
  q46: {
    id: 'QE6',
    tagName: 'QE6',
    x: '764',
    y: '1578',
    height: '156',
    width: '416'
  },
  q13: {
    id: 'QE7',
    tagName: 'QE7',
    x: '1282',
    y: '1578',
    height: '156',
    width: '416'
  },
  q23: {
    id: 'QH5',
    tagName: 'QH5',
    x: '3100',
    y: '1578',
    height: '156',
    width: '416'
  },
  q32: {
    id: 'QH6',
    tagName: 'QH6',
    x: '3618',
    y: '1578',
    height: '156',
    width: '416'
  },
  q33: {
    id: 'QH7',
    tagName: 'QH7',
    x: '4136',
    y: '1578',
    height: '156',
    width: '416'
  },
  q6: {
    id: 'QE8',
    tagName: 'QE8',
    x: '246',
    y: '2092',
    height: '156',
    width: '416'
  },
  q48: {
    id: 'QE9',
    tagName: 'QE9',
    x: '764',
    y: '2092',
    height: '156',
    width: '416'
  },
  q26: {
    id: 'QH8',
    tagName: 'QH8',
    x: '3100',
    y: '2092',
    height: '156',
    width: '416'
  },
  q35: {
    id: 'QH9',
    tagName: 'QH9',
    x: '3618',
    y: '2092',
    height: '156',
    width: '416'
  },
  q41: {
    id: 'QH10',
    tagName: 'QH10',
    x: '4136',
    y: '2092',
    height: '156',
    width: '416'
  },
  q9: {
    id: 'QE11',
    tagName: 'image',
    x: '246',
    y: '2606',
    height: '156',
    width: '416'
  },
  q28: {
    id: 'QH11',
    tagName: 'image',
    x: '3100',
    y: '2606',
    height: '156',
    width: '416'
  },
  q37: {
    id: 'QH12',
    tagName: 'QH12',
    x: '3618',
    y: '2606',
    height: '156',
    width: '416'
  },
  q43: {
    id: 'QH13',
    tagName: 'QH13',
    x: '4136',
    y: '2606',
    height: '156',
    width: '416'
  },
  q10: {
    id: 'AE7',
    tagName: 'image',
    x: '190',
    y: '3134',
    height: '158',
    width: '246'
  },
  q11: {
    id: 'AE8',
    tagName: 'AE8',
    x: '450',
    y: '3134',
    height: '158',
    width: '246'
  },
  q50: {
    id: 'AE9',
    tagName: 'image',
    x: '850',
    y: '3134',
    height: '158',
    width: '246'
  },
  q21: {
    id: 'AE10',
    tagName: 'image',
    x: '1364',
    y: '3134',
    height: '158',
    width: '246'
  },
  q29: {
    id: 'AH7',
    tagName: 'image',
    x: '3056',
    y: '3134',
    height: '158',
    width: '246'
  },
  q30: {
    id: 'AH8',
    tagName: 'image',
    x: '3316',
    y: '3134',
    height: '158',
    width: '246'
  },
  q38: {
    id: 'AH9',
    tagName: 'image',
    x: '3576',
    y: '3134',
    height: '158',
    width: '246'
  },
  q45: {
    id: 'AH12',
    tagName: 'image',
    x: '4368',
    y: '3436',
    height: '158',
    width: '246'
  },
  ES_E: {
    id: 'ES_E',
    tagName: 'image',
    x: '245',
    y: '505',
    height: '146',
    width: '419'
  },
  EO: {
    id: 'EO',
    tagName: 'image',
    x: '763',
    y: '505',
    height: '146',
    width: '419'
  },
  ENO: {
    id: 'ENO',
    tagName: 'image',
    x: '1281',
    y: '505',
    height: '146',
    width: '419'
  },
  EVIDENT: {
    id: 'EVIDENT-2',
    tagName: 'image',
    x: '249',
    y: '194',
    height: '221',
    width: '1450'
  },
  HS_E: {
    id: 'HS_E',
    tagName: 'image',
    x: '3103',
    y: '505',
    height: '146',
    width: '419'
  },
  HO: {
    id: 'HO',
    tagName: 'image',
    x: '3621',
    y: '505',
    height: '146',
    width: '419'
  },
  HNO: {
    id: 'HNO',
    tagName: 'image',
    x: '4139',
    y: '505',
    height: '146',
    width: '419'
  },
  HIDDEN: {
    id: 'HIDDEN-2',
    tagName: 'image',
    x: '3107',
    y: '194',
    height: '221',
    width: '1450'
  },
  'E-SE-L1-T1': {
    id: 'E-SE-L1-T1',
    tagName: 'image',
    x: '245',
    y: '1971',
    height: '68',
    width: '276'
  },
  'E-OC-L1-T1': {
    id: 'E-OC-L1-T1',
    tagName: 'image',
    x: '763',
    y: '1971',
    height: '68',
    width: '276'
  },
  'E-NC-L1-T1': {
    id: 'E-NC-L1-T1',
    tagName: 'image',
    x: '1281',
    y: '1971',
    height: '68',
    width: '276'
  },
  'E-SE-L2-T1': {
    id: 'E-SE-L2-T1',
    tagName: 'image',
    x: '245',
    y: '2483',
    height: '68',
    width: '276'
  },
  'E-OC-L2-T1': {
    id: 'E-OC-L2-T1',
    tagName: 'image',
    x: '763',
    y: '2483',
    height: '68',
    width: '276'
  },
  'E-NC-L2-T1': {
    id: 'E-NC-L2-T1',
    tagName: 'image',
    x: '1281',
    y: '2483',
    height: '68',
    width: '276'
  },
  'E-OC-L4-T2': {
    id: 'E-OC-L4-T2',
    tagName: 'image',
    x: '833',
    y: '3323',
    height: '68',
    width: '276'
  },
  'E-NC-L4-T2': {
    id: 'E-NC-L4-T2',
    tagName: 'image',
    x: '1349',
    y: '3323',
    height: '68',
    width: '276'
  },
  'E-SE-L3-T1': {
    id: 'E-SE-L3-T1',
    tagName: 'image',
    x: '157',
    y: '3323',
    height: '68',
    width: '276'
  },
  'E-SE-L3-T2': {
    id: 'E-SE-L3-T2',
    tagName: 'image',
    x: '449',
    y: '3323',
    height: '68',
    width: '276'
  },
  'H-SE-L1-T1': {
    id: 'H-SE-L1-T1',
    tagName: 'image',
    x: '3099',
    y: '1971',
    height: '68',
    width: '276'
  },
  'H-OC-L1-T1': {
    id: 'H-OC-L1-T1',
    tagName: 'image',
    x: '3617',
    y: '1971',
    height: '68',
    width: '276'
  },
  'H-NC-L1-T1': {
    id: 'H-NC-L1-T1',
    tagName: 'image',
    x: '4135',
    y: '1971',
    height: '68',
    width: '276'
  },
  'H-SE-L2-T1': {
    id: 'H-SE-L2-T1',
    tagName: 'image',
    x: '3099',
    y: '2483',
    height: '68',
    width: '276'
  },
  'H-OC-L2-T1': {
    id: 'H-OC-L2-T1',
    tagName: 'image',
    x: '3617',
    y: '2483',
    height: '68',
    width: '276'
  },
  'H-NC-L2-T1': {
    id: 'H-NC-L2-T1',
    tagName: 'image',
    x: '4135',
    y: '2483',
    height: '68',
    width: '276'
  },
  'H-SE-L3-T1': {
    id: 'H-SE-L3-T1',
    tagName: 'image',
    x: '3060',
    y: '3305',
    height: '55',
    width: '242'
  },
  'H-SE-L3-T2': {
    id: 'H-SE-L3-T2',
    tagName: 'image',
    x: '3315',
    y: '3305',
    height: '55',
    width: '242'
  },
  'H-OC-L3-T1': {
    id: 'H-OC-L3-T1',
    tagName: 'image',
    x: '3580',
    y: '3305',
    height: '55',
    width: '242'
  },
  'H-NC-L3-T1': {
    id: 'H-NC-L3-T1',
    tagName: 'image',
    x: '4100',
    y: '3305',
    height: '55',
    width: '242'
  },
  'H-OC-L4-T2': {
    id: 'H-OC-L4-T2',
    tagName: 'image',
    x: '3575',
    y: '3525',
    height: '55',
    width: '242'
  },
  'H-NC-L4-T2': {
    id: 'H-NC-L4-T2',
    tagName: 'image',
    x: '4119',
    y: '3525',
    height: '55',
    width: '242'
  },
  'TECHNICAL_FEASIBILITY_CRITERIA_-_Smart_Object_Group': {
    id: 'TECHNICAL_FEASIBILITY_CRITERIA_-_Smart_Object_Group',
    tagName: 'image',
    x: '4722',
    y: '1122',
    height: '353',
    width: '736'
  },
  q44: {
    id: 'AH11',
    tagName: 'use',
    x: '4096',
    y: '3134',
    height: '158',
    width: '246'
  },
  q19: {
    id: 'QE10',
    tagName: 'image',
    x: '1282',
    y: '2092',
    height: '156',
    width: '416'
  },
  q39: {
    id: 'AH10',
    tagName: 'image',
    x: '3838',
    y: '3436',
    height: '158',
    width: '246'
  },
  q14: {
    id: 'QE6',
    tagName: 'QE6',
    x: '764',
    y: '1578',
    height: '156',
    width: '416'
  },
  q15: {
    id: 'AE2',
    tagName: 'AE2',
    x: '782',
    y: '1778',
    height: '180',
    width: '142'
  }
}
