import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useState } from 'react'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import toNormalCase from '../../components/lib/toNormalCase'
import { assetCriticalityList } from './rcmConstants'
import { Box, Link, renderData } from '../workManagement/PmTree'
import { SubHeader } from '../VerifyCM'
import CustomLabel from '../../components/InputFields/CustomLabel'
import {
  HIGH_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'
import { useMsal } from '@azure/msal-react'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { useQuery } from 'react-query'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import RcmRiskMatrix from './RcmRiskMatrix'
import {
  calculatePof,
  economicRiskMatrix,
  environmentRiskMatrix,
  healthRiskMatrix,
  mtbfOptions as stage5Mtbf
} from './RcmViewStage5'

const colors: any = {
  LOW: NO_RISK_COLOR,
  MEDIUM: MEDIUM_RISK_COLOR,
  HIGH: HIGH_RISK_COLOR
}

const mtbfOptions = ['Extreme', 'High', 'Medium', 'Low', 'Negligible']

const envCons = [
  'Massive Effect',
  'Major Effect',
  'Localized Effect',
  'Minor Effect',
  'Slight Effect'
]

const healthCons = [
  'Multiple Fatality',
  'Single Fatality',
  'Major Injury',
  'Minor Injury',
  'Slight Injury'
]

const ecCons = [
  'Exteme (> 100M USD)',
  'High Effect (10-100M USD)',
  'Medium Effect (1-10M USD)',
  'Low Effect (0.1-1M USD)',
  'Negligible Effect < 100K USD'
]

const maintainabilityOptions = [
  'Very Low',
  'Low',
  'Moderate',
  'High',
  'Very High'
]

const YesNo = ['Yes', 'No']

const operatingOptions = ['100%', '< 75%', '< 50%', '< 25%']

const spareOptions = [
  '> 180 Days',
  '90 - 180 Days',
  '30 - 90 Days',
  '15 - 30 Days'
]

const ReAssessModal = ({
  isOpen,
  onClose,
  onSave,
  item,
  itemType,
  refetch,
  selected
}: any) => {
  const { accounts, instance } = useMsal()
  const [values, setValues]: any = useState(item)
  const [modal, setModal]: any = useState(false)
  const handleValueChange = (key: any, value: any) => {
    setValues((prev: any) => ({ ...prev, [key]: value }))
  }

  function calculate() {
    const {
      mtbfValue,
      envConsValue,
      helthValue,
      ecValue,
      mValue,
      redValue,
      ocValue,
      spareValue,
      hfValue
    } = values || {}
    const weightedAverage =
      0.25 * envConsValue + 0.5 * helthValue + 0.25 * ecValue
    const result =
      (mtbfValue *
        weightedAverage *
        mValue *
        redValue *
        ocValue *
        spareValue *
        hfValue) /
      100
    return result
    // return result > 1 ? result : 1;
  }

  const criticalityLevel = (result: any) => {
    if (result >= 1 && result <= 467) {
      return 'LOW'
    } else if (result >= 468 && result <= 623) {
      return 'MEDIUM'
    } else if (result >= 624) {
      return 'HIGH'
    }
    return 'UNKNOWN' // Optional, for cases where 'result' doesn't fall in any range
  }

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalBody>
        <SubHeader header="Re Asses Criticality" permitColor="gold" />
        <Box heading={`Tag Details`}>
          {[
            {
              label: 'Number :',
              value: item?.equipmentNumber
            },
            {
              label: 'Description :',
              value: item?.equipmentDescription
            }
          ]?.map((i: any) => renderData(i?.label, i?.value))}
        </Box>
        <Row>
          <Col xs="4">
            <CustomLabel label="Probability of Failure(MTBF)" />
            <Input
              type="select"
              value={item?.mtbf}
              onChange={(e) => {
                handleValueChange('mtbf', e?.target?.value)
                handleValueChange(
                  'mtbfValue',
                  5 - mtbfOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {mtbfOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="POF" />
            <Input
              type="select"
              onChange={(e: any) => {
                handleValueChange(
                  'pof',
                  calculatePof(stage5Mtbf[e?.target?.value])
                )
              }}
            >
              <option>Select</option>
              {Object.keys(stage5Mtbf)?.map((h: any) => (
                <option key={h}>{h}</option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Environment" />
            <RcmRiskMatrix
              pof={values?.pof}
              values={
                values?.environmentConsequence &&
                values?.environmentConsequence + ' - ' + values?.envConsValue
              }
              score={values?.envConsValue}
              riskMatrix={environmentRiskMatrix}
              onChange={async (value: any, text: any) => {
                await handleValueChange('envConsValue', value)
                await handleValueChange('environmentConsequence', text)
              }}
            />
            {/* <Input
              type="select"
              value={item?.environmentConsequence}
              onChange={(e) => {
                handleValueChange('environmentConsequence', e?.target?.value)
                handleValueChange(
                  'envConsValue',
                  5 - envCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {envCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input> */}
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Health & Safety" />
            <RcmRiskMatrix
              pof={values?.pof}
              values={
                values?.healthSafetyConsequence &&
                values?.healthSafetyConsequence + ' - ' + values?.helthValue
              }
              score={values?.helthValue}
              riskMatrix={healthRiskMatrix}
              onChange={async (value: any, text: any) => {
                await handleValueChange('helthValue', value)
                await handleValueChange('healthSafetyConsequence', text)
              }}
            />
            {/* <Input
              type="select"
              value={item?.healthSafetyConsequence}
              onChange={(e) => {
                handleValueChange('healthSafetyConsequence', e?.target?.value)
                handleValueChange(
                  'helthValue',
                  5 - healthCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {healthCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input> */}
          </Col>
          <Col xs="4">
            <CustomLabel label="Consequence Economic" />
            <RcmRiskMatrix
              pof={values?.pof}
              values={
                values?.economicConsequence &&
                values?.economicConsequence + ' - ' + values?.ecValue
              }
              score={values?.ecValue}
              riskMatrix={economicRiskMatrix}
              onChange={async (value: any, text: any) => {
                await handleValueChange('ecValue', value)
                await handleValueChange('economicConsequence', text)
              }}
            />
            {/* <Input
              type="select"
              value={item?.economicConsequence}
              onChange={(e) => {
                handleValueChange('economicConsequence', e?.target?.value)
                handleValueChange(
                  'ecValue',
                  5 - ecCons?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {ecCons?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input> */}
          </Col>
          <Col xs="4">
            <CustomLabel label="Maintainability" />
            <Input
              type="select"
              value={item?.maintainability}
              onChange={(e) => {
                handleValueChange('maintainability', e?.target?.value)
                handleValueChange(
                  'mValue',
                  5 - maintainabilityOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {maintainabilityOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Redundancy Available" />
            <Input
              value={item?.redundancy}
              type="select"
              onChange={(e) => {
                handleValueChange('redundancy', e?.target?.value)
                handleValueChange(
                  'redValue',
                  5 - YesNo?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {YesNo?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Operating Capacity" />
            <Input
              type="select"
              value={item?.operatingCapacity}
              onChange={(e) => {
                handleValueChange('operatingCapacity', e?.target?.value)
                handleValueChange(
                  'ocValue',
                  5 - operatingOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {operatingOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Spare Lead time" />
            <Input
              type="select"
              value={item?.spareLeadTime}
              onChange={(e) => {
                handleValueChange('spareLeadTime', e?.target?.value)
                handleValueChange(
                  'spareValue',
                  5 - spareOptions?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {spareOptions?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
          <Col xs="4">
            <CustomLabel label="Prone to Hidden Failures" />
            <Input
              type="select"
              value={item?.hiddenFailures}
              onChange={(e) => {
                handleValueChange('hiddenFailures', e?.target?.value)
                handleValueChange(
                  'hfValue',
                  5 - YesNo?.indexOf(e?.target?.value)
                )
              }}
            >
              <option>Select</option>
              {['Yes', 'No']?.map((o: any) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => onClose()}>
          Close
        </Button>
        <Button
          color="warning"
          onClick={() => {
            // const total = calculate()
            // const level = criticalityLevel(total)
            // onSave(item?.index, item, values, total, level, itemType)
            setModal(true)
          }}
        >
          Save
        </Button>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={modal}
        onClose={(val: any) => {
          setModal(false)
          onClose()
        }}
        onSubmit={async (e: any) => {
          e.preventDefault()
          const total = calculate()
          const level = criticalityLevel(total)

          const body = {
            ...values,
            totalValue: total,
            riskCategory: level,
            idList: selected?.map((a: any) => a?._id)
          }

          const res = await makeAuthenticatedRequest(
            `${rcmApi}/criticality`,
            'POST',
            body,
            accounts,
            instance
          )

          if (res?.status === 'success') {
            // setResult(res?.incident)
            await refetch()
          }

          return res?.status
        }}
        size="xs"
        header="Update"
      />
    </Modal>
  )
}

const AssetCriticalityTable = ({
  item,
  entries,
  setModal,
  selected,
  setSelected,
  setCurrentItem,
  hasButton
}: {
  item: any
  entries: any
  setModal: any
  setCurrentItem: any
  selected: any
  setSelected: any
  hasButton?: any
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <h4 style={{ fontWeight: 700 }}>{toNormalCase(item)}</h4>
        {selected?.length > 0 && hasButton && (
          <Button size="xs" color="warning" onClick={() => setModal(true)}>
            Calculate
          </Button>
        )}
      </div>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: 10,
          maxHeight: '90vh',
          overflow: 'scroll'
        }}
      >
        <Table striped className="text-center">
          <thead>
            <tr style={{ backgroundColor: 'lightgray' }}>
              <th className="top-left" style={{ width: '3vw' }}>
                Select
              </th>
              <th style={{ width: '3vw' }}>S.No</th>
              <th style={{ width: '10vw' }}>Eqp. Number</th>
              <th style={{ width: '25vw' }}>Description</th>
              <th style={{ width: '10vw' }}>Current Criticality</th>
              <th style={{ width: '10vw' }}>Score</th>
              <th className="top-right" style={{ width: '10vw' }}>
                New Criticality
              </th>
            </tr>
          </thead>
          <tbody>
            {entries?.map((entry: any, index: any) => (
              <tr key={index}>
                <td className="align-content-center">
                  <Input
                    type="checkbox"
                    size={30}
                    checked={selected?.some(
                      (s: any) => s?.equipmentNumber === entry?.equipmentNumber
                    )}
                    onChange={(e) =>
                      e?.target?.checked
                        ? setSelected([...selected, entry])
                        : setSelected(
                            selected?.filter(
                              (s: any) =>
                                s?.equipmentNumber !== entry?.equipmentNumber
                            )
                          )
                    }
                  />
                </td>
                <td className="align-content-center">{index + 1}</td>
                <td className="align-content-center">
                  {entry?.equipmentNumber}
                </td>
                <td>{entry?.equipmentDescription}</td>
                <td className="align-content-center">{entry?.criticality}</td>
                <th className="align-content-center">{entry?.totalValue}</th>
                <th
                  className="align-content-center"
                  style={{ backgroundColor: colors[entry?.riskCategory] }}
                >
                  {entry?.riskCategory}
                </th>
                {/* <td className="align-content-center">
                  <Link
                    onClick={() => {
                      setModal(true)
                      setCurrentItem(entry)
                    }}
                  >
                    Calculate
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default function AssetCriticality() {
  const [modal, setModal] = useState(false)
  const [currentItem, setCurrentItem]: any = useState({})
  const [entries, setEntries]: any = useState(assetCriticalityList)
  const [selected, setSelected]: any = useState([])

  const { accounts, instance } = useMsal()

  const fetchCard = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/criticality`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchAssets', fetchCard)
  const assetData = data?.assets?.[0]

  return (
    <RcmLayout onBack={'/cmms/rcm'} title={'Asset Criticality'}>
      <Row style={{ margin: 15 }}>
        <style>
          {`
          table {
            width: 100%;
            border-spacing: 0; 
            border-collapse: separate;
            font-family: Helvetica;
          }

          .top-left {
            border: 2px solid lightgray;
            border-top-left-radius: 10px;
          }

          .top-right {
            border: 2px solid lightgray;
            border-top-right-radius: 10px;
          }
          `}
        </style>
        <Spacer height={20} />
        <Col md={6}>
          <AssetCriticalityTable
            item={'equipment'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={assetData?.['equipment']}
            selected={selected}
            setSelected={setSelected}
            hasButton={true}
          />
          <Spacer height={20} />
          <AssetCriticalityTable
            item={'maintainableItems'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={assetData?.['maintainableItems']}
            selected={selected}
            setSelected={setSelected}
          />
        </Col>
        <Col md={6}>
          <AssetCriticalityTable
            item={'tags'}
            setModal={setModal}
            setCurrentItem={setCurrentItem}
            entries={assetData?.['tags']}
            selected={selected}
            setSelected={setSelected}
          />
        </Col>
      </Row>
      <ReAssessModal
        isOpen={modal}
        onClose={() => setModal(false)}
        onSave={(
          itemIndex: any,
          item: any,
          values: any,
          total: any,
          level: any,
          itemType: any
        ) => {
          // const updatedEntries = [...entries[itemType]]
          // const newEntries = updatedEntries?.map((u: any, index: any) =>
          //   index === itemIndex
          //     ? { ...item, ...values, totalValue: total, riskCategory: level }
          //     : u
          // )
          // setEntries((prev: any) => ({ ...prev, [itemType]: newEntries }))
          setModal(false)
        }}
        item={currentItem}
        itemType={currentItem?.itemType}
        refetch={refetch}
        selected={selected}
      />
    </RcmLayout>
  )
}
