import { Children } from 'react'

export const unit = 'UNIT-1'

const systems = [
  'SYSTEM 05-RISERS AND UMBILICAL ',
  'SYSTEM 07-TURRET AND MANIFOLD',
  'SYSTEM 08-PIGGING SYSTEM',
  'SYSTEM 11-CRUDE HEATING AND  SEPARATION',
  'SYSTEM 12-CRUDE OIL TREATMENT INCL DEHYDRATION',
  'SYSTEM 13-CRUDE PUMPING TO STORAGE',
  'SYSTEM 16-OILY WATER / PRODUCED WATER SYSTEM',
  'SYSTEM 18-LOW PRESSURE (LP) FLARE AND VENT SYSTEM',
  'SYSTEM 20-SEPARATION AND STABILISATION',
  'SYSTEM 21-CARGO HANDLING AND METERING',
  'SYSTEM 23-GAS COMPRESSION',
  'SYSTEM 24-GAS TREATMENT',
  'SYSTEM 25-GAS CONDITIONING',
  'SYSTEM 27-GAS EXPORT / INJECTION / LIFT AND METERING',
  'SYSTEM 29-WATER INJECTION',
  'SYSTEM 40-COOLING MEDIUM',
  'SYSTEM 41-HEATING MEDIUM',
  'SYSTEM 42-CHEMICAL INJECTION',
  'SYSTEM 41-PUBLIC ADDRESS & GENERAL ALARM SYSTEM',
  'SYSTEM 42-TELECOMMUNICATION SYSTEMS',
  'SYSTEM 43-ENTERTAINMENT SYSTEM',
  'SYSTEM 44-CONTROL SYSTEM INCL ICSS AND CCR',
  'SYSTEM 45-FIRE & GAS DETECTION SYSTEM',
  'SYSTEM 48-EMERGENCY SHUTDOWN SYSTEM',
  'SYSTEM 51-HVAC SYSTEM',
  'SYSTEM 52-WASTE WATER TREATMENT',
  'SYSTEM 53-SEA WATER SYSTEM',
  'SYSTEM 54-FRESH / DISTILLATE /SERVICE WATER SYSTEM',
  'SYSTEM 55-ANTI-FOULING AND MARINE GROWTH PREVENTION',
  'SYSTEM 56-AIR SYSTEM',
  'SYSTEM 57-BOILERS',
  'SYSTEM 58-HYDRAULIC SYSTEM (CARGO SYSTEM)',
  'SYSTEM 59-NITROGEN SYSTEM',
  'SYSTEM 61-FUEL GAS SYSTEM',
  'SYSTEM 62-LIQUID OR DIESEL OIL SYSTEM',
  'SYSTEM 63-SEA WATER INJECTION SYSTEM',
  'SYSTEM 64-CLOSED DRAIN SYSTEM',
  'SYSTEM 65-OPEN DRAIN SYSTEM',
  'SYSTEM 66-HEATING SYSTEM INCLUDING STEAM',
  'SYSTEM 67-FRESH WATER COOLING SYS.',
  'SYSTEM 68-CHEMICAL INJECTION SYSTEM',
  'SYSTEM 69-HOT WATER SYSTEM (TOPSIDE)',
  'SYSTEM 71-POWER GENERATION',
  'SYSTEM 72-SMALL POWER & LIGHTING SYSTEM ',
  'SYSTEM 73-UPS / BATTERIES SYSTEM',
  'SYSTEM 74-EARTHING SYSTEM',
  'SYSTEM 75-HV/MV (>1 KV) POWER DISTRIBUTION SYSTEM',
  'SYSTEM 76-LV (<1 KV) POWER DISTRIBUTION SYSTEM',
  'SYSTEM 77-ESSENTIAL/EMERGENCY POWER GENERATION ',
  'SYSTEM 78-EMERGENCY POWER DISTRIBUTION SYSTEM',
  'SYSTEM 81-FIRE WATER SYSTEM',
  'SYSTEM 82-SAFETY EQUIPMENT',
  'SYSTEM 83-FIRE SUPPRESSION SYSTEM',
  'SYSTEM 84-PORTABLE FIRE FIGHTING EQUIPMENT',
  'SYSTEM 86-SECURITY SYSTEM',
  'SYSTEM 89-NAVIGATION AIDS',
  'SYSTEM 90-AREA ACCEPTANCE',
  'SYSTEM 91-MOORING SYSTEM',
  'SYSTEM 92-BALLAST SYSTEM',
  'SYSTEM 93-BILGE SYSTEM',
  'SYSTEM 94-ENGINE ROOM MACHINERY',
  'SYSTEM 95-CATHODIC PROTECTION AND CORROSION PREVENTION',
  'SYSTEM 96-MATERIAL HANDLING EQUIPMENT',
  'SYSTEM 97-LAB, WORKSHOP AND STORES ',
  'SYSTEM 98-HOTEL SERVICES & GALLEY',
  'SYSTEM 99-HELIDECK'
]

const components = [
  'Combustion engines',
  'Compressors',
  'Electric generators',
  'Electric motors',
  'Gas turbines',
  'Pumps',
  'Steam turbines',
  'Turboexpanders'
]

const equipment = [
  'K-T7111 - 1st Stage Gas Compressor',
  'K-T7112 - 2nd Stage Gas Compressor'
]

const maintainItems: any = {
  '23-GT-2331A- Gas turbine driver LM2500 SAC Gas Fuel': [
    '23-BE-2331A-Combustion ignition unit',
    '23-IG-2352A-Combustion ignition plug',
    '23-PIT-2353A-HP recoup pressure',
    '23-PIT-2355A-PT inlet pressure (P48)',
    '23-PIT-2356A-GG inlet air pressure (P2)',
    '23-PT-2351AA-CDP - compressor discharge pressure (PS3)',
    '23-PT-2351BA-CDP - compressor discharge pressure (PS3)',
    '23-SE-2346AA-GG speed',
    '23-SE-2346BA-GG speed',
    '23-SE-2347AA-PT speed',
    '23-SE-2347BA-PT speed',
    '23-TE-2337AA-PT inlet temperature (T4.8)',
    '23-TE-2337BA-PT inlet temperature (T4.8)',
    '23-TE-2337CA-PT inlet temperature (T4.8)',
    '23-TE-2337DA-PT inlet temperature (T4.8)',
    '23-TE-2337EA-PT inlet temperature (T4.8)',
    '23-TE-2337GA-PT inlet temperature (T4.8)',
    '23-TE-2337HA-PT inlet temperature (T4.8)',
    '23-TE-2337IA-PT inlet temperature (T4.8)',
    '23-TE-2337JA-PT inlet temperature (T4.8)',
    '23-TE-2337KA-PT inlet temperature (T4.8)',
    '23-TE-2348AA-GG inlet temperature (T2)',
    '23-TE-2348BA-GG inlet temperature (T2)',
    '23-VE-2359A-GG forward vibration',
    '23-VI-2359A-GG forward vibration',
    '23-VE-2360A-PT aft vibration',
    '23-VI-2360A-PT aft vibration'
  ],
  '23-FS-2349A- Air intake filter house': [
    '23-ELH-2332A -Gas turbine enclosure heater ',
    '23-HS-2333AA-Local shut-down switches',
    '23-HS-2333BA-Local shut-down switches',
    '23-KF-2336AA-Ventilation fans',
    '23-KF-2336BA-Ventilation fans',
    '23-ME-2336AA-Ventilation fan electrical motors',
    '23-ME-2336BA-Ventilation fan electrical motors',
    '23-PDIT-2331A-Diff. pressure transmitter, combustion air filter',
    '23-PDIT-2333AA-Diff. pressure transmitter, enclosure to ambient',
    '23-PDIT-2333BA-Diff. pressure transmitter, enclosure to ambient',
    '23-TIT-2336BA-Temperature element (ventilation outlet)',
    '23-TZT-2336AA-Temperature element (ventilation outlet)',
    '23-XD-2336AA-Enclosure shut-off dampers with actuators',
    '23-XD-2336BA-Enclosure shut-off dampers with actuators',
    '23-XY-2336A-Enclosure shut-off damper shut-off valve',
    '23-ZSC-2336AA-Limit switch for enclosure inlet and outlet dampers',
    '23-ZSC-2336BA-Limit switch for enclosure inlet and outlet dampers',
    '23-ZSO-2336AA-Limit switch for enclosure inlet and outlet dampers',
    '23-ZSO-2336BA-Limit switch for enclosure inlet and outlet dampers',
    '23-ZSC-2351AA-Limit switch for turbine enclosure door',
    '23-ZSC-2351BA-Limit switch for turbine enclosure door',
    '23-ZSC-2351CA-Limit switch for turbine enclosure door'
  ],
  '23-XZ-2331A- HV synchronous generator': [
    '23-ELH-2335A-MA-Generator space heater',
    '23-KF-2332AA-Generator cooler fan',
    '23-KF-2332BA-Generator cooler fan',
    '23-KF-2332CA-Generator cooler fan',
    '23-KF-2332DA-Generator cooler fan',
    '23-ME-2332AA-Generator cooler fan motor',
    '23-ME-2332BA-Generator cooler fan motor',
    '23-ME-2332CA-Generator cooler fan motor',
    '23-ME-2332DA-Generator cooler fan motor',
    '23-SE-2382A-Key phasor',
    '23-ST-2382A-Key phasor',
    '23-TE-2332AA-Temperature element, exciter hot air',
    '23-TE-2332BA-Temperature element, exciter hot air',
    '23-TE-2334AA-Temperature element, bearing temp NDE',
    '23-TE-2334BA-Temperature element, bearing temp NDE',
    '23-TE-2335AA-Temperature element, cold air temp',
    '23-TE-2335BA-Temperature element, cold air temp',
    '23-TE-2351AA-Temperature element, generator stator phase U',
    '23-TE-2351BA-Temperature element, generator stator phase U',
    '23-TE-2352AA-Temperature element, generator stator phase V',
    '23-TE-2352BA-Temperature element, generator stator phase V',
    '23-TE-2353AA-Temperature element, generator stator phase W',
    '23-TE-2353BA-Temperature element, generator stator phase W',
    '23-TE-2385AA-Temperature element, thrust bearing DE temp',
    '23-TE-2385BA-Temperature element, thrust bearing DE temp',
    '23-VE-2391AA-Vibration sensor, generator bearing NDE',
    '23-VE-2391BA-Vibration sensor, generator bearing NDE',
    '23-VT-2391AA-Vibration sensor, generator bearing NDE',
    '23-VT-2391BA-Vibration sensor, generator bearing NDE',
    '23-ZE-2392AA-Vibration sensor, generator bearing DE',
    '23-ZE-2392BA-Vibration sensor, generator bearing DE',
    '23-ZT-2392AA-Vibration sensor, generator bearing DE',
    '23-ZT-2392BA-Vibration sensor, generator bearing DE'
  ],
  '23-FIC-2338 - Gas fuel metering valve drive': [
    '23-FV-2338-Gas fuel metering valv',
    '23-FT-2331-Flow meter, gas fuel suppl',
    '23-PIT-2334-Pressure transmitter, gas fuel supply',
    '23-SDV-2339-Gas fuel sec. shut-off valv',
    '23-SDV-2340-Gas fuel prim. shut-off valv',
    '23-SXY-2339A-Solenoid, gas fuel secondary shut-off valve',
    '23-SXY-2340A-Solenoid, gas fuel primary shut-off valve',
    '23-TIT-2340AA-Temperature element w/thermowell, gas fuel supply',
    '23-TIT-2340BA-Temperature element w/thermowell, gas fuel supply',
    '23-TW-2340AA-Temperature element w/thermowell, gas fuel supply',
    '23-TW-2340BA-Temperature element w/thermowell, gas fuel supply',
    '23-XV-2341A-Gas fuel vent-to-flare valve',
    '23-XV-2345A-Gas fuel shut-off bleed valve',
    '23-XY-2341A-Solenoid, gas fuel vent-to-flare.valve',
    '23-XY-2345A-Solenoid, gas fuel shut-off.bleed valve',
    '23-ZSC-2339A-Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)',
    '23-ZSO-2339A-Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)',
    '23-ZSC-2340A-Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)',
    '23-ZSO-2340A-Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)',
    '23-ZSC-2341A-Limit switch, gas fuel vent-to-flare valve closed/open',
    '23-ZSO-2341A-Limit switch, gas fuel vent-to-flare valve closed/open',
    '23-ZSC-2345A-Limit switch, gas fuel shut-off bleed valve closed/open',
    '23-ZSO-2345A-Limit switch, gas fuel shut-off bleed valve closed/open',
    '23-ZT-2338A-Displacement transmitter, gas fuel metering valve (part of 75-FV-2338A)',
    'SY-2340A-Gas fuel supply strainer'
  ],
  '23-AZ-2334A- Liquid fuel pump skid': [
    '23-FF-2331AA-Liquid fuel filter',
    '23-FF-2331BA-Liquid fuel filter',
    '23-FIC-2335A-Liquid fuel metering valve digital driver',
    '23-FT-2332A-Flow meter, liquid fuel supply',
    '23-FV-2332A-Flow divider',
    '23-FV-2335A-Liquid fuel metering valve',
    '23-LG-2336A-Level gauge, hydrocarbon tank',
    '23-ME-2336A-Liquid fuel supply pump motor',
    '23-PDIT-2334A-Diff. pressure transmitter, liquid fuel supply filter',
    '23-PIT-2331A-Pressure transmitter, liquid fuel supply',
    '23-PIT-2340AA-Pressure transmitter, liquid fuel supply pump suction',
    '23-PIT-2340BA-Pressure transmitter, liquid fuel supply pump suction',
    '23-PR-2336A-Liquid fuel supply pump',
    '23-PSV-2332A-Safety relief valve, liquid fuel pump protection',
    '23-SDV-2331A-Liquid fuel secondary shut-off valve',
    '23-SXY-2331A-Solenoid, liquid fuel sec shut-off valve',
    '23-SXY-2335A-Liquid fuel primary shut-off valve (part of 75-FV-2335A)',
    '23-TA-2362A-Hydrocarbon tank',
    '23-TE-2344A-Temperature element, prim.liquid fuel manifold',
    '23-TE-2345A-Temperature element, sec liquid fuel manifold',
    '23-XV-2332A-Liquid fuel manifold purge valve',
    '23-XV-2333A-Liquid fuel primary drain valve',
    '23-XV-2334A-Liquid fuel secondary drain valve',
    'SG-2387A-Sight glass, liquid fuel filter',
    'SY-2336A-Liquid fuel supply strainer',
    '23-ZSC-2334A-Limit switch, liquid fuel sec. drain valve',
    '23-ZSO-2334A-Limit switch, liquid fuel sec. drain valve'
  ],
  '23-AZ-2355A - Synthetic lube oil system': [
    '23-AE-2331AA-Chip detector',
    '23-AE-2331BA-Chip detector',
    '23-AE-2331CA-Chip detector',
    '23-AE-2331DA-Chip detector',
    '23-AE-2331EA-Chip detector',
    '23-ELH-2331A-Electric heater, SLO tank',
    '23-FF-2334AA-Duplex filter unit, SLO scavenge',
    '23-FF-2334BA-Duplex filter unit, SLO scavenge',
    '23-FF-2335AA-Duplex filter unit, SLO supply',
    '23-FF-2335BA-Duplex filter unit, SLO supply',
    '23-LG-2363A-Level gauge, tank level',
    '23-LIT-2362A-Level transmitter, tank level',
    '23-PDIT-2367A-Differential pressure transmitter (supply filter)',
    '23-PDIT-2374A-Differential pressure transmitter (scavenge filter)',
    '23-TE-2338AA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2338BA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2339AA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2339BA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2342AA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2342BA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2343AA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2343BA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2346AA-Temperature element, aux. gearbox and sumps A-D scavenge',
    '23-TE-2346BA-Temperature element, aux. gearbox and sumps A-D scavenge',
    'SY-2332A-Strainer, hydraulic start pump case drain',
    'SY-2343A-Strainer, hydraulic start pump case drain',
    'FA-2354A-SLO flame arrester',
    '23-VZ-2317A-SLO demister',
    '23-TV-2331A-Temperature control valve'
  ],
  '23-AZ-2344A - Mineral lube oil system': [
    '23-ELH-2337A-Electric heater, MLO tank',
    '23-FF-2332AA-Duplex filter unit, MLO supply',
    '23-FF-2332BA-Duplex filter unit, MLO supply',
    '23-FF-2351A-Single filter, jacking oil',
    '23-FV-2351A-Jacking oil diverter',
    '23-HA-2331AA-Common lube oil cooler',
    '23-HA-2331BA-Common lube oil cooler',
    '23-KF-2334AA-Lube oil cooler fan',
    '23-KF-2334BA-Lube oil cooler fan',
    '23-KF-2340A-MLO demister fan',
    '23-LG-2383A-Level glass, MLO tank',
    '23-LIT-2351A-A Level transmitter,',
    '23-ME-2339A-MLO pump motor',
    '23-ME-2340A-MLO demister fan motor',
    '23-ME-2341A-Jacking oil pump motor',
    '23-ME-2334AA-Lube oil cooler fan motor',
    '23-ME-2334BA-Lube oil cooler fan motor',
    '23-PCV-2333A-Pressure control valve, MLO supply',
    '23-PCV-2351A-Pressure control valve, jacking oil',
    '23-PDIT-2382A-Differential pressure transmitter, MLO filters',
    '23-PDIT-2383A-Differential pressure transmitter, MLO tank vent',
    '23-PG-2351A-Pressure gauge, jacking oil',
    '23-PIT-2384AA-Pressure transmitter, MLO supply',
    '23-PIT-2384BA-Pressure transmitter, MLO supply',
    '23-PR-2339A-MLO supply pump',
    '23-PR-2341A-Jacking oil pump',
    '23-PR-2342A-Shaft-driven pump, MLO supply',
    '23-TIT-2379A-Temperature element w/thermowell, MLO supply',
    '23-TW-2379AA-Temperature element w/thermowell, MLO supply',
    'FA-2336A-MLO flame arrester',
    'SG-2384A-Sight glass, generator bearing outlet',
    'SG-2385A-Sight glass, MLO tank'
  ],
  '23-AZ-2332A - Hydraulic start system': [
    '23-AZ-2332A-Hydraulic start module',
    '23-FF-2333A-Filter, hydraulic start supply and return',
    '23-FF-2337A-Filter, hydraulic start supply and return',
    '23-FV-2345A-Hydraulic start pump control valve',
    '23-ME-2333A-Hydraulic start motor',
    '23-ME-2338A-Hydraulic start pump motor (el.)',
    '23-PG-2343A-Hydraulic start supply and return pressure indicators',
    '23-PG-2344A-Hydraulic start supply and return pressure indicators',
    '23-PZ-2338A-Hydraulic start pump',
    '23-SE-2342A-Starter motor speed sensor',
    '23-TE-2341AA-Temperature element for lube oil scavenge line from clutch',
    '23-TE-2341BA-Temperature element for lube oil scavenge line from clutch',
    '23-XZ-2355A-Hydraulic starter clutch',
    'SY-2341A-Hydr. start clutch outlet strainer'
  ],
  '23-FS-2349A - Air inlet and ventilation system': [
    '23-XY-2336A-Ventilation shut-off dampers',
    '23-XY-2345A-Gas fuel shut-off valves',
    '23-XY-2341A-Gas fuel shut-off valves',
    '23-SXY-2331A-Liquid fuel sec. shut-off valve',
    '23-XY-2332A-Liquid fuel manifold purge valve',
    '23-XY-2333A-Liquid fuel manifold drain valves',
    '23-XY-2334A-Liquid fuel manifold drain valves',
    '23-XY-2352A-Quick filling valve',
    '23-FZ-2332A-Air filter regulator'
  ],
  '23-AZ-2333A - Fire and gas system': [
    '23-FD-4540A-101-Flame detector, turbine enclosure',
    '23-FD-4540A-102-Flame detector, turbine enclosure',
    '23-FD-4540A-103-Flame detector, turbine enclosure',
    '23-GD-4540A-101-Point gas detector, filter house',
    '23-GD-4540A-102-Point gas detector, filter house',
    '23-GD-4540A-103-Point gas detector, filter house',
    '23-GD-4540A-104-Point gas detector, ventilation outlet',
    '23-GD-4540A-105-Point gas detector, ventilation outlet',
    '23-GD-4540A-106-Point gas detector, ventilation outlet',
    '23-HD-4540A-101-Heat detector, turbine enclosure',
    '23-HD-4540A-102-Heat detector, turbine enclosure',
    '23-HD-4540A-103-Heat detector, turbine enclosure',
    '23-HS-4540A-101-Manual water mist release push button',
    '23-HS-4540A-102-Manual water mist release push button',
    '23-XI-4540A-101-Alarm beacon inside enclosure',
    '23-XI-4540A-102-Alarm beacon inside enclosure'
  ],
  '23-AZ-2333A- Water mist skid': [
    '23-LG-2335A-Level gauge, water mist vessel',
    '23-PCV-2331A-Pressure control valve, water mist N2 supply',
    '23-PG-2336A-Pressure gauge, N2 bottle',
    '23-PG-2337A-Pressure gauge, N2 bottle',
    '23-PG-2338A-Pressure gauge, water mist pressure',
    '23-PG-2339A-Pressure gauge, water mist pressure',
    '23-PIT-2333A-Pressure transmitter, water mist release',
    '23-PSV-2334A-Safety relief valve, water mist N2 supply',
    '23-VB-2340A-Water mist vessel',
    '23-XY-2350A-Water mist release valve',
    '23-XY-2354A-Water mist release valve',
    '23-XY-2351A-Water mist N2 release valve',
    '23-XY-2353A-Water mist N2 release valve',
    'SY-2342A-Strainer, water mist vessel filling'
  ]
}

export const rcmTreeDataFormula = {
  name: unit,
  children: systems?.map((system: any) => ({
    name: system,
    children:
      system === 'SYSTEM 23-GAS COMPRESSION'
        ? components?.map((c: any) => ({
            name: c,
            children:
              c === 'Compressors'
                ? equipment?.map((e: any) => ({
                    name: e,
                    children:
                      e === 'K-T7111 - 1st Stage Gas Compressor'
                        ? Object.keys(maintainItems)?.map((m: any) => ({
                            name: m,
                            children: maintainItems[m]?.map((t: any) => ({
                              name: t
                            }))
                          }))
                        : []
                  }))
                : []
          }))
        : []
  }))
}

export const rcmTreeData = {
  name: 'UNIT-1',
  isOpen: false,
  children: [
    {
      name: 'SYSTEM 05-RISERS AND UMBILICAL ',
      isOpen: false,
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 07-TURRET AND MANIFOLD',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 08-PIGGING SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 11-CRUDE HEATING AND  SEPARATION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 12-CRUDE OIL TREATMENT INCL DEHYDRATION',
      children: []
    },
    { isOpen: true, name: 'SYSTEM 13-CRUDE PUMPING TO STORAGE', children: [] },
    {
      isOpen: true,
      name: 'SYSTEM 16-OILY WATER / PRODUCED WATER SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 18-LOW PRESSURE (LP) FLARE AND VENT SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 20-SEPARATION AND STABILISATION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 21-CARGO HANDLING AND METERING',
      children: []
    },
    {
      isOpen: true,

      name: 'SYSTEM 23-GAS COMPRESSION',
      children: [
        {
          isOpen: true,

          name: 'COMBUSTION ENGINES',
          children: []
        },
        {
          isOpen: true,
          name: 'COMPRESSORS',
          children: [
            {
              isOpen: true,
              name: 'K-T7111 - 1st Stage Gas Compressor',
              children: [
                {
                  isOpen: true,
                  name: '23-GT-2331A- Gas turbine driver LM2500 SAC Gas Fuel',
                  children: [
                    {
                      isOpen: true,
                      name: '23-BE-2331A-Combustion ignition unit'
                    },
                    {
                      isOpen: true,
                      name: '23-IG-2352A-Combustion ignition plug'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2353A-HP recoup pressure'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2355A-PT inlet pressure (P48)'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2356A-GG inlet air pressure (P2)'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2346AA-GG speed'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2346BA-GG speed'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2347AA-PT speed'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2347BA-PT speed'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337AA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337BA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337CA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337DA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337EA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337GA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337HA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337IA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337JA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2337KA-PT inlet temperature (T4.8)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2348AA-GG inlet temperature (T2)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2348BA-GG inlet temperature (T2)'
                    },
                    {
                      isOpen: true,
                      name: '23-VE-2359A-GG forward vibration'
                    },
                    {
                      isOpen: true,
                      name: '23-VI-2359A-GG forward vibration'
                    },
                    {
                      isOpen: true,
                      name: '23-VE-2360A-PT aft vibration'
                    },
                    {
                      isOpen: true,
                      name: '23-VI-2360A-PT aft vibration'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-FS-2349A- Air intake filter house',
                  children: [
                    {
                      isOpen: true,
                      name: '23-ELH-2332A -Gas turbine enclosure heater '
                    },
                    {
                      isOpen: true,
                      name: '23-HS-2333AA-Local shut-down switches'
                    },
                    {
                      isOpen: true,
                      name: '23-HS-2333BA-Local shut-down switches'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2336AA-Ventilation fans'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2336BA-Ventilation fans'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2336AA-Ventilation fan electrical motors'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2336BA-Ventilation fan electrical motors'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2331A-Diff. pressure transmitter, combustion air filter'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2333AA-Diff. pressure transmitter, enclosure to ambient'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2333BA-Diff. pressure transmitter, enclosure to ambient'
                    },
                    {
                      isOpen: true,
                      name: '23-TIT-2336BA-Temperature element (ventilation outlet)'
                    },
                    {
                      isOpen: true,
                      name: '23-TZT-2336AA-Temperature element (ventilation outlet)'
                    },
                    {
                      isOpen: true,
                      name: '23-XD-2336AA-Enclosure shut-off dampers with actuators'
                    },
                    {
                      isOpen: true,
                      name: '23-XD-2336BA-Enclosure shut-off dampers with actuators'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2336A-Enclosure shut-off damper shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2336AA-Limit switch for enclosure inlet and outlet dampers'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2336BA-Limit switch for enclosure inlet and outlet dampers'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2336AA-Limit switch for enclosure inlet and outlet dampers'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2336BA-Limit switch for enclosure inlet and outlet dampers'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2351AA-Limit switch for turbine enclosure door'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2351BA-Limit switch for turbine enclosure door'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2351CA-Limit switch for turbine enclosure door'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-XZ-2331A- HV synchronous generator',
                  children: [
                    {
                      isOpen: true,
                      name: '23-ELH-2335A-MA-Generator space heater'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2332AA-Generator cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2332BA-Generator cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2332CA-Generator cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2332DA-Generator cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2332AA-Generator cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2332BA-Generator cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2332CA-Generator cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2332DA-Generator cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2382A-Key phasor'
                    },
                    {
                      isOpen: true,
                      name: '23-ST-2382A-Key phasor'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2332AA-Temperature element, exciter hot air'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2332BA-Temperature element, exciter hot air'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2334AA-Temperature element, bearing temp NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2334BA-Temperature element, bearing temp NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2335AA-Temperature element, cold air temp'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2335BA-Temperature element, cold air temp'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2351AA-Temperature element, generator stator phase U'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2351BA-Temperature element, generator stator phase U'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2352AA-Temperature element, generator stator phase V'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2352BA-Temperature element, generator stator phase V'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2353AA-Temperature element, generator stator phase W'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2353BA-Temperature element, generator stator phase W'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2385AA-Temperature element, thrust bearing DE temp'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2385BA-Temperature element, thrust bearing DE temp'
                    },
                    {
                      isOpen: true,
                      name: '23-VE-2391AA-Vibration sensor, generator bearing NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-VE-2391BA-Vibration sensor, generator bearing NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-VT-2391AA-Vibration sensor, generator bearing NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-VT-2391BA-Vibration sensor, generator bearing NDE'
                    },
                    {
                      isOpen: true,
                      name: '23-ZE-2392AA-Vibration sensor, generator bearing DE'
                    },
                    {
                      isOpen: true,
                      name: '23-ZE-2392BA-Vibration sensor, generator bearing DE'
                    },
                    {
                      isOpen: true,
                      name: '23-ZT-2392AA-Vibration sensor, generator bearing DE'
                    },
                    {
                      isOpen: true,
                      name: '23-ZT-2392BA-Vibration sensor, generator bearing DE'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-FIC-2338 - Gas fuel metering valve drive',
                  children: [
                    {
                      isOpen: true,
                      name: '23-FV-2338-Gas fuel metering valv'
                    },
                    {
                      isOpen: true,
                      name: '23-FT-2331-Flow meter, gas fuel suppl'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2334-Pressure transmitter, gas fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-SDV-2339-Gas fuel sec. shut-off valv'
                    },
                    {
                      isOpen: true,
                      name: '23-SDV-2340-Gas fuel prim. shut-off valv'
                    },
                    {
                      isOpen: true,
                      name: '23-SXY-2339A-Solenoid, gas fuel secondary shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-SXY-2340A-Solenoid, gas fuel primary shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-TIT-2340AA-Temperature element w/thermowell, gas fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-TIT-2340BA-Temperature element w/thermowell, gas fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-TW-2340AA-Temperature element w/thermowell, gas fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-TW-2340BA-Temperature element w/thermowell, gas fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-XV-2341A-Gas fuel vent-to-flare valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XV-2345A-Gas fuel shut-off bleed valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2341A-Solenoid, gas fuel vent-to-flare.valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2345A-Solenoid, gas fuel shut-off.bleed valve'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2339A-Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2339A-Limit switch, gas fuel sec. shut-off valve closed/open (part of 75-SDV-2339A)'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2340A-Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2340A-Limit switch, gas fuel prim.shut-off valve closed/open (part of 75-SDV-2340A)'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2341A-Limit switch, gas fuel vent-to-flare valve closed/open'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2341A-Limit switch, gas fuel vent-to-flare valve closed/open'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2345A-Limit switch, gas fuel shut-off bleed valve closed/open'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2345A-Limit switch, gas fuel shut-off bleed valve closed/open'
                    },
                    {
                      isOpen: true,
                      name: '23-ZT-2338A-Displacement transmitter, gas fuel metering valve (part of 75-FV-2338A)'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2340A-Gas fuel supply strainer'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2334A- Liquid fuel pump skid',
                  children: [
                    {
                      isOpen: true,
                      name: '23-FF-2331AA-Liquid fuel filter'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2331BA-Liquid fuel filter'
                    },
                    {
                      isOpen: true,
                      name: '23-FIC-2335A-Liquid fuel metering valve digital driver'
                    },
                    {
                      isOpen: true,
                      name: '23-FT-2332A-Flow meter, liquid fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-FV-2332A-Flow divider'
                    },
                    {
                      isOpen: true,
                      name: '23-FV-2335A-Liquid fuel metering valve'
                    },
                    {
                      isOpen: true,
                      name: '23-LG-2336A-Level gauge, hydrocarbon tank'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2336A-Liquid fuel supply pump motor'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2334A-Diff. pressure transmitter, liquid fuel supply filter'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2331A-Pressure transmitter, liquid fuel supply'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2340AA-Pressure transmitter, liquid fuel supply pump suction'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2340BA-Pressure transmitter, liquid fuel supply pump suction'
                    },
                    {
                      isOpen: true,
                      name: '23-PR-2336A-Liquid fuel supply pump'
                    },
                    {
                      isOpen: true,
                      name: '23-PSV-2332A-Safety relief valve, liquid fuel pump protection'
                    },
                    {
                      isOpen: true,
                      name: '23-SDV-2331A-Liquid fuel secondary shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-SXY-2331A-Solenoid, liquid fuel sec shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-SXY-2335A-Liquid fuel primary shut-off valve (part of 75-FV-2335A)'
                    },
                    {
                      isOpen: true,
                      name: '23-TA-2362A-Hydrocarbon tank'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2344A-Temperature element, prim.liquid fuel manifold'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2345A-Temperature element, sec liquid fuel manifold'
                    },
                    {
                      isOpen: true,
                      name: '23-XV-2332A-Liquid fuel manifold purge valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XV-2333A-Liquid fuel primary drain valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XV-2334A-Liquid fuel secondary drain valve'
                    },
                    {
                      isOpen: true,
                      name: 'SG-2387A-Sight glass, liquid fuel filter'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2336A-Liquid fuel supply strainer'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSC-2334A-Limit switch, liquid fuel sec. drain valve'
                    },
                    {
                      isOpen: true,
                      name: '23-ZSO-2334A-Limit switch, liquid fuel sec. drain valve'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2355A - Synthetic lube oil system',
                  children: [
                    {
                      isOpen: true,
                      name: '23-AE-2331AA-Chip detector'
                    },
                    {
                      isOpen: true,
                      name: '23-AE-2331BA-Chip detector'
                    },
                    {
                      isOpen: true,
                      name: '23-AE-2331CA-Chip detector'
                    },
                    {
                      isOpen: true,
                      name: '23-AE-2331DA-Chip detector'
                    },
                    {
                      isOpen: true,
                      name: '23-AE-2331EA-Chip detector'
                    },
                    {
                      isOpen: true,
                      name: '23-ELH-2331A-Electric heater, SLO tank'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2334AA-Duplex filter unit, SLO scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2334BA-Duplex filter unit, SLO scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2335AA-Duplex filter unit, SLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2335BA-Duplex filter unit, SLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-LG-2363A-Level gauge, tank level'
                    },
                    {
                      isOpen: true,
                      name: '23-LIT-2362A-Level transmitter, tank level'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2367A-Differential pressure transmitter (supply filter)'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2374A-Differential pressure transmitter (scavenge filter)'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2338AA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2338BA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2339AA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2339BA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2342AA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2342BA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2343AA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2343BA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2346AA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2346BA-Temperature element, aux. gearbox and sumps A-D scavenge'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2332A-Strainer, hydraulic start pump case drain'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2343A-Strainer, hydraulic start pump case drain'
                    },
                    {
                      isOpen: true,
                      name: 'FA-2354A-SLO flame arrester'
                    },
                    {
                      isOpen: true,
                      name: '23-VZ-2317A-SLO demister'
                    },
                    {
                      isOpen: true,
                      name: '23-TV-2331A-Temperature control valve'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2344A - Mineral lube oil system',
                  children: [
                    {
                      isOpen: true,
                      name: '23-ELH-2337A-Electric heater, MLO tank'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2332AA-Duplex filter unit, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2332BA-Duplex filter unit, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2351A-Single filter, jacking oil'
                    },
                    {
                      isOpen: true,
                      name: '23-FV-2351A-Jacking oil diverter'
                    },
                    {
                      isOpen: true,
                      name: '23-HA-2331AA-Common lube oil cooler'
                    },
                    {
                      isOpen: true,
                      name: '23-HA-2331BA-Common lube oil cooler'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2334AA-Lube oil cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2334BA-Lube oil cooler fan'
                    },
                    {
                      isOpen: true,
                      name: '23-KF-2340A-MLO demister fan'
                    },
                    {
                      isOpen: true,
                      name: '23-LG-2383A-Level glass, MLO tank'
                    },
                    {
                      isOpen: true,
                      name: '23-LIT-2351A-A Level transmitter,'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2339A-MLO pump motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2340A-MLO demister fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2341A-Jacking oil pump motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2334AA-Lube oil cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2334BA-Lube oil cooler fan motor'
                    },
                    {
                      isOpen: true,
                      name: '23-PCV-2333A-Pressure control valve, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-PCV-2351A-Pressure control valve, jacking oil'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2382A-Differential pressure transmitter, MLO filters'
                    },
                    {
                      isOpen: true,
                      name: '23-PDIT-2383A-Differential pressure transmitter, MLO tank vent'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2351A-Pressure gauge, jacking oil'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2384AA-Pressure transmitter, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2384BA-Pressure transmitter, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-PR-2339A-MLO supply pump'
                    },
                    {
                      isOpen: true,
                      name: '23-PR-2341A-Jacking oil pump'
                    },
                    {
                      isOpen: true,
                      name: '23-PR-2342A-Shaft-driven pump, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-TIT-2379A-Temperature element w/thermowell, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: '23-TW-2379AA-Temperature element w/thermowell, MLO supply'
                    },
                    {
                      isOpen: true,
                      name: 'FA-2336A-MLO flame arrester'
                    },
                    {
                      isOpen: true,
                      name: 'SG-2384A-Sight glass, generator bearing outlet'
                    },
                    {
                      isOpen: true,
                      name: 'SG-2385A-Sight glass, MLO tank'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2332A - Hydraulic start system',
                  children: [
                    {
                      isOpen: true,
                      name: '23-AZ-2332A-Hydraulic start module'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2333A-Filter, hydraulic start supply and return'
                    },
                    {
                      isOpen: true,
                      name: '23-FF-2337A-Filter, hydraulic start supply and return'
                    },
                    {
                      isOpen: true,
                      name: '23-FV-2345A-Hydraulic start pump control valve'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2333A-Hydraulic start motor'
                    },
                    {
                      isOpen: true,
                      name: '23-ME-2338A-Hydraulic start pump motor (el.)'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2343A-Hydraulic start supply and return pressure indicators'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2344A-Hydraulic start supply and return pressure indicators'
                    },
                    {
                      isOpen: true,
                      name: '23-PZ-2338A-Hydraulic start pump'
                    },
                    {
                      isOpen: true,
                      name: '23-SE-2342A-Starter motor speed sensor'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2341AA-Temperature element for lube oil scavenge line from clutch'
                    },
                    {
                      isOpen: true,
                      name: '23-TE-2341BA-Temperature element for lube oil scavenge line from clutch'
                    },
                    {
                      isOpen: true,
                      name: '23-XZ-2355A-Hydraulic starter clutch'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2341A-Hydr. start clutch outlet strainer'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-FS-2349A - Air inlet and ventilation system',
                  children: [
                    {
                      isOpen: true,
                      name: '23-XY-2336A-Ventilation shut-off dampers'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2345A-Gas fuel shut-off valves'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2341A-Gas fuel shut-off valves'
                    },
                    {
                      isOpen: true,
                      name: '23-SXY-2331A-Liquid fuel sec. shut-off valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2332A-Liquid fuel manifold purge valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2333A-Liquid fuel manifold drain valves'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2334A-Liquid fuel manifold drain valves'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2352A-Quick filling valve'
                    },
                    {
                      isOpen: true,
                      name: '23-FZ-2332A-Air filter regulator'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2333A - Fire and gas system',
                  children: [
                    {
                      isOpen: true,
                      name: '23-FD-4540A-101-Flame detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-FD-4540A-102-Flame detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-FD-4540A-103-Flame detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-101-Point gas detector, filter house'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-102-Point gas detector, filter house'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-103-Point gas detector, filter house'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-104-Point gas detector, ventilation outlet'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-105-Point gas detector, ventilation outlet'
                    },
                    {
                      isOpen: true,
                      name: '23-GD-4540A-106-Point gas detector, ventilation outlet'
                    },
                    {
                      isOpen: true,
                      name: '23-HD-4540A-101-Heat detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-HD-4540A-102-Heat detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-HD-4540A-103-Heat detector, turbine enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-HS-4540A-101-Manual water mist release push button'
                    },
                    {
                      isOpen: true,
                      name: '23-HS-4540A-102-Manual water mist release push button'
                    },
                    {
                      isOpen: true,
                      name: '23-XI-4540A-101-Alarm beacon inside enclosure'
                    },
                    {
                      isOpen: true,
                      name: '23-XI-4540A-102-Alarm beacon inside enclosure'
                    }
                  ]
                },
                {
                  isOpen: true,
                  name: '23-AZ-2333A- Water mist skid',
                  children: [
                    {
                      isOpen: true,
                      name: '23-LG-2335A-Level gauge, water mist vessel'
                    },
                    {
                      isOpen: true,
                      name: '23-PCV-2331A-Pressure control valve, water mist N2 supply'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2336A-Pressure gauge, N2 bottle'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2337A-Pressure gauge, N2 bottle'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2338A-Pressure gauge, water mist pressure'
                    },
                    {
                      isOpen: true,
                      name: '23-PG-2339A-Pressure gauge, water mist pressure'
                    },
                    {
                      isOpen: true,
                      name: '23-PIT-2333A-Pressure transmitter, water mist release'
                    },
                    {
                      isOpen: true,
                      name: '23-PSV-2334A-Safety relief valve, water mist N2 supply'
                    },
                    {
                      isOpen: true,
                      name: '23-VB-2340A-Water mist vessel'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2350A-Water mist release valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2354A-Water mist release valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2351A-Water mist N2 release valve'
                    },
                    {
                      isOpen: true,
                      name: '23-XY-2353A-Water mist N2 release valve'
                    },
                    {
                      isOpen: true,
                      name: 'SY-2342A-Strainer, water mist vessel filling'
                    }
                  ]
                }
              ]
            },
            {
              isOpen: true,
              name: 'K-T7112 - 2nd Stage Gas Compressor',
              children: []
            }
          ]
        },
        {
          isOpen: true,
          name: 'ELECTRIC GENERATORS',
          children: []
        },
        {
          isOpen: true,
          name: 'ELECTRIC MOTORS',
          children: []
        },
        {
          isOpen: true,
          name: 'GAS TURBINES',
          children: []
        },
        {
          isOpen: true,
          name: 'PUMPS',
          children: []
        },
        {
          isOpen: true,
          name: 'STEAM TURBINES',
          children: []
        },
        {
          isOpen: true,
          name: 'TURBOEXPANDERS',
          children: []
        }
      ]
    },
    {
      isOpen: true,
      name: 'SYSTEM 24-GAS TREATMENT',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 25-GAS CONDITIONING',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 27-GAS EXPORT / INJECTION / LIFT AND METERING',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 29-WATER INJECTION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 40-COOLING MEDIUM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 41-HEATING MEDIUM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 42-CHEMICAL INJECTION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 41-PUBLIC ADDRESS & GENERAL ALARM SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 42-TELECOMMUNICATION SYSTEMS',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 43-ENTERTAINMENT SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 44-CONTROL SYSTEM INCL ICSS AND CCR',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 45-FIRE & GAS DETECTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 48-EMERGENCY SHUTDOWN SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 51-HVAC SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 52-WASTE WATER TREATMENT',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 53-SEA WATER SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 54-FRESH / DISTILLATE /SERVICE WATER SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 55-ANTI-FOULING AND MARINE GROWTH PREVENTION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 56-AIR SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 57-BOILERS',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 58-HYDRAULIC SYSTEM (CARGO SYSTEM)',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 59-NITROGEN SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 61-FUEL GAS SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 62-LIQUID OR DIESEL OIL SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 63-SEA WATER INJECTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 64-CLOSED DRAIN SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 65-OPEN DRAIN SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 66-HEATING SYSTEM INCLUDING STEAM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 67-FRESH WATER COOLING SYS.',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 68-CHEMICAL INJECTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 69-HOT WATER SYSTEM (TOPSIDE)',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 71-POWER GENERATION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 72-SMALL POWER & LIGHTING SYSTEM ',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 73-UPS / BATTERIES SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 74-EARTHING SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 75-HV/MV (>1 KV) POWER DISTRIBUTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 76-LV (<1 KV) POWER DISTRIBUTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 77-ESSENTIAL/EMERGENCY POWER GENERATION ',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 78-EMERGENCY POWER DISTRIBUTION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 81-FIRE WATER SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 82-SAFETY EQUIPMENT',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 83-FIRE SUPPRESSION SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 84-PORTABLE FIRE FIGHTING EQUIPMENT',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 86-SECURITY SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 89-NAVIGATION AIDS',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 90-AREA ACCEPTANCE',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 91-MOORING SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 92-BALLAST SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 93-BILGE SYSTEM',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 94-ENGINE ROOM MACHINERY',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 95-CATHODIC PROTECTION AND CORROSION PREVENTION',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 96-MATERIAL HANDLING EQUIPMENT',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 97-LAB, WORKSHOP AND STORES ',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 98-HOTEL SERVICES & GALLEY',
      children: []
    },
    {
      isOpen: true,
      name: 'SYSTEM 99-HELIDECK',
      children: []
    }
  ]
}
