import { Badge } from 'reactstrap'
import { FaCubes } from 'react-icons/fa'
import { FiChevronsLeft, FiChevronsRight, FiXCircle } from 'react-icons/fi'
import { GrStatusCriticalSmall } from 'react-icons/gr'
import { IoCreateOutline, IoPieChartSharp } from 'react-icons/io5'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { MdAssignmentAdd, MdRecommend } from 'react-icons/md'
import {
  PiBoundingBoxLight,
  PiPrinterThin,
  PiBiohazardThin
} from 'react-icons/pi'
import { SiAzurefunctions } from 'react-icons/si'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import {
  TbDatabaseSearch,
  TbHierarchy3,
  TbListDetails,
  TbBoxModel2
} from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

const ICON_SIZE = 25

const routes = [
  {
    route: 'hierarchy',
    label: 'Hierarchy',
    icon: <TbHierarchy3 size={ICON_SIZE} />
  },
  {
    route: 'assetcriticality',
    label: 'Criticality',
    icon: <GrStatusCriticalSmall size={ICON_SIZE} />
  },
  {
    route: 'riskmatrix',
    label: 'Risk Matrix',
    icon: <FaCubes size={ICON_SIZE} />
  },
  {
    route: 'createAnalysis',
    label: 'RCM Analysis',
    icon: <IoCreateOutline size={ICON_SIZE} />
  },
  {
    route: 'stage2',
    label: 'Boundary',
    icon: <PiBoundingBoxLight size={ICON_SIZE} />
  },
  {
    route: 'stage3',
    label: 'Function',
    icon: <SiAzurefunctions size={ICON_SIZE} />
  },
  {
    route: 'stage4',
    label: 'Function Failure',
    icon: <FiXCircle size={ICON_SIZE} />
  },
  {
    route: 'stage5',
    label: 'Risk',
    icon: <PiBiohazardThin size={ICON_SIZE} />
  },
  {
    route: 'stage6',
    label: 'Assign Task',
    icon: <MdAssignmentAdd size={ICON_SIZE} />
  },
  {
    route: 'stage7',
    label: 'Task Decision',
    icon: <TbListDetails size={ICON_SIZE} />
  },
  {
    route: 'stage8',
    label: 'Recommendation',
    icon: <MdRecommend size={ICON_SIZE} />
  },
  {
    route: 'stage9',
    label: 'Task Approval',
    icon: <IoMdCheckmarkCircleOutline size={ICON_SIZE} />
  },
  {
    route: 'print',
    label: 'Approved RCM',
    icon: <PiPrinterThin size={ICON_SIZE} />
  },
  {
    route: 'kpi',
    label: 'KPI',
    icon: <IoPieChartSharp size={ICON_SIZE} />
  },
  {
    route: 'search',
    label: 'Clamp Ai',
    icon: <TbDatabaseSearch size={ICON_SIZE} />
  },
  {
    route: 'fmea',
    label: 'FMEA',
    icon: <TbBoxModel2 size={ICON_SIZE} />
  }
]

const NavItem = ({
  route,
  label,
  icon,
  count
}: {
  route: any
  label: any
  icon: any
  count: any
}) => {
  const [hover, setHover]: any = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentTab = pathname?.split('/')?.[3] || 'dashboard'

  const navigateTo = (route: any) => navigate(`/cmms/rcm/${route}`)

  const styles = {
    normal: {
      backgroundColor: currentTab === route ? 'gray' : '#FAFAD2',
      fontWeight: 600,
      color: currentTab === route ? '#FFF' : '#000',
      padding: 5,
      paddingLeft: 15
    },
    hover: {
      background: '#D7D7D7',
      fontWeight: 600,
      padding: 5,
      paddingLeft: 15
    }
  }

  return (
    <MenuItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={hover ? styles.hover : styles.normal}
      onClick={() => navigateTo(route)}
      icon={icon}
      suffix={
        count && (
          <Badge variant="danger" shape="circle" color="danger">
            {count}
          </Badge>
        )
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          fontSize: 18
        }}
      >
        {label}
      </div>
    </MenuItem>
  )
}

export default function RcmSideBar({ counts }: { counts?: any }) {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <div style={{ height: 'auto', backgroundColor: '#FAFAD2' }}>
        <Sidebar
          collapsed={collapsed}
          backgroundColor="#FAFAD2"
          style={{ height: 'auto', backgroundColor: '#FAFAD2' }}
        >
          <Menu style={{ marginTop: 10 }}>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                onClick={() => setCollapsed(false)}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FiChevronsLeft />}
                onClick={() => setCollapsed(true)}
              >
                <div
                  style={{
                    padding: '9px',
                    fontWeight: 900,
                    fontSize: 20,
                    letterSpacing: '1px'
                  }}
                >
                  RCM MENU
                </div>
              </MenuItem>
            )}
            {routes?.map((r: any) => (
              <NavItem
                key={r?.route}
                route={r?.route}
                label={r?.label?.toUpperCase()}
                icon={r?.icon}
                count={r?.count}
              />
            ))}
          </Menu>
        </Sidebar>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={require('./../../images/e73_logo.jpeg')}
            style={{
              margin: 10,
              mixBlendMode: 'darken',
              height: 100,
              textAlign: 'center'
            }}
            alt="logo"
          />
        </div>
      </div>
    </>
  )
}
