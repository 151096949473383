import CreatableSelect from 'react-select/creatable'
import ReactToPrint from 'react-to-print'
import {
  Col,
  Input,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { useEffect, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import {
  detectData,
  occurenceData,
  severityData,
  tabs
} from './constants/fmcaData'
import { getOptions } from '../firs'
import { Header, renderData } from './RcmAnalysisPrint'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { MEDIUM_RISK_COLOR } from '../../components/constants/riskMatrixColors'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { RCMButton, TableForm } from './RcmComponents'
import { theme } from './constants/brandkit'
import { getReadableTime } from '../workManagement/WorkOrderDetails'

const DARK_GREEN = '#008000'
const LIGHT_GREEN = '#90EE90'
const ORANGE = '#FFA500'
const RED = '#FF0000'
const YELLOW = '#FFFF00'

const Stage2 = ({
  currentAnalysisData,
  refetch,
  data
}: {
  currentAnalysisData: any
  refetch: any
  data: any
}) => {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const failureModes = currentAnalysisData?.failuresModes || []
  const [entries, setEntries] = useState(failureModes)

  useEffect(
    () => setEntries(failureModes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const handleInputChange = async (
    categoryKey: string,
    index: number,
    field: string,
    value: any
  ) => {
    // Function to calculate oldRpn
    const calculateOldRpn = (failure: any) => {
      const { severity, detect, occurence } = failure
      return severity && detect && occurence
        ? Number(severity) * Number(detect) * Number(occurence)
        : ''
    }

    const handleFailureChange = (failureIndex: number, category: any) => {
      const updatedFailures = category.failures?.map(
        (failure: any, i: number) =>
          i === failureIndex
            ? {
                ...failure,
                [field]: value,
                oldRpn: calculateOldRpn({ ...failure, [field]: value })
              }
            : failure
      )

      return { ...category, failures: updatedFailures }
    }

    const updatedEntries = entries.map((entry: any) =>
      entry.name === categoryKey ? handleFailureChange(index, entry) : entry
    )

    setEntries(updatedEntries)

    const body: any = {
      ...currentAnalysisData,
      failuresModes: updatedEntries
    }

    const res = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'PATCH',
      body,
      accounts,
      instance
    )

    if (res.status === 'success') {
      await refetch()
    }
  }

  const sodColors = (score: any) => {
    if (score <= 100) {
      return DARK_GREEN
    } else if (score >= 101 && score <= 300) {
      return LIGHT_GREEN
    } else if (score >= 301 && score <= 600) {
      return YELLOW
    } else if (score >= 601 && score <= 700) {
      return ORANGE
    } else if (score >= 701 && score <= 1000) {
      return RED
    }
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        margin: 0,
        padding: 10,
        borderRadius: 10
      }}
    >
      {entries.map((mode: any, modeIndex: number) => (
        <div key={modeIndex}>
          <h4>{mode.name}</h4>
          <p className="m-0">
            <strong>Associated Equipment:</strong>{' '}
            {mode?.tags?.map((a: any) => a?.equipmentNumber)?.join(', ')}{' '}
          </p>
          <p className="m-0">
            <strong>Functions:</strong>{' '}
            {currentAnalysisData?.functions?.[mode?.name]}
          </p>
          <p>
            <strong>Function Failure:</strong>{' '}
            {entries?.[mode?.name]?.[0]?.functionalFailure}
          </p>
          <Table striped className="text-center" style={{ fontSize: 18 }}>
            <thead>
              <tr style={{ backgroundColor: 'lightgray' }}>
                <th style={{ width: '2vw' }} className="top-left">
                  S.No
                </th>
                <th style={{ width: '10vw' }} className="text-left">
                  Failure modes
                </th>
                <th style={{ width: '10vw' }}>Failure Effect</th>
                <th style={{ width: '10vw' }}>Failure Characteristic</th>
                <th style={{ width: '10%' }}>Severity</th>
                <th style={{ width: '10%' }}>Occurence</th>
                <th style={{ width: '10%' }}>Detectability</th>
                <th className="top-right" style={{ width: '5vw' }}>
                  RPN
                </th>
                {/* <th>Hidden / Evident</th> */}
                {/* <th>Completion Date</th> */}
                {/* <th >Recommended Task</th> */}
              </tr>
            </thead>
            <tbody>
              {mode?.failures?.map((entry: any, index: number) => (
                <tr key={index}>
                  <td style={{ alignContent: 'center' }}>{index + 1}</td>
                  <td
                    style={{
                      alignContent: 'center',
                      textAlign: 'left'
                    }}
                  >
                    {entry.failureMode}
                  </td>
                  <td
                    style={{
                      alignContent: 'center',
                      textAlign: 'left'
                    }}
                  >
                    {entry.failureEffect}
                  </td>
                  <td
                    style={{
                      alignContent: 'center',
                      textAlign: 'left'
                    }}
                  >
                    {entry.failureChar}
                  </td>
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.severity}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'severity', v)
                      }
                      title={'SEVERITY'}
                      headers={[
                        'Risk Level',
                        'Category',
                        'Description',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={severityData}
                    />
                  </td>
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.occurence}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'occurence', v)
                      }
                      title={'OCCURANCE'}
                      headers={[
                        'Risk Level',
                        'Likelihood',
                        'Probability of Failure',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={occurenceData}
                    />
                  </td>
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.detect}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'detect', v)
                      }
                      title={'DETECTABLITY'}
                      headers={[
                        'Risk Level',
                        'Detectability',
                        'Likelihood of Detection',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={detectData}
                    />
                  </td>
                  <td
                    style={{ alignContent: 'center' }}
                    className="align-content-center"
                  >
                    {entry?.oldRpn && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 60,
                          cursor: 'pointer',
                          backgroundColor: sodColors(entry?.oldRpn) || 'gray',
                          opacity: 1,
                          fontSize: 22,
                          fontWeight: 900,
                          border: '5px solid #FFF'
                        }}
                      >
                        {entry?.oldRpn}
                      </Col>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      <ModalFooter>
        <RCMButton onClick={() => setSubmit(true)}>Submit</RCMButton>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={submit}
        onClose={(val: any) => {
          setSubmit(false)
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()

          const body: any = {
            ...currentAnalysisData,
            status: 'Fmea2'
          }

          const res = await makeAuthenticatedRequest(
            `${rcmApi}/rcmAnalysis/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
            setResult(res?.analysis)
          }

          return res.status
        }}
        sucessView={<p>RCM Number: {result?.number}</p>}
        size="xs"
        header="Failure Modes"
      />
    </div>
  )
}

const Print = ({
  currentAnalysisData,
  refetch,
  data
}: {
  currentAnalysisData: any
  refetch: any
  data: any
}) => {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const failureModes =
    currentAnalysisData?.failuresModes?.map((mode: any) => ({
      ...mode,
      failures: mode?.failures?.sort(
        (a: any, b: any) => Number(b?.oldRpn) - Number(a?.oldRpn)
      )
    })) || []

  const [entries, setEntries] = useState(failureModes)

  useEffect(
    () => setEntries(failureModes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const sodColors = (score: any) => {
    if (score <= 100) {
      return DARK_GREEN
    } else if (score >= 101 && score <= 300) {
      return LIGHT_GREEN
    } else if (score >= 301 && score <= 600) {
      return YELLOW
    } else if (score >= 601 && score <= 700) {
      return ORANGE
    } else if (score >= 701 && score <= 1000) {
      return RED
    }
  }

  const ScoreRenderer = ({ score }: { score: any }) => {
    return (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 40,
          cursor: 'pointer',
          backgroundColor: sodColors(score) || 'gray',
          // background: `linear-gradient(.25turn,${sodColors(
          //   score
          // )},90% ,#F5F5F5)`,
          opacity: 1,
          fontSize: 18,
          fontWeight: 900,
          border: '5px solid #fff'
        }}
      >
        {score}
      </Col>
    )
  }

  const SODRender = ({ score }: { score: any }) => {
    const sodColors = (s: any) => {
      const score = Number(s)
      if (score <= 1) {
        return DARK_GREEN
      } else if (score >= 2 && score <= 3) {
        return LIGHT_GREEN
      } else if (score >= 4 && score <= 6) {
        return YELLOW
      } else if (score >= 7 && score <= 8) {
        return ORANGE
      } else if (score >= 8 && score <= 10) {
        return RED
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 40,
          padding: 2,
          paddingLeft: 4,
          cursor: 'pointer',
          backgroundColor: sodColors(score) || 'gray',
          // background: `linear-gradient(.25turn,${sodColors(
          //   score
          // )},90% ,#F5F5F5)`,
          opacity: 1,
          fontSize: 14,
          width: 30,
          fontWeight: 900,
          border: '5px solid #fff'
        }}
      >
        {score}
      </div>
    )
  }
  const getPageMargins = () => {
    return `@page { margin: 5px 5px 5px 5px !important; }`
  }
  const componentRef: any = useRef()
  const number = currentAnalysisData?.number?.split('-')
  const fmeaNumber = 'FMEA-'.concat(number?.[1])

  const rcmDetails: any = {
    'Client Unit': currentAnalysisData?.plantUnit,
    System: currentAnalysisData?.system,
    'Sub System': currentAnalysisData?.subSystem,
    'System Location': currentAnalysisData?.location,
    'Analysis Description': currentAnalysisData?.analysisDescription
  }

  // const aboveTolerable = entries
  //   ?.map(
  //     (risk: any) => risk?.failures?.filter((f: any) => f?.oldRpn > 250)?.length
  //   )
  //   ?.reduce((acc: any, curr: any) => acc + curr, 0)

  // const belowTolerable = entries
  //   ?.map(
  //     (risk: any) => risk?.failures?.filter((f: any) => f?.oldRpn < 250)?.length
  //   )
  //   ?.reduce((acc: any, curr: any) => acc + curr, 0)

  const insights: any = {
    'Tolerable RPN Value': 'NA',
    'PM Data Analysis from ABAN CMMS': 29035,
    'CM Data Analysis from ABAN CMMS': 8043,
    'No of Failure Modes Input from ABAN CM': 244
  }

  const totalFailureModes = entries
    ?.map((risk: any) => risk?.failures?.length)
    ?.reduce((acc: any, curr: any) => acc + curr, 0)

  const totalOldRpn = entries
    ?.flatMap(
      (risk: any) => risk?.failures?.map((f: any) => Number(f?.oldRpn) || 0)
    )
    ?.reduce((acc: number, curr: number) => acc + curr, 0)

  const totalNewRpn = entries
    ?.flatMap(
      (risk: any) => risk?.failures?.map((f: any) => Number(f?.newRpn) || 0)
    )
    ?.reduce((acc: number, curr: number) => acc + curr, 0)

  const percentageReduction = ((totalOldRpn - totalNewRpn) / totalOldRpn) * 100

  return (
    <div
      style={{
        backgroundColor: '#fff',
        margin: 0,
        padding: 10,
        borderRadius: 10
      }}
    >
      <style>
        {`
        td {
            padding: 0px;
            margin: 0px;
            }

            tr {
            padding: 0px;
            margin: 0px;
            }

          @media print {
            @page {
              size: landscape;
              margin: 20mm;
            }
             footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 10px;
    color: gray;
  }

  /* Use CSS counters for pages */
  footer::after {
    content: "Page " counter(page) " of " counter(pages);
  }

            td {
            padding: 0px;
            margin: 0px;
            }
              
            .print-header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            heihgt: 50px;
            text-align: center;
            background: #fff;
            padding: 10px;
            overflow: 'hiddden'
            font-size: 18px;
            z-index: 1000;
          }

            .print-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            text-align: center;
            background: #fff;
            paddingBottom: 20px;
            font-size: 18px;
            z-index: 1000;
          }

          :global {
    .patchPrintStyle {
        section {
            display: block !important;
        }
        article {
            height: 100% !important;
        }
        * {
            border: 0 none !important;
        }
    }
}
        `}
        {getPageMargins()}
      </style>
      <div
        ref={componentRef}
        style={{
          overflow: 'hiddden',
          display: 'flex',
          justifyContent: 'space-around',
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: '#fff'
        }}
      >
        <Row style={{ maxWidth: '87vw' }}>
          <Header number={fmeaNumber} isAban />
          <div
            className="content"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <h6 />
            <h6
              style={{
                textAlign: 'center',
                fontWeight: 700,
                // color: '#e8ac01',
                textDecoration: 'underline',
                textUnderlineOffset: 9,
                marginBottom: 10,
                marginTop: 10
              }}
            >
              CLAMP ID: {fmeaNumber}
            </h6>
            <h6
              style={{
                textAlign: 'center',
                fontWeight: 700,
                // color: '#e8ac01',
                textUnderlineOffset: 9,
                marginBottom: 10,
                marginTop: 10
              }}
            >
              Date : {getReadableTime(Date.now())}
            </h6>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{
                width: '50%',
                border: '1px solid #000',
                marginRight: 5,
                borderRadius: 5,
                padding: 10
              }}
            >
              <h6
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textUnderlineOffset: 3,
                  marginBottom: 10
                }}
              >
                FMEA BASIC DETAILS :
              </h6>
              {Object.keys(rcmDetails)?.map((k: any) =>
                renderData(k, rcmDetails[k], 14, '40%', '60%')
              )}
            </div>
            <div
              style={{
                width: '50%',
                border: '1px solid #000',
                borderRadius: 5,
                padding: 10
              }}
            >
              <h6
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textUnderlineOffset: 3,
                  marginBottom: 10
                }}
              >
                FMEA INSIGHTS :
              </h6>
              {Object.keys(insights)?.map(
                (k: any) => renderData(k, insights[k], 14, '60%', '40%'),
                8
              )}
            </div>
          </div>
          <div>
            <Spacer height={10} />
            <div
              style={{
                border: '1px solid #000',
                borderRadius: 5,
                padding: 12,
                display: 'flex',
                gap: 4
              }}
            >
              {[
                {
                  value: currentAnalysisData?.equipmentList?.length,
                  label: 'Systems Analysed'
                },
                { value: totalFailureModes, label: 'FM ANALYSED' },
                { value: '55', label: 'Equipment FM' },
                {
                  value: `${percentageReduction.toFixed(0)} %`,
                  label: 'RPN Reduction'
                }
              ]?.map((a: any) => (
                <div
                  style={{
                    height: '15vh',
                    width: '25%',
                    border: '1px solid #000',
                    borderRadius: 5
                  }}
                >
                  <div
                    style={{
                      height: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 48,
                        fontWeight: 700
                      }}
                    >
                      {a?.value}
                    </p>
                  </div>
                  <div
                    style={{
                      height: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.colors.primaryLight
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 18,
                        fontWeight: 700,
                        textAlign: 'center'
                      }}
                    >
                      {a?.label?.toUpperCase()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h6
            style={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              textUnderlineOffset: 3,
              marginTop: 10
            }}
          >
            TEAM MEMBERS :
          </h6>
          <Table
            className="min-vh-50"
            style={{
              fontSize: 12,
              borderCollapse: 'separate',
              borderSpacing: 3
            }}
          >
            <thead>
              <tr className="text-center">
                {['S.No', 'Name', 'Company', 'Department', 'Designation']?.map(
                  (h: any, ThIndex: any) => (
                    <th
                      key={h}
                      style={{
                        background: theme.colors.accent,
                        color: '#000'
                      }}
                    >
                      {h?.toUpperCase()}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {currentAnalysisData?.team?.map((e: any, Eindex: number) => (
                <tr key={e?.value} style={{}}>
                  {[
                    Eindex + 1,
                    'givenName',
                    'companyName',
                    'department',
                    'jobTitle'
                  ]?.map((a: any, DIndex: any) => (
                    <td
                      key={DIndex}
                      style={{
                        border: '1px solid #000',
                        color: '#000',
                        textAlign: 'center'
                      }}
                    >
                      {e?.[a] || a}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ pageBreakBefore: 'always' }}>
            <Spacer height={10} />
            {entries.map((mode: any, modeIndex: number) => (
              <div key={modeIndex}>
                {/* <Table
                  bordered
                  style={{
                    margin: 0,
                    marginTop: 5,
                    marginBottom: 5,
                    borderColor: 'gray',
                    fontSize: 12
                  }}
                >
                  <thead style={{ backgroundColor: theme.colors.accent }}>
                    <tr>
                      <th colSpan={3}>
                        <th>System: {mode?.name} </th>
                        <th>
                          Associated Equipment:{' '}
                          {mode?.tags
                            ?.map((a: any) => a?.equipmentNumber)
                            ?.join(', ')}
                        </th>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          width: '14%',
                          minWidth: '14%',
                          maxWidth: '14%'
                        }}
                        className="top-left"
                      >
                        System
                      </th>
                      <th style={{ width: '20%' }}>Associated Equipment</th>
                      <th style={{ width: '40%' }}>Function</th>
                      <th style={{ width: '40%' }} className="top-right">
                        Function Failure
                      </th>
                    </tr>
                  </thead>
                  <tr style={{ backgroundColor: '#f2f2f2' }}>
                    {[
                      {
                        value: <strong>{mode?.name}</strong>
                      },
                      {
                        label: 'Associated Tags',
                        value: mode?.tags
                          ?.map((a: any) => a?.equipmentNumber)
                          ?.join(', ')
                      },
                      {
                        value: currentAnalysisData?.functions?.[mode?.name],
                        label: 'Functions: '
                      },
                      {
                        value: mode?.failures?.[0]?.functionalFailure,
                        label: 'Function Failure:'
                      }
                    ]?.map((a, index) => (
                      <td
                        style={{
                          padding: 0,
                          paddingLeft: 4,
                          fontSize: 14,
                          borderLeft: '1px solid gray',
                          textAlign: index === 1 ? 'center' : 'left'
                        }}
                      >
                        {a?.value}
                      </td>
                    ))}
                  </tr>
                </Table> */}
                <Spacer height={10} />
                <Table
                  striped
                  bordered
                  className="text-center"
                  style={{ fontSize: 12, borderColor: '#212529' }}
                >
                  <thead style={{ backgroundColor: theme.colors.accent }}>
                    <tr style={{ backgroundColor: '#fff' }}>
                      <td
                        className="top-left"
                        colSpan={3}
                        style={{
                          textAlign: 'left',
                          border: '1px solid gray',
                          alignContent: 'center'
                        }}
                      >
                        <p className="m-0 text-left">
                          <strong>System: {mode?.name} </strong>
                        </p>
                        <p className="m-0 text-left">
                          <strong>Associated Equipment:</strong>
                          {mode?.tags
                            ?.map((a: any) => a?.equipmentNumber)
                            ?.join(', ')}
                        </p>
                      </td>
                      <td
                        className="top-right"
                        colSpan={8}
                        style={{
                          textAlign: 'left',
                          border: '1px solid gray'
                        }}
                      >
                        <p className="m-0 text-left">
                          <strong>Function:</strong>
                          {currentAnalysisData?.functions?.[mode?.name]}
                        </p>
                        <p className="m-0 text-left">
                          <strong>Function Failure:</strong>
                          {mode?.failures?.[0]?.functionalFailure}{' '}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ borderTop: '1px solid gray' }}>No</th>
                      <th
                        style={{ borderTop: '1px solid gray' }}
                        className="text-left"
                      >
                        Failure modes
                      </th>
                      <th
                        style={{ borderTop: '1px solid gray' }}
                        className="text-left"
                      >
                        Failure Efect
                      </th>
                      <th style={{ borderTop: '1px solid gray' }} colSpan={2}>
                        Old S O D | RPN
                      </th>
                      <th style={{ borderTop: '1px solid gray' }}>H/E</th>
                      <th style={{ borderTop: '1px solid gray' }}>
                        Maintenance Task
                      </th>
                      <th style={{ borderTop: '1px solid gray' }} colSpan={2}>
                        New S O D | RPN
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ borderColor: 'gray' }}>
                    {mode?.failures?.map((entry: any, index: number) => (
                      <tr key={index} style={{ borderColor: 'gray' }}>
                        <td
                          style={{
                            alignContent: 'center',
                            minWidth: '1%',
                            maxWidth: '1%',
                            width: '1%',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderColor: 'gray',
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            alignContent: 'center',
                            textAlign: 'left',
                            minWidth: '8%',
                            maxWidth: '8%',
                            width: '8%',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry.failureMode}
                        </td>
                        <td
                          style={{
                            alignContent: 'center',
                            textAlign: 'left',
                            minWidth: '20%',
                            maxWidth: '20%',
                            width: '20%',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry.failureEffect}
                        </td>
                        <td
                          style={{
                            minWidth: '2%',
                            maxWidth: '2%',
                            width: '2%',
                            textAlign: 'center',
                            alignContent: 'center',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {[
                              entry?.severity,
                              entry?.occurence,
                              entry?.detect
                            ]?.map((a: any) => <SODRender score={a} />)}
                          </div>
                        </td>
                        <td
                          style={{
                            alignContent: 'center',
                            minWidth: '2%',
                            maxWidth: '2%',
                            width: '2%',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry?.oldRpn && (
                            <ScoreRenderer score={entry?.oldRpn} />
                          )}
                        </td>
                        <td
                          style={{
                            minWidth: '3%',
                            maxWidth: '3%',
                            width: '3%',
                            alignContent: 'center',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry?.rcmDecisionCode}
                        </td>
                        <td
                          style={{
                            minWidth: '25%',
                            width: '25%',
                            maxWidth: '25%',
                            textAlign: 'left',
                            alignContent: 'center',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry?.maintenanceTask}
                        </td>
                        <td
                          style={{
                            minWidth: '2%',
                            maxWidth: '2%',
                            width: '2%',
                            textAlign: 'center',
                            alignContent: 'center',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {[
                              entry?.newSeverity,
                              entry?.newOccurence,
                              entry?.newDetect
                            ]?.map((a: any) => <SODRender score={a} />)}
                          </div>
                        </td>
                        <td
                          style={{
                            alignContent: 'center',
                            minWidth: '2%',
                            maxWidth: '2%',
                            width: '2%',
                            padding: 2,
                            paddingLeft: 4,
                            margin: 0,
                            borderBottom:
                              index === mode?.failures?.length - 1
                                ? '1px solid gray'
                                : 'none'
                          }}
                        >
                          {entry?.newRpn && (
                            <ScoreRenderer score={entry?.newRpn} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
            <ul
              style={{
                margin: 0,
                fontSize: 8,
                marginTop: 20,
                listStyle: 'none',
                padding: 0
              }}
            >
              <li>
                <b>Abbreviations</b>
              </li>
              <li>
                Evident: These are failures that are immediately detectable by
                the operator or monitoring systems
              </li>
              <li>
                Hidden: These are failures that do not have an immediate
                observable impact and may only be detected during inspections or
                diagnostics.
              </li>
              <li>
                O (Occurrence): The likelihood of a specific failure mode
                occurring
              </li>
              <li>
                D (Detection): The likelihood of detecting the failure before it
                causes harm
              </li>
              <li>RPN - Risk Priority Number</li>
              <li>FM - Failure Modes</li>
              <li>PM- Preventive Maintenance</li>
              <li>CM-Corrective Maintenance</li>
            </ul>
          </div>
        </Row>
      </div>

      <ReactToPrint
        bodyClass="patchPrintStyle"
        documentTitle={fmeaNumber}
        trigger={() => (
          <ModalFooter style={{ marginTop: 10 }}>
            <RCMButton children="Print" />
          </ModalFooter>
        )}
        content={() => componentRef.current}
      />
      <SubmitModalWithForm
        isOpen={submit}
        onClose={(val: any) => {
          setSubmit(false)
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()

          const body: any = {
            ...currentAnalysisData,
            status: 'Fmea2'
          }

          const res = await makeAuthenticatedRequest(
            `${rcmApi}/rcmAnalysis/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
            setResult(res?.analysis)
          }

          return res.status
        }}
        sucessView={<p>RCM Number: {result?.number}</p>}
        size="xs"
        header="Failure Modes"
      />
    </div>
  )
}

const Stage3 = ({
  currentAnalysisData,
  refetch,
  data
}: {
  currentAnalysisData: any
  refetch: any
  data: any
}) => {
  const { accounts, instance } = useMsal()
  const { id } = useParams()
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const failureModes = currentAnalysisData?.failuresModes || []
  const [entries, setEntries] = useState(failureModes)

  useEffect(
    () => setEntries(failureModes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const handleInputChange = async (
    categoryKey: string,
    index: number,
    field: string,
    value: any
  ) => {
    const calculateOldRpn = (failure: any) => {
      const { newSeverity, newDetect, newOccurence } = failure
      return newSeverity && newDetect && newOccurence
        ? Number(newSeverity) * Number(newDetect) * Number(newOccurence)
        : ''
    }

    const handleFailureChange = (failureIndex: number, category: any) => {
      const updatedFailures = category.failures?.map(
        (failure: any, i: number) =>
          i === failureIndex
            ? {
                ...failure,
                [field]: value,
                newRpn: calculateOldRpn({ ...failure, [field]: value })
              }
            : failure
      )

      return { ...category, failures: updatedFailures }
    }

    const updatedEntries = entries.map((entry: any) =>
      entry.name === categoryKey ? handleFailureChange(index, entry) : entry
    )

    setEntries(updatedEntries)

    const body: any = {
      ...currentAnalysisData,
      failuresModes: updatedEntries
    }

    const res = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'PATCH',
      body,
      accounts,
      instance
    )

    if (res.status === 'success') {
      await refetch()
    }
  }

  const sodColors = (score: any) => {
    if (score <= 100) {
      return DARK_GREEN
    } else if (score >= 101 && score <= 300) {
      return LIGHT_GREEN
    } else if (score >= 301 && score <= 600) {
      return YELLOW
    } else if (score >= 601 && score <= 700) {
      return ORANGE
    } else if (score >= 701 && score <= 1000) {
      return RED
    }
  }

  let debounceTimeout: any

  const handleDebouncedInputChange = (
    modeName: any,
    index: any,
    field: any,
    value: any
  ) => {
    // Clear the previous timeout to reset the debounce timer
    clearTimeout(debounceTimeout)

    // Set a new timeout to wait for the user to stop typing
    debounceTimeout = setTimeout(() => {
      handleInputChange(modeName, index, field, value)
    }, 500) // Adjust the delay (500ms) as needed
  }

  const ScoreRenderer = ({ score }: { score: any }) => {
    return (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          cursor: 'pointer',
          background: `linear-gradient(.25turn,${sodColors(
            score
          )},90% ,#F5F5F5)`,
          opacity: 1,
          fontSize: 22,
          fontWeight: 900,
          border: '5px solid #fff'
        }}
      >
        {score}
      </Col>
    )
  }

  const SODRenderer = ({ score }: { score: any }) => {
    const sodColors = (s: any) => {
      const score = Number(s)
      if (score <= 1) {
        return DARK_GREEN
      } else if (score >= 2 && score <= 3) {
        return LIGHT_GREEN
      } else if (score >= 4 && score <= 6) {
        return YELLOW
      } else if (score >= 7 && score <= 8) {
        return ORANGE
      } else if (score >= 8 && score <= 10) {
        return RED
      }
    }
    return (
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          cursor: 'pointer',
          background: `linear-gradient(.25turn,${sodColors(
            score
          )},90% ,#F5F5F5)`,
          opacity: 1,
          fontSize: 22,
          fontWeight: 900,
          border: '5px solid #fff'
        }}
      >
        {score}
      </Col>
    )
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        margin: 0,
        padding: 10,
        borderRadius: 10
      }}
    >
      {entries.map((mode: any, modeIndex: number) => (
        <div key={modeIndex}>
          <h4>{mode.name}</h4>
          <p className="m-0">
            <strong>Associated Equipment:</strong>{' '}
            {mode?.tags?.map((a: any) => a?.equipmentNumber)?.join(', ')}{' '}
          </p>
          <p className="m-0">
            <strong>Functions:</strong>{' '}
            {currentAnalysisData?.functions?.[mode?.name]}
          </p>
          <p>
            <strong>Function Failure:</strong>{' '}
            {entries?.[mode?.name]?.[0]?.functionalFailure}
          </p>
          <Table striped className="text-center" style={{ fontSize: 18 }}>
            <thead>
              <tr style={{ backgroundColor: 'lightgray' }}>
                <th style={{ width: '2vw' }} rowSpan={2} className="top-left">
                  S.No
                </th>
                <th style={{ width: '10vw' }} className="text-left">
                  Failure modes
                </th>
                <th style={{ width: '5%' }}>S</th>
                <th style={{ width: '5%' }}>O</th>
                <th style={{ width: '5%' }}>D</th>
                <th style={{ width: '2vw' }}>Old RPN</th>
                <th style={{ width: '5vw' }}>Decission</th>
                <th style={{ width: '5vw' }}>Maintenance Task</th>
                <th style={{ width: '5%' }}>S</th>
                <th style={{ width: '5%' }}>O</th>
                <th style={{ width: '5%' }}>D</th>
                <th className="top-right" style={{ width: '2vw' }}>
                  New RPN
                </th>
              </tr>
            </thead>
            <tbody>
              {mode?.failures?.map((entry: any, index: number) => (
                <tr key={index}>
                  <td style={{ alignContent: 'center' }}>{index + 1}</td>
                  <td
                    style={{
                      alignContent: 'center',
                      textAlign: 'left'
                    }}
                  >
                    {entry.failureMode}
                  </td>
                  <td>
                    <SODRenderer score={Number(entry?.severity)} />
                  </td>
                  <td>
                    <SODRenderer score={Number(entry?.detect)} />
                  </td>
                  <td>
                    <SODRenderer score={Number(entry?.occurence)} />
                  </td>
                  <td
                    style={{ alignContent: 'center' }}
                    className="align-content-center"
                  >
                    {entry?.oldRpn && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 60,
                          cursor: 'pointer',
                          background: `linear-gradient(.25turn,${sodColors(
                            Number(entry?.oldRpn)
                          )},90% ,#F5F5F5)`,
                          opacity: 1,
                          fontSize: 22,
                          fontWeight: 900,
                          border: '5px solid #FFF'
                        }}
                      >
                        {entry?.oldRpn}
                      </Col>
                    )}
                  </td>
                  <td>
                    <CreatableSelect
                      options={getOptions(['HIDDEN', 'EVIDENT'])}
                      isMulti={false}
                      value={{
                        key: entry?.rcmDecisionCode,
                        label: entry?.rcmDecisionCode
                      }}
                      onChange={(e: any) => {
                        handleInputChange(
                          mode?.name,
                          index,
                          'rcmDecisionCode',
                          e?.value
                        )
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      type="textarea"
                      // value={entry?.maintenanceTask}
                      style={{ height: 40 }}
                      onChange={(e) =>
                        handleDebouncedInputChange(
                          mode.name,
                          index,
                          'maintenanceTask',
                          e?.target?.value
                        )
                      }
                    />
                  </td>
                  {/* <td style={{ maxWidth: '5vw' }}>
                    <CreatableSelect
                      options={getOptions([
                        'Conditioned Monitoring',
                        'Functional Test',
                        'Calibration',
                        'Condition Based',
                        'Time Based',
                        'Operator Round'
                      ])}
                      isMulti={true}
                      onChange={(e: any) => {
                        const val = e?.map((a: any) => a?.value)
                        handleInputChange(mode?.name, index, 'taskType', val)
                      }}
                    />
                  </td> */}
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.newSeverity}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'newSeverity', v)
                      }
                      title={'SEVERITY'}
                      headers={[
                        'Risk Level',
                        'Category',
                        'Description',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={severityData}
                    />
                  </td>
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.newOccurence}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'newOccurence', v)
                      }
                      title={'OCCURANCE'}
                      headers={[
                        'Risk Level',
                        'Likelihood',
                        'Probability of Failure',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={occurenceData}
                    />
                  </td>
                  <td style={{ alignContent: 'center' }}>
                    <TableForm
                      values={entry?.newDetect}
                      onSave={(v: any) =>
                        handleInputChange(mode.name, index, 'newDetect', v)
                      }
                      title={'DETECTABLITY'}
                      headers={[
                        'Risk Level',
                        'Detectability',
                        'Likelihood of Detection',
                        'Example (Drillinf Rig Context)',
                        'Select Rating'
                      ]}
                      data={detectData}
                    />
                  </td>
                  <td
                    style={{ alignContent: 'center' }}
                    className="align-content-center"
                  >
                    {entry?.newRpn && (
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 60,
                          cursor: 'pointer',
                          background: `linear-gradient(.25turn,${sodColors(
                            entry?.newRpn
                          )},90% ,#F5F5F5)`,
                          opacity: 1,
                          fontSize: 22,
                          fontWeight: 900,
                          border: '5px solid #FFF'
                        }}
                      >
                        {entry?.newRpn}
                      </Col>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      <ModalFooter>
        <RCMButton onClick={() => setSubmit(true)}>Submit</RCMButton>
      </ModalFooter>
      <SubmitModalWithForm
        isOpen={submit}
        onClose={(val: any) => {
          setSubmit(false)
        }}
        onSubmit={async (e: any) => {
          e?.preventDefault()

          const body: any = {
            ...currentAnalysisData,
            status: 'Fmea2'
          }

          const res = await makeAuthenticatedRequest(
            `${rcmApi}/rcmAnalysis/${id}`,
            'PATCH',
            body,
            accounts,
            instance
          )

          if (res.status === 'success') {
            await refetch()
            setResult(res?.analysis)
          }

          return res.status
        }}
        sucessView={<p>RCM Number: {result?.number}</p>}
        size="xs"
        header="Failure Modes"
      />
    </div>
  )
}

export default function FmeaForm() {
  const [active, setActive]: any = useState(tabs.DETAILS)

  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const currentAnalysisData = data?.analysis

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <Col>
          <Nav
            justified
            pills
            style={{ backgroundColor: theme.colors.primaryLight }}
          >
            {Object.keys(tabs)?.map((t: any) => (
              <NavItem>
                <NavLink
                  style={{
                    backgroundColor:
                      active === tabs?.[t]
                        ? theme.colors.accent
                        : theme.colors.primaryLight,
                    color: '#000'
                  }}
                  onClick={() => setActive(tabs?.[t])}
                >
                  {tabs?.[t]}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={active}>
            <TabPane tabId={tabs.DETAILS}>
              <Spacer height={10} />
              <RcmBasicDetails data={currentAnalysisData} />
              <ModalFooter>
                <RCMButton
                  onClick={() => setActive(tabs?.STAGE2)}
                  children="Next"
                />
              </ModalFooter>
            </TabPane>
            <TabPane tabId={tabs.STAGE2}>
              <Spacer height={10} />
              <Stage2
                currentAnalysisData={currentAnalysisData}
                refetch={refetch}
                data={data}
              />
            </TabPane>
            <TabPane tabId={tabs.STAGE3}>
              <Spacer height={10} />
              <Stage3
                currentAnalysisData={currentAnalysisData}
                refetch={refetch}
                data={data}
              />
            </TabPane>
            <TabPane tabId={tabs.PRINT}>
              <Spacer height={10} />
              <Print
                currentAnalysisData={currentAnalysisData}
                refetch={refetch}
                data={data}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </RcmLayout>
  )
}
