import React, { useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'
import { RCMButton } from '../../pages/rcm/RcmComponents'

function SubmitModalWithForm({
  isOpen,
  onClose,
  onSubmit,
  form,
  isDisabled,
  size = null,
  sucessView,
  header = 'Acknowledgement',
  fullscreen = false,
  children,
  showDefaultButtons = true
}: any) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await onSubmit(e)

      const isSuccess = response === 'success'
      if (isSuccess) {
        setShowSuccess(true)
      } else {
        setShowFailure(true)
      }
    } catch (error) {
      setShowFailure(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const resetState = () => {
    setIsSubmitting(false)
    setShowFailure(false)
    setShowSuccess(false)
  }

  return (
    <Modal isOpen={isOpen} size={size && size} fullscreen={fullscreen}>
      <ModalBody>
        <Row style={{}}>
          <ModalTitle title={header} height={40} />
        </Row>
        {showSuccess ? (
          <div className="success-message">
            Form submitted successfully!
            <Spacer height={10} />
            {sucessView}
            <ModalFooter>
              <RCMButton
                type="button"
                color="warning"
                onClick={() => {
                  onClose(showSuccess)
                  resetState()
                }}
              >
                Close
              </RCMButton>
            </ModalFooter>
          </div>
        ) : showFailure ? (
          <div className="error-message">
            Form submission failed. Please try again.
            <ModalFooter>
              <Button
                type="button"
                color="warning"
                onClick={() => {
                  onClose(showSuccess)
                  resetState()
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {form ||
              (!children && (
                <>
                  <Spacer height={20} />
                  <p style={{ marginTop: 10 }}>Are you sure to Continue ?</p>
                </>
              ))}
            {children}
            <Spacer height={10} />
            {showDefaultButtons && (
              <ModalFooter>
                <RCMButton onClick={() => onClose(showSuccess)}>
                  Close
                </RCMButton>
                <RCMButton
                  type="submit"
                  disabled={isSubmitting || isDisabled}
                  color={isDisabled ? 'secondary' : 'warning'}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </RCMButton>
              </ModalFooter>
            )}
          </form>
        )}
      </ModalBody>
    </Modal>
  )
}

export default SubmitModalWithForm
