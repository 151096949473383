import { useState } from 'react'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import {
  getRiskLevel,
  getRiskMatrixColors
} from '../../components/lib/getRiskLevel'
import {
  HIGH_RISK_COLOR,
  LOW_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'
import ModalTitle from '../../components/ModalTitle'
import { theme } from './constants/brandkit'

export const riskValues: any = {
  'Extreme (<.2 Years)': [25, 23, 20, 16, 11],
  'High (< .5 Years)': [24, 21, 17, 12, 7],
  'Medium (0.5 - 20 Years)': [22, 18, 13, 8, 4],
  'Low (>20 - 30 Years)': [19, 14, 9, 5, 2],
  'Negligible (>30 -50 Years)': [15, 10, 6, 3, 1]
}

export default function RcmRiskMatrix({
  values,
  name,
  equipment,
  onChange,
  score,
  riskMatrix,
  pof,
  title
}: any) {
  const [isOpen, setIsOpen] = useState(false)
  const [consequence, setConsequence]: any = useState()
  const [currentScore, setCurrentScore]: any = useState()

  const toggle = () => setIsOpen(!isOpen)

  const risklevel = getRiskLevel(equipment)
  const riskMatrixColors = getRiskMatrixColors(risklevel)

  const tableHeaders = [
    { title: 'Risk Matrix' },
    {
      title: 'Negligible (>30 -50 Years)'
    },
    {
      title: 'Low (>20 - 30 Years)',
      content: 'Rare'
    },
    {
      title: 'Medium (0.5 - 20 Years)',
      content: 'Likely'
    },
    {
      title: 'High (< .5 Years)',
      content: 'Very Likely'
    },
    {
      title: 'Extreme (<.2 Years)',
      content: 'Certainly'
    }
  ]

  const getColor = () => {
    if (Number(score) >= 1 && Number(score) <= 6) {
      return NO_RISK_COLOR
    } else if (Number(score) >= 7 && Number(score) <= 17) {
      return MEDIUM_RISK_COLOR
    } else if (Number(score) >= 18 && Number(score) <= 25) {
      return HIGH_RISK_COLOR
    } else {
      return 'gray'
    }
  }
  // console.log({ currentScore }, { position }, riskValues[pof])

  return (
    <>
      {
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            color: '#fff',
            backgroundColor: getColor(),
            cursor: 'pointer'
          }}
          onClick={() => toggle()}
        >
          {Boolean(values) ? values : 'Set Risk'}
        </Col>
      }
      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <ModalBody>
          <ModalTitle title={title} height={40} />
          <Row>
            <Col xs="1" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <p
                style={{
                  writingMode: 'vertical-rl',
                  fontSize: 20,
                  padding: 20,
                  textOrientation: 'upright',
                  textAlign: 'center',
                  border: '1px solid #000',
                  marginLeft: 0,
                  fontWeight: 700
                }}
              >
                CONSEQUENCE
              </p>
            </Col>
            <Col xs="11">
              <Table striped style={{ backgroundColor: theme.colors.gray100 }}>
                <thead>
                  <tr style={{ backgroundColor: theme.colors.table.header }}>
                    <th className="top-left" colSpan={1}></th>
                    <th
                      className="top-right"
                      colSpan={5}
                      style={{ textAlign: 'center', fontSize: 20 }}
                    >
                      PROBABILITY
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={1}></td>
                    {['1', '2', '3', '4', '5']?.map((h: any) => (
                      <td
                        key={h}
                        style={{
                          textAlign: 'center',
                          width: '15%',
                          fontSize: 12,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {h}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {tableHeaders?.map((header: any) => (
                      <th
                        style={{
                          fontSize: 12,
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          fontWeight: header.title === 'Factor' ? 900 : 700,
                          border: header?.title === pof ? '6px solid #000' : ''
                        }}
                      >
                        {header.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {riskMatrix?.map((item: any, index: any) => (
                    <>
                      <tr>
                        {[item?.category]?.map((i: any) => (
                          <td
                            style={{
                              border: i === consequence ? '5px solid #000' : '',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setConsequence(i)
                              setCurrentScore(riskValues?.[pof]?.[index])
                            }}
                          >
                            {i}
                          </td>
                        ))}
                        {[item[1], item[2], item[3], item[4], item[5]]?.map(
                          ({ text, value, fieldValue }) => (
                            <td
                              // onClick={() => {
                              //   onChange(text, fieldValue)
                              //   toggle()
                              // }}
                              style={{
                                border:
                                  Number(text) === Number(currentScore)
                                    ? '5px solid #000'
                                    : '',
                                backgroundColor: riskMatrixColors[value],
                                color:
                                  riskMatrixColors[value] === LOW_RISK_COLOR
                                    ? '#000'
                                    : '#FFF',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                height: '10%',
                                fontWeight: 600,
                                fontSize: 24
                              }}
                            >
                              {text}
                            </td>
                          )
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={toggle}>
            Close
          </Button>
          <Button
            color="success"
            onClick={() => {
              onChange(currentScore, consequence)
              toggle()
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
