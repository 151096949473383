import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { useEffect, useState } from 'react'

import Spacer from '../../components/Spacer'
import { svgPoints } from './constants/decisionChart'
import { RCMButton } from './RcmComponents'

const tree: any = {
  q1: ['q2', 'q3'], // done
  q2: ['q4', 'q5'], //
  q3: ['q23', 'q24'],
  q4: ['q7', 'q6'],
  q5: ['q46', 'q12'],
  q6: ['q8', 'q9'],
  q7: ['result1'],
  q8: ['result2'],
  q9: ['q10', 'q11'],
  q10: ['result3'],
  q11: ['result4'],
  q12: ['q14', 'q13'],
  q13: ['q22', 'q19'],
  q14: ['q15', 'q48'],
  q15: ['q17', 'q18'],
  q16: ['q17', 'q18'],
  q17: ['result6'],
  q18: ['result7'],
  q19: ['q20', 'q21'],
  q20: ['result9'],
  q21: ['result10'],
  q22: ['result10'],
  q23: ['q25', 'q26'],
  q24: ['q32', 'q31'],
  q25: ['result12'],
  q26: ['q27', 'q28'],
  q27: ['result13'],
  q28: ['q29', 'q30'],
  q29: ['result14'],
  q30: ['result15'],
  q31: ['q32', 'q33'],
  q32: ['q34', 'q35'],
  q33: ['q40', 'q41'],
  q34: ['result16'],
  q35: ['q36', 'q37'],
  q36: ['result17'],
  q37: ['q38', 'q39'],
  q38: ['result18'],
  q39: ['result19'],
  q40: ['result20'],
  q41: ['q42', 'q43'],
  q42: ['result21'],
  q43: ['q44', 'q45'],
  q44: ['result22'],
  q45: ['result23'],
  q46: ['q47', 'q48'],
  q47: ['result1'],
  q48: ['q49', 'q50']
}

const questions: any = {
  q1: 'Will the Loss of Function caused by this Failure mode on its own become evident to the operating crew under normal circumstance?	',
  q2: 'Is there an intolerable risk that the effects of this tailure mode couldn injure or kill someone?',
  q3: 'Is there an intolerable risk that the multiple failure could kill or injure someone?',
  q4: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q5: 'Is there an intolerahle risk that the effects of this  failure mode could breach a known environmental standard or regulation?',
  q6: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
  q9: 'Is a combination of tasks technically feasible and worth doing?',
  q12: 'Does the failure mode have a direct adverse effect on operational capability?',
  q13: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q14: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q16: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
  q19: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
  q23: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q24: 'Is there an intolerable risk that the multiple failure could breach a known environmental  standard or regulation?',
  q26: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing ?',
  q28: 'Is a scheduled failure-finding task technicallt feasible and woth doing ?',
  q31: 'Does the multiple Failuer have a dorect adverse effect om operational capability ?',
  q32: 'Is a scheduled on condition task technically feasible and worth doing',
  q33: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q35: 'Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
  q37: 'Is a scheduled failure- finding task technically feasible and worth doing?',
  q41: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?',
  q43: 'Is a scheduled failure- finding task technically feasible and worth doing?',
  q46: 'Is a scheduled on-condition task technically feasible and worth doing?',
  q48: ' Is a scheduled restoration or scheduled discard task technically feasible and worth doing?'
}

const results: any = {
  q7: 'Schedule on Condition task',
  q8: 'Scheduled discard or restoration task',
  q10: 'Combination of tasks',
  q11: 'Redesign compulsory',
  q15: 'Scheduled discard or restoration task',
  q17: 'Scheduled discard or restoration task',
  q18: 'Redesign may be desirable',
  q20: 'Scheduled discard or restoration task',
  q21: 'Redesign may be desirable',
  q22: 'Schedule on Condition task',
  q25: 'Scheduled discard or restoration task',
  q27: 'Scheduled discard or restoration task',
  q29: 'Failure Finding Task',
  q30: 'Redesign Compulsary',
  q34: 'Scheduled discard or restoration task',
  q36: 'Scheduled discard or restoration task',
  q38: 'Failure Finding Task',
  q39: 'Redesign may be desirable',
  q40: 'Scheduled discard or restoration task',
  q42: 'Scheduled discard or restoration task',
  q44: 'Failure Finding Task',
  q45: 'Redesign may be desirable',
  q47: 'Schedule on Condition task',
  q49: 'Scheduled discard or restoration task',
  q50: 'Redesign may be desirable'
}

export const RcmDescissionQuestions = ({
  handleChange,
  decision,
  setSelectedTags
}: {
  handleChange: any
  decision: any
  setSelectedTags: any
}) => {
  // const [value, setValue]: any = useState()

  const [answers, setAnswers]: any = useState({})
  const [value, setValue]: any = useState()
  const [type, setType]: any = useState()
  const [currentNode, setCurrentNode]: any = useState('q1')

  const addTag = (tag: any) =>
    setSelectedTags((prev: any) => [...prev, svgPoints?.[tag]])

  const handleAnswer = (answer: any, currentNode: any) => {
    const nextNode = tree[currentNode][answer ? 0 : 1]
    setAnswers((prev: any) => ({
      ...prev,
      [currentNode]: answer ? 'Yes' : 'No'
    }))
    setCurrentNode(nextNode)

    addTag(nextNode)

    const tagMapping: Record<string, (answer: boolean) => void> = {}

    const assignTag = (questions: string[], tag: string) => {
      questions.forEach((q) => {
        tagMapping[q] = (answer: boolean) => answer && addTag(tag)
      })
    }

    tagMapping['q1'] = (answer: boolean) =>
      addTag(answer ? 'EVIDENT' : 'HIDDEN')

    tagMapping['q2'] = (answer: boolean) => answer && addTag('ES_E1')
    tagMapping['q12'] = (answer: boolean) => addTag(answer ? 'EO' : 'ENO')
    tagMapping['q12'] = (answer: boolean) => addTag(answer ? 'EO1' : 'ENO1')
    tagMapping['q3'] = (answer: boolean) => answer && addTag('HS_E')
    tagMapping['q3'] = (answer: boolean) => answer && addTag('HS_E1')
    tagMapping['q24'] = (answer: boolean) => answer && addTag('HO')
    tagMapping['q24'] = (answer: boolean) => answer && addTag('HO1')
    tagMapping['q31'] = (answer: boolean) => addTag(answer ? 'HO' : 'HNO')
    tagMapping['q31'] = (answer: boolean) => addTag(answer ? 'HO1' : 'HNO1')

    assignTag(['q4', 'q46', 'q13', 'q23', 'q32', 'q33'], 'TASK1')
    assignTag(['q6', 'q19', 'q48', 'q26', 'q35', 'q41'], 'TASK2')
    assignTag(['q28', 'q37', 'q43'], 'TASK3')

    if (tagMapping[currentNode]) {
      tagMapping[currentNode](answer)
    }

    if (currentNode === 'q2' && answer) addTag('ES_E')
    if (currentNode === 'q3' && answer) addTag('HS_E')
    if (currentNode === 'q24' && answer) addTag('HO')
    if (currentNode === 'q31' && answer) addTag('HO')
    if (currentNode === 'q31' && !answer) addTag('HNO')
    if (currentNode === 'q12' && !answer) addTag('ENO')
    if (currentNode === 'q12' && answer) addTag('EO')
    if (currentNode === 'q12' && answer) addTag('EO1')
    if (currentNode === 'q5' && answer) addTag('EO')
    if (currentNode === 'q5' && answer) addTag('EO1')
    if (currentNode === 'q1') setType(answer ? 'EVIDENT' : 'HIDDEN')
  }
  const resultKeys = Object.keys(results)
  useEffect(() => {
    if (currentNode && resultKeys?.includes(currentNode)) {
      setValue(results[currentNode])
    }
  }, [currentNode])
  return (
    <>
      <ModalBody className="d-flex flex-column align-content-between align-items-center">
        <div>
          {Object.keys(answers).map((questionKey) => (
            <div key={questionKey}>
              <p
                style={{
                  border: '1px solid #000',
                  padding: 10,
                  fontWeight: 700
                }}
              >
                {questions[questionKey]}:{' '}
                <strong>{answers[questionKey]}</strong>
              </p>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {currentNode in questions && (
              <div
                className="d-flex flex-column align-items-center"
                style={{
                  width: '60%',
                  border: '1px solid #000',
                  textAlign: 'center',
                  padding: 10
                }}
              >
                <p>
                  <strong>{questions[currentNode]}</strong>
                </p>
                <ModalFooter>
                  <RCMButton onClick={() => handleAnswer(true, currentNode)}>
                    Yes
                  </RCMButton>
                  <RCMButton onClick={() => handleAnswer(false, currentNode)}>
                    No
                  </RCMButton>
                </ModalFooter>
              </div>
            )}
          </div>
          {currentNode in results && (
            <div>
              <h3>Decision: {results[currentNode]}</h3>
            </div>
          )}
        </div>
        <Spacer height={20} />
        <ModalFooter>
          <RCMButton
            color="warning"
            onClick={() => {
              setAnswers({})
              setCurrentNode('q1')
              setSelectedTags([
                {
                  id: 'Q1',
                  tagName: 'image',
                  x: '1774',
                  y: '724',
                  height: '438',
                  width: '1252'
                }
              ])
            }}
          >
            Reset
          </RCMButton>
          <RCMButton
            color="warning"
            onClick={() => {
              handleChange(value, type)
            }}
          >
            save
          </RCMButton>
        </ModalFooter>
        <Spacer height={20} />
      </ModalBody>
    </>
  )
}
