import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import {
  Button,
  Col,
  Input,
  ModalFooter,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import RcmLayout from '../../components/rcm/RcmLayout'
import RcmStepper from './RcmStepper'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import {
  compressorFMs,
  lubricationFMs,
  motorFMs,
  vibrationFMs
} from './rcmConstants'
import { getOptions } from '../firs'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { RCMButton } from './RcmComponents'

const failureModes = [
  'Abnormal instrument reading',
  'Breakdown',
  'Control-line-to-well communication',
  'Control / signal failure',
  'Sludge build-up',
  'Minor in-service problems',
  'Failure to set/retrieve',
  'Spurious high alarm level',
  'Spurious low alarm level',
  'Slippage',
  'Spurious operation',
  'Structural deficiency',
  'Failure to stop on demand',
  'Stuck',
  'Global buckling',
  'Unknown',
  'Spurious stop',
  'Vibration',
  'Very low output',
  'Well-to-control-line communication',
  'Delayed operation',
  'External leakage - fuel',
  'External leakage - process medium',
  'External leakage - utility medium',
  'Erratic output',
  'Failure to connect',
  'Failure to cut',
  'Failure in lightning protection system',
  'Faulty output frequency',
  'Faulty output voltage',
  'Failure to rotate',
  'Failure to close on demand',
  'Failure to disconnect',
  'Failure to function on demand',
  'Failure to function as intended',
  'Failure to lock/unlock',
  'Failure to open on demand',
  'Failure to regulate',
  'Failure to start on demand',
  'Failure while running',
  'High output',
  'Heating failure',
  'Insufficient heat transfer',
  'Internal leakage - process medium',
  'Internal leakage - utility medium',
  'Internal leakage',
  'Low oil supply pressure',
  'Leakage in closed position',
  'Load drop',
  'Loss of buoyancy',
  'Low output',
  'Mooring failure',
  'Noise',
  'No output',
  'Overheating',
  'Other',
  'Premature closure',
  'Parameter deviation',
  'Plugged/ choked',
  'Loss of functions on both pods',
  'Insufficient power',
  'Power/signal transmission failure'
]

const failureEffects = [
  'False alarm, faulty instrument indication',
  'Serious damage (seizure, breakage)',
  'Loss of hydraulic control fluids into the well bore',
  'No, or faulty monitoring or regulation, failure to transmit or receive commad or data',
  'Power/signal transmission failure',
  'Loose items, discoloration, dirt',
  'False alarm, premature closure/stop, unexpected operation/fails to operate as demanded',
  'Material damages (cracks, wear, fracture, corrosion, decay)',
  'Too little information to define a failure mode',
  'Unexpected shutdown',
  'Abnormal/excessive vibration',
  'Serious damage (seizure, breakage)',
  'Oscillating, hunting, instability',
  'External leakage - util- Lubricant, cooling water',
  "Failure to start on de- Doesn't start on demand",
  'Overspeed/output above acceptance',
  'Cooling/heating below acceptance and/or heat transfer too low',
  'Leakage internally process or utility fluids',
  'Low oil supply pressure',
  'Delivery/output/torque/performance below acceptance',
  'Abnormal or excessive noise',
  'Machine parts, exhaust, cooling water, etc.',
  'Failure mode(s) not covered above',
  'Monitored parameter exceeding limits, e.g. High/Low alarm',
  'Flow restriction due to contamination, objects, wax etc.',
  'Lack of or too low power supply',
  'Power/signal transmission failure'
]

const fm: any = {
  Compressor: compressorFMs,
  Motor: motorFMs,
  'Instrument, vibration': vibrationFMs,
  'Lubrication System': lubricationFMs
}

export const LoadingState = () => {
  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh'
        }}
      >
        <Spinner />
      </div>
    </RcmLayout>
  )
}

export default function RcmViewStage4() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('fetchRcm', fetchRcm)
  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const [functions, setFunctions]: any = useState(
    currentAnalysisData?.functions || {}
  )

  const [funcFailure, setFuncFailure]: any = useState()

  const [categorizedEquipment, setcategorizedEquipment]: any = useState(
    currentAnalysisData?.categorizedEquipment || {}
  )

  useEffect(() => {
    if (Object.keys(categorizedEquipment || {})?.length === 0) {
      setcategorizedEquipment(data?.analysis?.categorizedEquipment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalysisData?.categorizedEquipment])

  const [entries, setEntries] = useState(
    categorizedEquipment &&
      Object.keys(categorizedEquipment).reduce((acc: any, key) => {
        acc[key] = [
          { functionalFailure: '', failureMode: '', failureEffect: '' }
        ]
        return acc
      }, {})
  )

  useEffect(() => {
    setFunctions(currentAnalysisData?.functions)
  }, [currentAnalysisData?.functions])

  if (isLoading) {
    return <LoadingState />
  }

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const newEntries = [...entries[categoryKey]]
    newEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: newEntries }))
  }

  const isFMEA = currentAnalysisData?.type === 'FMEA'

  const handleAddEntry = (categoryKey: any) => {
    const newEntry = {
      criticality: '',
      equipmentNumber: '',
      systemName: '',
      systemNumber: '',
      failures: [
        {
          functionalFailure: isFMEA ? funcFailure?.[categoryKey] : '',
          failureMode: '',
          failureEffect: ''
        }
      ]
    }

    setEntries((prev: any) => ({
      ...prev,
      [categoryKey]: prev[categoryKey]
        ? [...prev[categoryKey], newEntry]
        : [newEntry]
    }))
  }

  const handleRemoveEntry = (categoryKey: any, index: any) => {
    setEntries((prev: any) => {
      const updatedEntries = { ...prev }

      if (updatedEntries[categoryKey]) {
        updatedEntries[categoryKey] = updatedEntries[categoryKey].filter(
          (_: any, i: any) => i !== index
        )
      }

      return updatedEntries
    })
  }

  const onFunctionChange = (key: any, value: any) => {
    setFunctions({ ...functions, [key]: value })
  }

  const onFuncFailureChange = (key: any, value: any) => {
    setFuncFailure({ ...funcFailure, [key]: value })
  }

  const customStyles = {
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: '40vh'
    })
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, paddingBottom: '30vh' }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {Object.keys(categorizedEquipment || {})?.map(
              (k: any, cIndex: any) => (
                <React.Fragment key={`${cIndex}-${k}`}>
                  <h4>{k}</h4>
                  <p className="m-0">
                    <strong>Equipment / Maintainable Items :</strong>
                    {categorizedEquipment?.[k]
                      ?.map((c: any) => c?.equipmentNumber)
                      .join(', ')}
                  </p>
                  <Col className="d-flex flex-grow mb-4 mt-2 gap-2">
                    <div style={{ width: '8vw' }}>
                      <CustomLabel label="Function" />
                    </div>
                    <Input
                      type="text"
                      value={functions?.[k]}
                      onChange={(e: any) =>
                        onFunctionChange(k, e?.target?.value)
                      }
                    />
                  </Col>
                  {/* {isFMEA && (
                    <Col className="d-flex flex-grow mb-4 mt-2 gap-2">
                      <div style={{ width: '8vw' }}>
                        <CustomLabel label="Function Failure" />
                      </div>
                      <Input
                        type="text"
                        value={funcFailure?.[k]}
                        onChange={(e: any) =>
                          onFuncFailureChange(k, e?.target?.value)
                        }
                      />
                    </Col>
                  )} */}
                  <Table striped>
                    <thead>
                      <tr style={{ backgroundColor: 'lightgray' }}>
                        <th className="top-left" style={{ width: '3vw' }}>
                          S.No
                        </th>
                        <th style={{ width: '25vw' }}>Functional Failure</th>
                        <th style={{ width: '15vw' }}>Failure modes</th>
                        <th style={{ width: '15vw' }}>Failure Effect</th>
                        {isFMEA && (
                          <th style={{ width: '15vw' }}>
                            Failure Charecteristics
                          </th>
                        )}
                        <td className="top-right" style={{ width: '3vw' }}>
                          Remove
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {entries[k]?.map((entry: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Input
                              type="textarea"
                              // disabled={isFMEA}
                              style={{ height: 40 }}
                              value={entry.functionalFailure}
                              onChange={(e) =>
                                handleInputChange(
                                  k,
                                  index,
                                  'functionalFailure',
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <CreatableSelect
                              styles={customStyles}
                              options={getOptions(
                                fm[data?.mode] || failureModes
                              )}
                              isMulti={false}
                              onChange={(e: any) =>
                                handleInputChange(
                                  k,
                                  index,
                                  'failureMode',
                                  e?.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <CreatableSelect
                              styles={customStyles}
                              options={getOptions(failureEffects)}
                              isMulti={false}
                              onChange={(e: any) =>
                                handleInputChange(
                                  k,
                                  index,
                                  'failureEffect',
                                  e?.value
                                )
                              }
                            />
                          </td>
                          {isFMEA && (
                            <td style={{ width: '15vw' }}>
                              <Input
                                type="textarea"
                                style={{ height: 40 }}
                                value={entry?.failureChar}
                                onChange={(e) =>
                                  handleInputChange(
                                    k,
                                    index,
                                    'failureChar',
                                    e?.target?.value
                                  )
                                }
                              />
                            </td>
                          )}
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => handleRemoveEntry(k, index)}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <RCMButton onClick={() => handleAddEntry(k)}>
                    Add Another Entry
                  </RCMButton>
                  <Spacer height={20} />
                </React.Fragment>
              )
            )}
          </div>
          {currentAnalysisData?.status === 'Stage4' && (
            <ModalFooter>
              <RCMButton onClick={() => setSubmit(true)}>Submit</RCMButton>
            </ModalFooter>
          )}
          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()
              const categorizedFailues = Object.keys(categorizedEquipment)?.map(
                (k: any) => ({
                  name: k,
                  failures: entries[k],
                  tags: categorizedEquipment[k]
                })
              )

              const body: any = {
                ...currentAnalysisData,
                failuresModes: categorizedFailues,
                status: isFMEA ? 'Fmea' : 'Stage5',
                functions
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Functional Failures"
          />
        </Col>
      </Row>
    </RcmLayout>
  )
}
