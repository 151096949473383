/* eslint-disable no-loop-func */
import { Button, Col, Input, ModalFooter, Row, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Box } from '../workManagement/PmTree'
import {
  economicConsequences,
  economicRiskValues,
  environmentSconsequences,
  healthScoreSconsequences,
  taskInterval
} from './rcmConstants'
import { getRcmRiskColor } from './rcmLib'
import { LoadingState } from './RcmViewStage4'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { SubHeader } from '../VerifyCM'
import { calculatePof, getEcoRange, mtbfOptions } from './RcmViewStage5'
import { riskValues } from './RcmRiskMatrix'
import RcmStepper from './RcmStepper'

export default function RcmViewStage8() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || {}
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment

  const [entries, setEntries] = useState(riskDetails)
  const [updateStage, setUpdateStage]: any = useState(false)

  useEffect(() => {
    if (Object.keys(entries)?.length <= 0) {
      setEntries(riskDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.analysis?.failuresModes, riskDetails])

  if (isLoading) {
    return <LoadingState />
  }

  const calculateTotalCost = (tasks: any[], key: any) => {
    return tasks?.reduce((sum, task) => {
      const yearlyMaintenance = Number(task.yearlyMaintenance) || 0
      const intervalValue = taskInterval[task?.[key]] || 1
      return sum + yearlyMaintenance * intervalValue
    }, 0)
  }

  const getRatio = (failureMode: any, key: any) => {
    const economicEffect = Number(failureMode?.economicEffect) || 0
    const totalMaintenanceCost = calculateTotalCost(failureMode.tasks, key)

    return (economicEffect / totalMaintenanceCost).toFixed(5)
  }

  const handleFailureModeInputChange = (
    categoryKey: any,
    failureModeIndex: number,
    field: string,
    value: any
  ) => {
    setEntries((prev: any) => {
      const updatedEntries = { ...prev }

      const currentCategory = updatedEntries[categoryKey]
      if (currentCategory && currentCategory[failureModeIndex]) {
        const updatedFailureMode = { ...currentCategory[failureModeIndex] }

        updatedFailureMode[field] = value
        currentCategory[failureModeIndex] = updatedFailureMode
        updatedEntries[categoryKey] = currentCategory

        return updatedEntries
      }
      return prev
    })
  }

  const getNewRatio = (failureMode: any) => {
    const newRatio: any = getRatio(failureMode, 'recommendedInterval')
    const oldRatio: any = getRatio(failureMode, 'givenTaskInterval')
    return newRatio >= 1 && newRatio
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, maxWidth: '87vw' }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={30} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <h4 style={{ fontWeight: 700 }} children="RECOMMENDATION" />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {Object.keys(riskDetails).map(
              (mode: any, modeIndex: number) =>
                riskDetails?.[mode]?.some(
                  (entry: any) => Number(entry.riskScore) > riskToleranceLevel
                ) && (
                  <div key={modeIndex}>
                    {entries?.[mode]?.map(
                      (failureMode: any, failureIndex: any) => (
                        <React.Fragment key={failureMode?._id}>
                          <Table
                            bordered
                            style={{ borderColor: '#000', border: '1px solid' }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: '15vw' }}>
                                  <h4>{mode}</h4>
                                  <p className="m-0">
                                    <strong>Associated Equipment:</strong>{' '}
                                    {categorizedEquipment[mode]
                                      ?.map((a: any) => a?.equipmentNumber)
                                      ?.join(', ')}
                                  </p>
                                  <p>
                                    <strong>Functions:</strong>{' '}
                                    {currentAnalysisData?.functions?.[mode]}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    width: '15vw',
                                    alignContent: 'center'
                                  }}
                                >
                                  <h4 className="m-0">Failure Mode</h4>
                                  <p className="m-0">
                                    {failureMode?.failureMode}
                                  </p>
                                </td>
                                {[
                                  {
                                    value: failureMode?.healthSafetyScore,
                                    label: 'Health Safety Score'
                                  },
                                  {
                                    value: failureMode?.economicScore,
                                    label: 'Economic Score'
                                  },
                                  {
                                    value: failureMode?.environmentScore,
                                    label: 'Environment Score'
                                  }
                                ]?.map((s: any, index: any) => (
                                  <td
                                    key={`${s}-${s?.label}-${index}`}
                                    style={{ width: '8vw' }}
                                    className="text-center align-content-center"
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        height: '5vh',
                                        backgroundColor: getRcmRiskColor(
                                          s?.value
                                        )
                                      }}
                                    >
                                      {s?.label}: {s?.value}
                                    </p>
                                  </td>
                                ))}
                                <td
                                  className="align-content-center"
                                  style={{ width: '8vw' }}
                                >
                                  <p className="m-0 text-left">
                                    Ratio:{' '}
                                    {getRatio(failureMode, 'givenTaskInterval')}
                                  </p>
                                  <hr />
                                  <p className="m-0 text-left">
                                    New Ratio:
                                    {getNewRatio(failureMode)}
                                    {/* {getRatio(
                                      failureMode,
                                      'recommendedInterval'
                                    )} */}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped style={{ borderColor: '#000' }}>
                            <thead>
                              <tr
                                style={{ backgroundColor: 'lightgray' }}
                                className="text-center"
                              >
                                <th className="top-left">S.No</th>
                                <th>Task Name</th>
                                <th>Task Type</th>
                                <th>Compliance</th>
                                <th>Associated Tags</th>
                                <th>Current Task Interval</th>
                                {/* <th>Details</th> */}

                                <th className="top-right">Clamp Recomended</th>
                              </tr>
                            </thead>
                            <tbody>
                              {failureMode?.tasks?.length > 0 &&
                                failureMode?.tasks?.map(
                                  (task: any, taskIndex: number) => (
                                    <React.Fragment key={taskIndex}>
                                      <tr
                                        className="text-center"
                                        key={taskIndex}
                                      >
                                        <td>{taskIndex + 1}</td>
                                        <td style={{ textAlign: 'left' }}>
                                          {task.taskname}
                                        </td>
                                        <td>{task.taskType}</td>
                                        <td>{task.compliance}</td>
                                        <td>
                                          <ul style={{ listStyleType: 'none' }}>
                                            {task?.tags?.map((t: any) => (
                                              <li>
                                                {t?.itemNumber ||
                                                  t?.tagNo ||
                                                  t?.equipmentNumber}
                                              </li>
                                            ))}
                                          </ul>
                                        </td>
                                        <td>
                                          {task?.givenTaskInterval ||
                                            task?.taskInterval}
                                        </td>
                                        {/* <td>
                                          Yearly Maintenance:{' '}
                                          {task?.yearlyMaintenance} <br />
                                          External Cost: {task?.externalCost}
                                          <br />
                                          Spare Cost: {task?.spareCost}
                                          <br />
                                          Discipline 1 H:{' '}
                                          {task?.discipline1Hours}
                                          <br />
                                          Discipline 2 H:{' '}
                                          {task?.discipline2Hours}
                                          <br />
                                          Discipline 3 H:{' '}
                                          {task?.discipline3Hours}
                                          <br />
                                          Interval: {task?.taskInterval}
                                          {'-'}
                                          {taskInterval[task?.taskInterval]}
                                          <br />
                                          Day Rate: 23
                                        </td> */}
                                        <td>{task?.recommendedInterval}</td>
                                      </tr>
                                      {/* <tr>
                                      <td colSpan={7}>
                                        <h5>Associated Tags</h5>
                                        <Table bordered style={{ borderColor: '#000' }}>
                                          <thead>
                                            <tr>
                                              <th>S.No</th>
                                              <th>Tag</th>
                                              <th>Tag Desctiption</th>
                                              <th>Maintainable Items</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {task?.tags?.map((tg: any, Tindex: any) =>
                                              <tr key={tg?.tag}>
                                                <th>{Tindex + 1}</th>
                                                <td>
                                                  {tg?.tag}
                                                </td>
                                                <td>
                                                  {tg?.tagDescription}
                                                </td>
                                                <td>
                                                  {tg?.maintainableItemNo}
                                                </td>
                                              </tr>
                                            )}

                                          </tbody>
                                        </Table>
                                      </td>
                                    </tr> */}
                                    </React.Fragment>
                                  )
                                )}
                            </tbody>
                          </Table>
                          <Table
                            bordered
                            style={{ borderColor: '#000', border: '1px solid' }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: '30vw',
                                    alignContent: 'center'
                                  }}
                                >
                                  <h5 className="m-0 mb-10">
                                    New Failure Rate
                                  </h5>
                                  <Input
                                    type="select"
                                    onChange={(e) => {
                                      const newPof = calculatePof(
                                        mtbfOptions[e?.target?.value]
                                      )
                                      const healthConsequence =
                                        healthScoreSconsequences?.[
                                          failureMode?.healthSafety
                                        ]
                                      const envCons =
                                        environmentSconsequences?.[
                                          failureMode?.environment
                                        ]
                                      const ecoCons =
                                        economicConsequences?.[
                                          failureMode?.econonmic
                                        ]
                                      const ecoRiskValues =
                                        economicRiskValues?.[
                                          getEcoRange(e?.target?.value)
                                        ]

                                      handleFailureModeInputChange(
                                        mode,
                                        failureIndex,
                                        'newMtbf',
                                        e?.target?.value
                                      )
                                      handleFailureModeInputChange(
                                        mode,
                                        failureIndex,
                                        'newPof',
                                        newPof
                                      )

                                      handleFailureModeInputChange(
                                        mode,
                                        failureIndex,
                                        'newHealthScore',
                                        riskValues?.[newPof]?.[
                                          healthConsequence
                                        ]
                                      )

                                      handleFailureModeInputChange(
                                        mode,
                                        failureIndex,
                                        'newEcoScore',
                                        ecoRiskValues?.[ecoCons]
                                      )

                                      handleFailureModeInputChange(
                                        mode,
                                        failureIndex,
                                        'newEnvironmentScore',
                                        riskValues?.[newPof]?.[envCons]
                                      )
                                    }}
                                  >
                                    <option>Select</option>
                                    {Object.keys(mtbfOptions)?.map((h: any) => (
                                      <option key={h}>{h}</option>
                                    ))}
                                  </Input>
                                </td>
                                {[
                                  {
                                    value: failureMode?.newHealthScore,
                                    label: 'New Health Safety Score'
                                  },
                                  {
                                    value: failureMode?.newEcoScore,
                                    label: 'New Economic Score'
                                  },
                                  {
                                    value: failureMode?.newEnvironmentScore,
                                    label: 'Environment Score'
                                  }
                                ]?.map((s: any, index: any) => (
                                  <td
                                    key={`${s}-${s?.label}-${index}`}
                                    style={{ width: '8vw' }}
                                    className="text-center align-content-center"
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        height: '5vh',
                                        backgroundColor: getRcmRiskColor(
                                          s?.value
                                        )
                                      }}
                                    >
                                      {s?.label}: {s?.value}
                                    </p>
                                  </td>
                                ))}
                                <td style={{ width: '8vw' }} />
                              </tr>
                            </tbody>
                          </Table>
                        </React.Fragment>
                      )
                    )}
                  </div>
                )
            )}
          </div>

          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage7'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Failure Modes"
          />

          <SubmitModalWithForm
            isOpen={updateStage}
            onClose={(val: any) => {
              setUpdateStage(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage9'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Move to Stage 9"
          />
        </Col>
      </Row>
      <ModalFooter>
        <Button
          color="warning"
          onClick={async () => {
            // await performAnalysis()
            setUpdateStage(true)
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </RcmLayout>
  )
}
