import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import RcmLayout from '../../components/rcm/RcmLayout'
import RcmRiskMatrix from './RcmRiskMatrix'
import RcmStepper from './RcmStepper'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { SubHeader } from '../VerifyCM'
import { Text } from '../../components/RiskAssesmentPreview'
import { RcmBasicDetails } from './RcmViewStage1'
import {
  HIGH_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'

export const healthRiskMatrix = [
  {
    category: 'Multiple Fatality',
    1: { text: '15', value: 'S5/P1', fieldValue: 'Multiple Fatality' },
    2: { text: '19', value: 'S5/P2', fieldValue: 'Multiple Fatality' },
    3: { text: '22', value: 'S5/P3', fieldValue: 'Multiple Fatality' },
    4: { text: '24', value: 'S5/P4', fieldValue: 'Multiple Fatality' },
    5: { text: '25', value: 'S5/P5', fieldValue: 'Multiple Fatality' }
  },
  {
    category: 'Single Fatality',
    1: { text: '10', value: 'S4/P1', fieldValue: 'Single Fatality' },
    2: { text: '14', value: 'S4/P2', fieldValue: 'Single Fatality' },
    3: { text: '18', value: 'S4/P3', fieldValue: 'Single Fatality' },
    4: { text: '21', value: 'S4/P4', fieldValue: 'Single Fatality' },
    5: { text: '23', value: 'S4/P5', fieldValue: 'Single Fatality' }
  },
  {
    category: 'Major Injury',
    1: { text: '6', value: 'S3/P1', fieldValue: 'Major Injury' },
    2: { text: '9', value: 'S3/P2', fieldValue: 'Major Injury' },
    3: { text: '13', value: 'S3/P3', fieldValue: 'Major Injury' },
    4: { text: '17', value: 'S3/P4', fieldValue: 'Major Injury' },
    5: { text: '20', value: 'S3/P5', fieldValue: 'Major Injury' }
  },
  {
    category: 'Minor Injury',
    1: { text: '3', value: 'S2/P1', fieldValue: 'Minor Injury' },
    2: { text: '5', value: 'S2/P2', fieldValue: 'Minor Injury' },
    3: { text: '8', value: 'S2/P3', fieldValue: 'Minor Injury' },
    4: { text: '12', value: 'S2/P4', fieldValue: 'Minor Injury' },
    5: { text: '16', value: 'S2/P5', fieldValue: 'Minor Injury' }
  },
  {
    category: 'Slight Injury',
    1: { text: '1', value: 'S1/P1', fieldValue: 'Slight Injury' },
    2: { text: '2', value: 'S1/P2', fieldValue: 'Slight Injury' },
    3: { text: '4', value: 'S1/P3', fieldValue: 'Slight Injury' },
    4: { text: '7', value: 'S1/P4', fieldValue: 'Slight Injury' },
    5: { text: '11', value: 'S1/P5', fieldValue: 'Slight Injury' }
  }
]

export const environmentRiskMatrix = [
  {
    category: 'Massive Effect',
    1: { text: '15', value: 'S5/P1', fieldValue: 'Massive Effect' },
    2: { text: '19', value: 'S5/P2', fieldValue: 'Massive Effect' },
    3: { text: '22', value: 'S5/P3', fieldValue: 'Massive Effect' },
    4: { text: '24', value: 'S5/P4', fieldValue: 'Massive Effect' },
    5: { text: '25', value: 'S5/P5', fieldValue: 'Massive Effect' }
  },
  {
    category: 'Major Effect',
    1: { text: '10', value: 'S4/P1', fieldValue: 'Major Effect' },
    2: { text: '14', value: 'S4/P2', fieldValue: 'Major Effect' },
    3: { text: '18', value: 'S4/P3', fieldValue: 'Major Effect' },
    4: { text: '21', value: 'S4/P4', fieldValue: 'Major Effect' },
    5: { text: '23', value: 'S4/P5', fieldValue: 'Major Effect' }
  },
  {
    category: 'Localised Effect',
    1: { text: '6', value: 'S3/P1', fieldValue: 'Localised Effect' },
    2: { text: '9', value: 'S3/P2', fieldValue: 'Localised Effect' },
    3: { text: '13', value: 'S3/P3', fieldValue: 'Localised Effect' },
    4: { text: '17', value: 'S3/P4', fieldValue: 'Localised Effect' },
    5: { text: '20', value: 'S3/P5', fieldValue: 'Localised Effect' }
  },
  {
    category: 'Minor Effect',
    1: { text: '3', value: 'S2/P1', fieldValue: 'Minor Effect' },
    2: { text: '5', value: 'S2/P2', fieldValue: 'Minor Effect' },
    3: { text: '8', value: 'S2/P3', fieldValue: 'Minor Effect' },
    4: { text: '12', value: 'S2/P4', fieldValue: 'Minor Effect' },
    5: { text: '16', value: 'S2/P5', fieldValue: 'Minor Effect' }
  },
  {
    category: 'Slight Effect',
    1: { text: '1', value: 'S1/P1', fieldValue: 'Slight Effect' },
    2: { text: '2', value: 'S1/P2', fieldValue: 'Slight Effect' },
    3: { text: '4', value: 'S1/P3', fieldValue: 'Slight Effect' },
    4: { text: '7', value: 'S1/P4', fieldValue: 'Slight Effect' },
    5: { text: '11', value: 'S1/P5', fieldValue: 'Slight Effect' }
  }
]

export const economicRiskMatrix = [
  {
    category: 'Massive Effect',
    1: { text: '15', value: 'S5/P1', fieldValue: 'Extreme Effect (>100M)USD' },
    2: { text: '19', value: 'S5/P2', fieldValue: 'Extreme Effect (>100M)USD' },
    3: { text: '22', value: 'S5/P3', fieldValue: 'Extreme Effect (>100M)USD' },
    4: { text: '24', value: 'S5/P4', fieldValue: 'Extreme Effect (>100M)USD' },
    5: { text: '25', value: 'S5/P5', fieldValue: 'Extreme Effect (>100M)USD' }
  },
  {
    category: 'Major Effect',
    1: { text: '10', value: 'S4/P1', fieldValue: 'High Effect (10-100M)USD' },
    2: { text: '14', value: 'S4/P2', fieldValue: 'High Effect (10-100M)USD' },
    3: { text: '18', value: 'S4/P3', fieldValue: 'High Effect (10-100M)USD' },
    4: { text: '21', value: 'S4/P4', fieldValue: 'High Effect (10-100M)USD' },
    5: { text: '23', value: 'S4/P5', fieldValue: 'High Effect (10-100M)USD' }
  },
  {
    category: 'Localised Effect',
    1: { text: '6', value: 'S3/P1', fieldValue: 'Medium Effect (1-10M) USD' },
    2: { text: '9', value: 'S3/P2', fieldValue: 'Medium Effect (1-10M) USD' },
    3: { text: '13', value: 'S3/P3', fieldValue: 'Medium Effect (1-10M) USD' },
    4: { text: '17', value: 'S3/P4', fieldValue: 'Medium Effect (1-10M) USD' },
    5: { text: '20', value: 'S3/P5', fieldValue: 'Medium Effect (1-10M) USD' }
  },
  {
    category: 'Minor Effect',
    1: { text: '3', value: 'S2/P1', fieldValue: 'Low Effect 0.1-1 M USD' },
    2: { text: '5', value: 'S2/P2', fieldValue: 'Low Effect 0.1-1 M USD' },
    3: { text: '8', value: 'S2/P3', fieldValue: 'Low Effect 0.1-1 M USD' },
    4: { text: '12', value: 'S2/P4', fieldValue: 'Low Effect 0.1-1 M USD' },
    5: { text: '16', value: 'S2/P5', fieldValue: 'Low Effect 0.1-1 M USD' }
  },
  {
    category: 'Slight Effect',
    1: {
      text: '1',
      value: 'S1/P1',
      fieldValue: 'Negligible Effect < 100 K USD'
    },
    2: {
      text: '2',
      value: 'S1/P2',
      fieldValue: 'Negligible Effect < 100 K USD'
    },
    3: {
      text: '4',
      value: 'S1/P3',
      fieldValue: 'Negligible Effect < 100 K USD'
    },
    4: {
      text: '7',
      value: 'S1/P4',
      fieldValue: 'Negligible Effect < 100 K USD'
    },
    5: {
      text: '11',
      value: 'S1/P5',
      fieldValue: 'Negligible Effect < 100 K USD'
    }
  }
]

export const mtbfOptions: any = {
  '0.2 Years': 0.2,
  '0.3 Years': 0.3,
  '0.6 Years': 0.6,
  '1 Year': 1,
  '1.5 Years': 1.5,
  '2 Years': 2,
  '2.5 Years': 2.5,
  '3 Years': 3,
  '3.5 Years': 3.5,
  '4 Years': 4,
  '20 Years': 20,
  '30 Years': 30
}

export const calculatePof = (key: any) => {
  if (key <= 0.2) {
    return 'Extreme (<.2 Years)'
  } else if (key > 0.2 && key <= 0.5) {
    return 'High (< .5 Years)'
  } else if (key > 0.5 && key < 20) {
    return 'Medium (0.5 - 20 Years)'
  } else if (key >= 20 && key < 30) {
    return 'Low (>20 - 30 Years)'
  } else if (key >= 30) {
    return 'Negligible (>30 -50 Years)'
  } else {
    return '' // Default case
  }
}

export const extremeRange = ['0.2 Years']
export const highRange = ['0.3 Years']
export const mediumRange = [
  '0.6 Years',
  '1 Year',
  '1.5 Years',
  '2 Years',
  '2.5 Years',
  '3 Years',
  '3.5 Years',
  '4 Years'
]
export const lowRange = ['20 Years']
export const negligibleRange = ['30 Years']

export const getEcoRange = (mtbf: any) => {
  if (extremeRange?.includes(mtbf)) {
    return 'Extreme'
  } else if (highRange?.includes(mtbf)) {
    return 'High'
  } else if (mediumRange?.includes(mtbf)) {
    return 'Medium'
  } else if (lowRange?.includes(mtbf)) {
    return 'Low'
  } else if (negligibleRange?.includes(mtbf)) {
    return 'Negligible'
  } else {
    return 'Extreme'
  }
}

export const CalculateProductionLoss = ({
  handleChange,
  isOpen,
  setIsOpen,
  pof,
  mtbf
}: {
  handleChange: any
  isOpen: any
  setIsOpen: any
  pof: any
  mtbf: any
}) => {
  const [values, setValue]: any = useState({
    productionLoss: '',
    howLong: 0,
    hourlyLoss: 0,
    failureLossHours: 0,
    spareCost: 0,
    labourCost: 0,
    externalCost: 0
  })

  const calulate = () => {
    const {
      howLong,
      hourlyLoss,
      failureLossHours,
      spareCost,
      labourCost,
      externalCost
    } = values

    const hours = Number(failureLossHours) - Number(howLong)
    const additionalCosts =
      Number(spareCost) + Number(labourCost) + Number(externalCost)
    const total = hours * Number(hourlyLoss) + additionalCosts

    return total
  }

  const calculateCategory = () => {
    const loss = calulate()

    if (loss < 100000) {
      return 'Negligble Effect'
    } else if (loss >= 100000 && loss < 10000000) {
      return 'Low Effect'
    } else if (loss > 10000000 && loss < 100000000) {
      return 'Medium Effect'
    } else if (loss > 100000000 && loss < 1000000000) {
      return 'High Effect'
    } else if (loss > 100000000) {
      return 'Extreme Effect'
    }
  }

  const score = () => {
    const consequence: any = calculateCategory()

    const riskValues: any = {
      Extreme: [11, 16, 20, 23, 25],
      High: [7, 12, 17, 21, 24],
      Medium: [4, 8, 13, 18, 22],
      Low: [2, 5, 9, 14, 19],
      Negligible: [1, 3, 6, 10, 15]
    }

    const range: any = getEcoRange(mtbf)

    const conseqencePositions: any = {
      'Extreme Effect': 4,
      'High Effect': 3,
      'Medium Effect': 2,
      'Low Effect': 1,
      'Negligble Effect': 0
    }

    const possibleScores = riskValues[range]
    const consequencePosition: any = conseqencePositions[consequence]
    console.log(
      { range },
      { possibleScores },
      { consequence },
      { consequencePosition }
    )

    const score = possibleScores[consequencePosition]

    return score
  }
  const calculate = calulate()

  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalBody>
          <SubHeader header="Calculate Production Loss" permitColor="gold" />
          <CustomLabel label="Is there any Production Loss ?" />
          <div className="d-flex align-items-center">
            <Input
              type="radio"
              value="Yes"
              name="a"
              onChange={(e: any) => {
                setValue({ ...values, productionLoss: e?.target?.value })
              }}
            />
            <Spacer width={5} />
            <Text> Yes</Text>
            <Spacer width={10} />
            <Input
              type="radio"
              value="No"
              name="a"
              onChange={(e: any) => {
                setValue({ ...values, productionLoss: e?.target?.value })
              }}
            />
            <Spacer width={5} />
            <Text>No</Text>
          </div>
          {values?.productionLoss === 'Yes' && (
            <>
              <CustomLabel label="How long it can tolerate without production Loss in Hours (Consider Redundancy)" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, howLong: e?.target?.value })
                }}
              />
              <CustomLabel label="Hourly production Loss in USD" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, hourlyLoss: e?.target?.value })
                }}
              />
              <CustomLabel label="Due to this Failure mode how long the machine can be in out of production in Hours" />
              <Input
                type="text"
                onChange={(e: any) => {
                  setValue({ ...values, failureLossHours: e?.target?.value })
                }}
              />
              <CustomLabel label="Due this failre mode what is the repair cost" />
              <div className="d-flex">
                <div>
                  <CustomLabel label="Cost of Spare (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, spareCost: e?.target?.value })
                    }}
                  />
                </div>
                <Spacer width={5} />
                <div>
                  <CustomLabel label="Cost of Labour (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, labourCost: e?.target?.value })
                    }}
                  />
                </div>
                <Spacer width={5} />
                <div>
                  <CustomLabel label="Cost of External Services (USD)" />
                  <Input
                    type="text"
                    onChange={(e: any) => {
                      setValue({ ...values, externalCost: e?.target?.value })
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <Spacer height={20} />
          <p>
            Economic Value:{' '}
            <strong>
              {values?.productionLoss === 'Yes' ? (
                <>
                  {calculate > 0 && calulate()} ({calculateCategory()})
                </>
              ) : (
                <>0 (Negligible Effect)</>
              )}
            </strong>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            onClick={() => {
              values?.productionLoss === 'Yes'
                ? handleChange(
                    calulate(),
                    score(),
                    // getRiskMatrixValue(pof || '30 Years'),
                    calculateCategory()
                  )
                : handleChange(0, 1, 'Negligible Effect')

              setIsOpen(false)
              setValue({
                productionLoss: '',
                howLong: 0,
                hourlyLoss: 0,
                failureLossHours: 0,
                spareCost: 0,
                labourCost: 0,
                externalCost: 0
              })
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default function RcmViewStage5() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})
  const [isOpen, setIsOpen]: any = useState(false)

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const failureModes = currentAnalysisData?.failuresModes || []

  const [entries, setEntries] = useState(
    failureModes.reduce((acc: any, mode: any) => {
      acc[mode.name] = mode.failures.map((failure: any) => ({
        functionalFailure: failure.functionalFailure || '',
        failureMode: failure.failureMode || '',
        failureEffect: failure.failureEffect || '',
        healthSafety: failure.healthSafety || '',
        environment: failure.environment || '',
        pof: failure.pof || '',
        economic: failure.economic || ''
      }))
      return acc
    }, {})
  )

  useEffect(
    () => {
      setEntries(
        failureModes.reduce((acc: any, mode: any) => {
          acc[mode.name] = mode.failures.map((failure: any) => ({
            functionalFailure: failure.functionalFailure || '',
            failureMode: failure.failureMode || '',
            failureEffect: failure.failureEffect || '',
            healthSafety: failure.healthSafety || '',
            environment: failure.environment || '',
            pof: failure.pof || '',
            economic: failure.economic || ''
          }))
          return acc
        }, {})
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data?.analysis?.failuresModes
  )

  const sortEntried = () => {
    const sortedEntries = { ...entries }

    Object.keys(sortedEntries).forEach((key) => {
      sortedEntries[key] = sortedEntries[key].sort(
        (a: any, b: any) => Number(b.riskScore) - Number(a.riskScore)
      )
    })

    setEntries(sortedEntries)
  }

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const updatedEntries = [...entries[categoryKey]]
    updatedEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: updatedEntries }))
  }

  const calculateScore = (eScore: any, hScore: any, ecScore: any) =>
    Number(eScore) + Number(hScore) + Number(ecScore)

  const RenderEconomic = ({ score, value }: { value: any; score: any }) => {
    const getColor = () => {
      if (score >= 1 && score <= 6) {
        return NO_RISK_COLOR
      } else if (score >= 7 && score <= 15) {
        return MEDIUM_RISK_COLOR
      } else if (score >= 16 && score <= 25) {
        return HIGH_RISK_COLOR
      } else {
        return '#fff'
      }
    }

    return (
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: 60,
          height: 'auto',
          backgroundColor: getColor(),
          cursor: 'pointer',
          color: '#000'
        }}
      >
        {value}
      </Col>
    )
  }

  const updateRiskScore = (
    enScore: any,
    ecScore: any,
    hScore: any,
    mode: any,
    index: any
  ) => {
    const score = calculateScore(enScore, ecScore, hScore)
    handleInputChange(mode.name, index, 'riskScore', score)
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {failureModes.map((mode: any, modeIndex: number) => (
              <div key={modeIndex}>
                <h4>{mode.name}</h4>
                <p className="m-0">
                  <strong>Associated Equipment:</strong>{' '}
                  {mode?.tags?.map((a: any) => a?.equipmentNumber)?.join(', ')}{' '}
                </p>
                <p>
                  <strong>Functions:</strong>{' '}
                  {currentAnalysisData?.functions?.[mode?.name]}
                </p>
                <Table striped className="text-center">
                  <thead>
                    <tr style={{ backgroundColor: 'lightgray' }}>
                      <th className="top-left">S.No</th>
                      <th>Functional Failure</th>
                      <th>Failure modes</th>
                      <th>Failure Effect</th>
                      <th>MTBF</th>
                      <th>Health / Safety</th>
                      <th>Score</th>
                      <th>Environment</th>
                      <th>Score</th>
                      <th>Economic</th>
                      <th className="top-right">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries[mode.name]?.map((entry: any, index: number) => (
                      <tr key={index}>
                        <td style={{ width: '5%', alignContent: 'center' }}>
                          {index + 1}
                        </td>
                        <td style={{ width: '10%' }}>
                          {entry.functionalFailure}
                        </td>
                        <td style={{ width: '10%' }}>{entry.failureMode}</td>
                        <td style={{ width: '10%' }}>{entry.failureEffect}</td>
                        <td style={{ width: '10%', alignContent: 'center' }}>
                          <Input
                            type="select"
                            onChange={(e) => {
                              handleInputChange(
                                mode.name,
                                index,
                                'mtbf',
                                e?.target?.value
                              )

                              handleInputChange(
                                mode.name,
                                index,
                                'pof',
                                calculatePof(mtbfOptions[e?.target?.value])
                              )
                            }}
                          >
                            <option>Select</option>
                            {Object.keys(mtbfOptions)?.map((h: any) => (
                              <option key={h}>{h}</option>
                            ))}
                          </Input>
                        </td>
                        <td style={{ width: '10%', alignContent: 'center' }}>
                          <RcmRiskMatrix
                            title="HEALTH & SAFETY"
                            values={entry?.healthSafety}
                            score={entry?.healthSafetyScore}
                            riskMatrix={healthRiskMatrix}
                            pof={entry?.pof}
                            onChange={(score: any, consequence: any) => {
                              handleInputChange(
                                mode.name,
                                index,
                                'healthSafety',
                                consequence
                              )
                              handleInputChange(
                                mode.name,
                                index,
                                'healthSafetyScore',
                                score
                              )
                              updateRiskScore(
                                entry?.environmentScore,
                                entry?.economicScore,
                                entry?.healthSafetyScore,
                                mode,
                                index
                              )
                            }}
                          />
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: 24, alignContent: 'center' }}
                        >
                          {entry?.healthSafetyScore}
                        </td>
                        <td style={{ width: '10%', alignContent: 'center' }}>
                          <RcmRiskMatrix
                            title="ENVIRONMENT"
                            pof={entry?.pof}
                            values={entry?.environment}
                            score={entry?.environmentScore}
                            riskMatrix={environmentRiskMatrix}
                            onChange={(value: any, text: any) => {
                              handleInputChange(
                                mode.name,
                                index,
                                'environment',
                                text
                              )
                              handleInputChange(
                                mode.name,
                                index,
                                'environmentScore',
                                value
                              )
                              updateRiskScore(
                                entry?.environmentScore,
                                entry?.economicScore,
                                entry?.healthSafetyScore,
                                mode,
                                index
                              )
                            }}
                          />
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: 24, alignContent: 'center' }}
                        >
                          {entry?.environmentScore}
                        </td>
                        <td
                          style={{ width: '10%', alignContent: 'center' }}
                          onClick={() =>
                            setIsOpen({
                              mode: mode,
                              index: index,
                              entry: entry
                            })
                          }
                        >
                          {entry?.econonmic ? (
                            <RenderEconomic
                              score={entry?.economicScore}
                              value={
                                <>
                                  <p className="m-0">{entry?.econonmic}</p>
                                  <p className="m-0">
                                    Value = {entry?.economicEffect}
                                  </p>
                                </>
                              }
                            />
                          ) : (
                            <Link
                              onClick={() =>
                                setIsOpen({
                                  mode: mode,
                                  index: index,
                                  entry: entry
                                })
                              }
                            >
                              Calculate Economic
                            </Link>
                          )}
                        </td>
                        <td
                          className="text-center"
                          style={{ fontSize: 24, alignContent: 'center' }}
                        >
                          {entry?.economicScore}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
          {currentAnalysisData?.status === 'Stage5' && (
            <ModalFooter>
              <Button color="warning" onClick={() => sortEntried()}>
                Sort by Tolerance
              </Button>
              <Button color="warning" onClick={() => setSubmit(true)}>
                Submit
              </Button>
            </ModalFooter>
          )}
          <CalculateProductionLoss
            isOpen={isOpen}
            pof={isOpen?.entry?.pof}
            mtbf={isOpen?.entry?.mtbf}
            setIsOpen={(e: any) => setIsOpen(e)}
            handleChange={async (loss: any, score: any, category: any) => {
              await handleInputChange(
                isOpen?.mode?.name,
                isOpen?.index,
                'economicEffect',
                loss
              )
              await handleInputChange(
                isOpen?.mode?.name,
                isOpen?.index,
                'economicScore',
                Number(score)
              )
              await handleInputChange(
                isOpen?.mode?.name,
                isOpen?.index,
                'econonmic',
                category
              )

              await updateRiskScore(
                isOpen?.entry?.environmentScore,
                Number(score),
                isOpen?.entry?.healthSafetyScore,
                isOpen?.mode,
                isOpen?.index
              )
            }}
          />
          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              e?.preventDefault()

              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage6'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Failure Modes"
          />
        </Col>
      </Row>
    </RcmLayout>
  )
}
