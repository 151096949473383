import React, { useState } from 'react'
import axios from 'axios'
import RcmLayout from '../../components/rcm/RcmLayout'
import { RCMButton } from '../rcm/RcmComponents'
import { theme } from '../rcm/constants/brandkit'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalTitle from '../../components/ModalTitle'
import FailureModes from './FailureModes'

const ChatGPT = () => {
  const [input, setInput] = useState('')
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: 'Hi! I’m Clamp AI. What can I help you with today?'
    }
  ])
  const [loading, setLoading] = useState(false)
  const [modal, seModal] = useState(false)

  const handleInputChange = (e: any) => setInput(e.target.value)

  const handleSendMessage = async () => {
    if (!input.trim()) return
    const userMessage = { role: 'user', content: input }
    setMessages((prev) => [...prev, userMessage])
    setLoading(true)

    try {
      const res = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [...messages, userMessage]
          // max_tokens: 500,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_CHAT_API}`
          }
        }
      )

      const aiMessage = {
        role: 'assistant',
        content: res.data.choices[0].message.content
      }
      setMessages((prev) => [...prev, aiMessage])
    } catch (error) {
      console.error('Error fetching AI response:', error)
      setMessages((prev) => [
        ...prev,
        {
          role: 'assistant',
          content: 'Something went wrong. Please try again.'
        }
      ])
    } finally {
      setInput('')
      setLoading(false)
    }
  }

  const renderMessageContent = (content: string) => {
    // Detect Markdown table
    if (content.includes('|') && content.includes('---')) {
      const rows = content.trim().split('\n')
      const headers = rows[0]
        .split('|')
        .filter(Boolean)
        .map((col) => col.trim())
      const body = rows.slice(2).map((row) =>
        row
          .split('|')
          .filter(Boolean)
          .map((col) => col.trim())
      )

      return (
        <table style={styles.table}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} style={styles.tableHeader}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} style={styles.tableCell}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    // Regular text fallback
    return <p>{content}</p>
  }

  return (
    <RcmLayout>
      <div style={styles.container}>
        <h3 style={styles.header}>
          <img
            src={require('./../../images/rcm ai.jpeg')}
            alt="rcmai"
            style={{ height: 50 }}
          />
        </h3>
        <div style={styles.chatBox}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                ...styles.message,
                alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor:
                  msg.role === 'user' ? theme.colors.primaryLight : '#f1f1f1'
              }}
            >
              {renderMessageContent(msg.content)}
            </div>
          ))}
        </div>
        <div style={styles.inputArea}>
          <textarea
            placeholder="Type your message..."
            value={input}
            onChange={handleInputChange}
            style={styles.textarea}
          />
          <RCMButton onClick={handleSendMessage} disabled={loading}>
            {loading ? 'Thinking...' : 'Send'}
          </RCMButton>
          <RCMButton onClick={() => seModal(true)} disabled={loading}>
            Failure Modes
          </RCMButton>
        </div>
      </div>
      <Modal fullscreen isOpen={modal}>
        <ModalBody>
          <ModalTitle title="Upload Excelsheet" />
          <FailureModes />
        </ModalBody>
        <ModalFooter>
          <RCMButton onClick={() => seModal(false)}>Close</RCMButton>
        </ModalFooter>
      </Modal>
    </RcmLayout>
  )
}

const styles = {
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '80vh',
    justifyContent: 'space-between'
  },
  header: {
    textAlign: 'center' as const,
    width: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '20px'
  },
  chatBox: {
    flex: 1,
    overflowY: 'auto' as const,
    backgroundColor: theme.colors.white,
    borderRadius: '10px',
    padding: '10px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '10px'
  },
  message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    wordWrap: 'break-word' as const
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse' as const,
    marginTop: '10px'
  },
  tableHeader: {
    backgroundColor: theme.colors.primaryLight,
    color: '#fff',
    padding: '8px',
    border: '1px solid #ddd',
    textAlign: 'left' as const
  },
  tableCell: {
    padding: '8px',
    border: '1px solid #ddd'
  },
  inputArea: {
    display: 'flex',
    gap: '10px'
  },
  textarea: {
    flex: 1,
    height: '50px',
    borderRadius: '10px',
    padding: '10px',
    border: '1px solid #ccc',
    resize: 'none' as const
  }
}

export default ChatGPT
