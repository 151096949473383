import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import CustomLabel from '../../components/InputFields/CustomLabel'
import RcmLayout from '../../components/rcm/RcmLayout'
import RcmStepper from './RcmStepper'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { RcmBasicDetails } from './RcmViewStage1'
import { SubHeader } from '../VerifyCM'
import { getRcmRiskColor } from './rcmLib'
import {
  compressorRTL,
  compressotTL,
  existingTaksList,
  instrumentRTL,
  lubricationRTL,
  lubricationTL,
  motorRTL,
  motorTL,
  receomendedTaskList,
  taskInterval
} from './rcmConstants'
import { RcmDecissionChart } from './RcmDecissionChartSVG'
import { LoadingState } from './RcmViewStage4'
import ModalTitle from '../../components/ModalTitle'
import { theme } from './constants/brandkit'
import { RCMButton, RcmHeader } from './RcmComponents'

const NewTaskModal = ({
  formModal,
  setFormModal,
  onSave
}: {
  formModal: any
  setFormModal: any
  onSave: any
}) => {
  const [task, setTask]: any = useState({})
  return (
    <>
      <Modal isOpen={formModal}>
        <ModalBody>
          <SubHeader permitColor="gold" header="Create New Task" />
          <Col>
            <CustomLabel label="TasK Name" />
            <Input
              type="text"
              onChange={(e) => setTask({ ...task, name: e?.target?.value })}
              value={task?.name}
            />
          </Col>
          <Col>
            <CustomLabel label="TasK Interval" />
            <Input
              type="select"
              onChange={(e) => setTask({ ...task, interval: e?.target?.value })}
              value={task?.interval}
            >
              <option>Select</option>
              {Object.keys(taskInterval)?.map((interval: any) => (
                <option key={interval} value={interval}>
                  {' '}
                  {interval}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <CustomLabel label="TasK Type" />
            <Input
              type="select"
              onChange={(e) => setTask({ ...task, type: e?.target?.value })}
              value={task?.type}
            >
              <option>Select</option>
              {[
                'Condition Based',
                'Time Based',
                'Operator Monitoring',
                'PM Task',
                'Function Test Task',
                'Inspection Task',
                'Detailed Inspection Task',
                'Calibration Task',
                'Technical Task'
              ]?.map((interval: any) => (
                <option key={interval} value={interval}>
                  {' '}
                  {interval}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <CustomLabel label="TasK Status" />
            <Input
              type="select"
              onChange={(e) => setTask({ ...task, status: e?.target?.value })}
              value={task?.status}
            >
              <option>Select</option>
              {['MODIFY EX', 'EXISTING', 'NEW', 'CLASS']?.map(
                (interval: any) => (
                  <option key={interval} value={interval}>
                    {' '}
                    {interval}
                  </option>
                )
              )}
            </Input>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={() => setFormModal(false)}>
            Close
          </Button>
          <Button
            color="warning"
            onClick={() => {
              onSave(task)
              setTask({})
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const TaskTable = ({
  data,
  selected,
  setSelected,
  selectable = true,
  removeTask
}: {
  data: any
  selected: any
  setSelected: any
  selectable?: any
  removeTask: any
}) => {
  return (
    <Table striped style={{ borderColor: '#000' }}>
      <thead>
        <tr style={{ backgroundColor: theme.colors.table.header }}>
          {selectable && (
            <th className="top-left" style={{ width: 10 }}>
              Select
            </th>
          )}
          <th className={`${!selectable && 'top-left'}`} style={{ width: 10 }}>
            S.No
          </th>
          <th style={{ width: '20vw' }}>Name</th>
          <th style={{ width: '5vw' }}>Interval</th>
          <th
            className={`${selectable && 'top-right'}`}
            style={{ width: '10vw' }}
          >
            Type
          </th>
          {/* <th style={{ width: '8vw' }}>Status</th> */}
          {!selectable && <th className="top-right" style={{ width: '5vw' }} />}
        </tr>
      </thead>
      <tbody>
        {data?.map((task: any, index: any) => (
          <tr key={index}>
            {selectable && (
              <td className="text-center">
                <Input
                  type="checkbox"
                  checked={selected?.some((a: any) => a?.name === task?.name)}
                  onChange={(e) =>
                    e?.target?.checked
                      ? setSelected([...selected, task])
                      : removeTask(task?.name)
                  }
                />
              </td>
            )}
            <td className="text-center">{index + 1}</td>
            <td>{task?.name}</td>
            <td>{task?.interval}</td>
            <td>{task?.type}</td>
            {/* <td>{task?.status}</td> */}
            {!selectable && (
              <td>
                <Link onClick={() => removeTask(task?.name)}>Remove</Link>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const AddTaskModal = ({
  isOpen,
  onClose,
  onSave,
  rcmData,
  mode,
  tags,
  entry,
  index,
  selected,
  setSelected
}: {
  isOpen: any
  onClose: any
  rcmData: any
  mode: any
  tags: any
  entry: any
  index: any
  onSave: any
  selected: any
  setSelected: any
}) => {
  const [formModal, setFormModal] = useState(false)

  const removeTask = (taskName: any) =>
    setSelected(selected?.filter((s: any) => s?.name !== taskName))

  const eTL: any = {
    Compressor: compressotTL,
    Motor: motorTL,
    'Lubrication System': lubricationTL
  }

  const rTL: any = {
    Compressor: compressorRTL,
    Motor: motorRTL,
    'Lubrication System': lubricationRTL,
    'Instrument, general': instrumentRTL
  }

  const riskStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: 60,
    height: 'auto',
    cursor: 'pointer',
    color: '#fff'
  }

  return (
    <Modal isOpen={isOpen} fullscreen>
      <ModalBody>
        <ModalTitle title="Maintenance Strategy Gap Analysis" />
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                border: theme.borders.dataContainer,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '18vh',
                justifyContent: 'center',
                marginRight: 10,
                padding: 10,
                width: 500
              }}
            >
              <p
                className="m-0 small"
                style={{ whiteSpace: 'nowrap', fontSize: 18 }}
              >
                <b>Failure Mode :</b> {entry?.failureMode}
              </p>
              {[
                {
                  key: 'Number',
                  value: rcmData?.number
                },
                {
                  key: 'System',
                  value: mode
                },
                {
                  key: 'Equipment / Maintainable Items',
                  value: tags
                },
                {
                  key: 'Economic Value',
                  value: entry?.economicEffect
                },
                {
                  key: 'Clamp Recommended Task',
                  value: entry?.rcmDecision
                },
                {
                  key: 'Task Type',
                  value: entry?.rcmDecisionCode
                }
              ]?.map(({ key, value }: any) => (
                <p
                  className="m-0 small"
                  style={{ whiteSpace: 'nowrap' }}
                  key={key}
                >
                  <b>{key} :</b> {value}
                </p>
              ))}
            </div>
            <div
              style={{
                border: theme.borders.dataContainer,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '18vh',
                justifyContent: 'center',
                marginRight: 10,
                padding: 10,
                width: 500
              }}
            >
              {[
                {
                  key: 'Zone Classification',
                  value: 'Zone 2'
                },
                {
                  key: 'Threats to the system',
                  value: 'Corrosion'
                },
                {
                  key: 'Interface Systems',
                  value: 'Yes'
                },
                {
                  key: 'Shutdown code',
                  value: 'E-001'
                },
                {
                  key: 'Obsolete status',
                  value: '15 Years'
                },
                {
                  key: 'Compensating Action',
                  value: ' '
                },
                {
                  key: 'Production Profile',
                  value: ' '
                },
                {
                  key: 'Support Function',
                  value: ' '
                },
                {
                  key: 'System Redundancy',
                  value: '3*33%'
                }
              ]?.map(({ key, value }: any) => (
                <p className="m-0 small" key={key}>
                  <b>{key} :</b> {value}
                </p>
              ))}
            </div>
            <div
              style={{
                border: theme.borders.dataContainer,
                borderRadius: 5,
                padding: 10,
                height: '18vh',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Table>
                <thead>
                  <tr
                    className="text-center"
                    style={{ backgroundColor: theme.colors.table.header }}
                  >
                    <th style={{ width: '5vw' }} className="top-left">
                      MTBF
                    </th>
                    <th style={{ width: '10vw' }}>Health / Safety</th>
                    <th style={{ width: '10vw' }}>Environment</th>
                    <th className="top-right" style={{ width: '10vw' }}>
                      Economic
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    fontSize: 16,
                    backgroundColor: theme.colors.gray100
                  }}
                >
                  <tr key={index}>
                    <td
                      className="text-center align-content-center"
                      style={{ fontWeight: 700 }}
                    >
                      {entry?.mtbf}
                    </td>
                    <td>
                      <Col
                        style={{
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.healthSafetyScore)
                          ),
                          ...riskStyles
                        }}
                      >
                        <p className="m-0" style={{ fontWeight: 700 }}>
                          {entry?.healthSafety} - {entry?.healthSafetyScore}
                        </p>
                      </Col>
                    </td>
                    <td>
                      <Col
                        style={{
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.environmentScore)
                          ),
                          ...riskStyles
                        }}
                      >
                        <p className="m-0" style={{ fontWeight: 700 }}>
                          {entry?.environment} - {entry?.environmentScore}
                        </p>
                      </Col>
                    </td>
                    <td>
                      <Col
                        style={{
                          backgroundColor: getRcmRiskColor(
                            Number(entry?.economicScore)
                          ),
                          ...riskStyles
                        }}
                      >
                        <p className="m-0" style={{ fontWeight: 700 }}>
                          {entry?.econonmic} - {entry?.economicScore}
                        </p>
                      </Col>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Spacer height={10} />
        <Row>
          <Col xs={12} md={6}>
            <RcmHeader children="Existing Tasks" />
            <div style={{ height: '30vh', overflow: 'scroll' }}>
              <TaskTable
                data={eTL[mode] || existingTaksList}
                selected={selected}
                setSelected={setSelected}
                removeTask={removeTask}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <RcmHeader children="Recomended Tasks" />
            <div style={{ height: '30vh', overflow: 'scroll' }}>
              <TaskTable
                data={rTL[mode] || receomendedTaskList}
                selected={selected}
                setSelected={setSelected}
                removeTask={removeTask}
              />
            </div>
          </Col>
        </Row>
        <Spacer height={10} />
        <Row>
          <Col>
            <RcmHeader children="Selected Tasks" />
            <div style={{ height: '18vh', overflow: 'scroll' }}>
              <TaskTable
                data={selected}
                selected={selected}
                setSelected={setSelected}
                selectable={false}
                removeTask={removeTask}
              />
            </div>
            <Spacer height={10} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <RCMButton onClick={() => setFormModal(true)}>Add New Task</RCMButton>
        <RCMButton onClick={() => onClose()}>Close</RCMButton>
        <RCMButton onClick={() => onSave(mode, index, selected)}>
          Save
        </RCMButton>
      </ModalFooter>
      <NewTaskModal
        formModal={formModal}
        setFormModal={setFormModal}
        onSave={(task: any) => {
          setSelected([...selected, task])
          setFormModal(false)
        }}
      />
    </Modal>
  )
}

export default function RcmViewStage6() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData }: any = useQuery('fetchRiskMatrix', fetchRiskMatrix)
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const [submit, setSubmit]: any = useState(false)
  const [result, setResult]: any = useState({})
  // const [task, setTask]: any = useState()
  // const [taskType, setTaskType]: any = useState()

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || {}
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment

  const [entries, setEntries] = useState(riskDetails)
  const [taskModal, setTaskModal]: any = useState()
  const [modal, setModal] = useState(false)
  const [selected, setSelected] = useState([])

  const toggleModal = () => setModal(!modal)

  useEffect(() => {
    console.log(Object.keys(entries)?.length)
    if (Object.keys(entries)?.length <= 0) {
      setEntries(riskDetails)
    }
  }, [riskDetails])

  const getScoreStyles: any = (score: any) => ({
    textAlign: 'center',
    backgroundColor: getRcmRiskColor(score)
  })

  const getTags = (mode: any) =>
    categorizedEquipment[mode]?.map((a: any) => a?.equipmentNumber)?.join(', ')

  const removeTask = (category: any, FIndex: any, taskIndex: any) => {
    const updatedEntries = [...entries[category]]
    const currentTasks = updatedEntries[FIndex]?.tasks || []
    const updatedTasks = currentTasks.filter(
      (_: any, index: number) => index !== taskIndex
    )
    updatedEntries[FIndex].tasks = updatedTasks
    setEntries((prev: any) => ({ ...prev, [category]: updatedEntries }))
  }

  const handleInputChange = (
    categoryKey: any,
    index: any,
    field: any,
    value: any
  ) => {
    const updatedEntries = [...entries[categoryKey]]
    updatedEntries[index][field] = value
    setEntries((prev: any) => ({ ...prev, [categoryKey]: updatedEntries }))
  }

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15, maxWidth: '87vw' }}>
        <Col>
          <h4
            style={{ fontWeight: 700 }}
            children={`${hasCurrentRcm ? currentAnalysisData?.number : ''}`}
          />
          <RcmStepper stage={currentAnalysisData?.status} />
          <Spacer height={20} />
          <RcmBasicDetails data={currentAnalysisData} />
          <Spacer height={20} />
          <div
            style={{
              backgroundColor: '#fff',
              margin: 0,
              padding: 10,
              borderRadius: 10
            }}
          >
            {Object.keys(riskDetails).map(
              (mode: any, modeIndex: number) =>
                riskDetails?.[mode]?.some(
                  (entry: any) => Number(entry.riskScore) > riskToleranceLevel
                ) && (
                  <div key={modeIndex}>
                    <h4>{mode}</h4>
                    <p className="m-0">
                      <strong>Associated Equipment: </strong> {getTags(mode)}
                    </p>
                    <p>
                      <strong>Functions:</strong>{' '}
                      {currentAnalysisData?.functions?.[mode]}
                    </p>
                    <Table
                      responsive
                      hover
                      style={{ borderColor: '#000', tableLayout: 'fixed' }}
                    >
                      <thead>
                        <tr
                          style={{ backgroundColor: 'lightgray' }}
                          className="text-center"
                        >
                          <th className="top-left" style={{ width: '3vw' }}>
                            S.No
                          </th>
                          <th style={{ width: '10vw' }}>Failure modes</th>
                          <th style={{ width: '5vw' }}>HS</th>
                          <th style={{ width: '5vw' }}>EV</th>
                          <th style={{ width: '5vw' }}>EC</th>
                          <th style={{ width: '8vw' }}>RCM Decision</th>
                          <th style={{ width: '8vw' }}>Client Decision</th>
                          <th className="top-right" style={{ width: '5vw' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {entries?.[mode]?.map(
                          (
                            {
                              riskScore,
                              failureMode,
                              healthSafetyScore,
                              environmentScore,
                              economicScore,
                              tasks,
                              rcmDecision,
                              clientDecision,
                              healthSafety,
                              environment,
                              econonmic,
                              mtbf,
                              economicEffect,
                              rcmDecisionCode
                            }: any,
                            index: number
                          ) =>
                            Number(riskScore) > riskToleranceLevel && (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className="text-center">{index + 1}</td>
                                  <td>{failureMode}</td>
                                  <td style={getScoreStyles(healthSafetyScore)}>
                                    {healthSafetyScore}
                                  </td>
                                  <td style={getScoreStyles(economicScore)}>
                                    {economicScore}
                                  </td>
                                  <td style={getScoreStyles(environmentScore)}>
                                    {environmentScore}
                                  </td>
                                  <td>
                                    <RcmDecissionChart
                                      decision={rcmDecision}
                                      handleChange={async (
                                        decision: any,
                                        decisionCode: any
                                      ) => {
                                        await handleInputChange(
                                          mode,
                                          index,
                                          'rcmDecision',
                                          `${decision}`
                                        )
                                        await handleInputChange(
                                          mode,
                                          index,
                                          'rcmDecisionCode',
                                          `${decisionCode}`
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      value={clientDecision}
                                      type="select"
                                      onChange={(e: any) =>
                                        handleInputChange(
                                          mode,
                                          index,
                                          'clientDecision',
                                          e?.target?.value
                                        )
                                      }
                                    >
                                      <option>Select</option>
                                      {[
                                        'Scheduled on condition Task',
                                        'Scheduled discard or restoration task',
                                        'Combination of task',
                                        'Redesign Necessary',
                                        'Failure finding Task'
                                      ]?.map((a: any) => (
                                        <option key={a} value={a}>
                                          {a}
                                        </option>
                                      ))}
                                    </Input>
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      color={'warning'}
                                      onClick={() => {
                                        setSelected(
                                          tasks?.map(
                                            ({
                                              taskname,
                                              taskInterval,
                                              taskStatus,
                                              taskType
                                            }: any) => ({
                                              name: taskname,
                                              interval: taskInterval,
                                              type: taskType,
                                              status: taskStatus
                                            })
                                          )
                                        )
                                        setTaskModal({
                                          mode,
                                          index,
                                          entry: {
                                            riskScore,
                                            failureMode,
                                            healthSafetyScore,
                                            environmentScore,
                                            economicScore,
                                            tasks,
                                            rcmDecision,
                                            clientDecision,
                                            healthSafety,
                                            econonmic,
                                            environment,
                                            mtbf,
                                            economicEffect,
                                            rcmDecisionCode
                                          }
                                        })
                                        toggleModal()
                                      }}
                                    >
                                      Add Tasks
                                    </Button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={8}>
                                    <h6>Tasks</h6>
                                    <Table>
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th>Task Name</th>
                                          <th>Task Type</th>
                                          <th>Task Interval</th>
                                          <th>Task Status</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tasks?.length > 0 &&
                                          tasks?.map((t: any, Tindex: any) => (
                                            <tr
                                              key={`${t?.taskname}-${index}-${Tindex}`}
                                            >
                                              <td>{Tindex + 1}</td>
                                              <td>{t?.taskname}</td>
                                              <td>{t?.taskType}</td>
                                              <td>{t?.taskInterval}</td>
                                              <td>{t?.taskStatus}</td>
                                              <td>
                                                <Link
                                                  onClick={() =>
                                                    removeTask(
                                                      mode,
                                                      index,
                                                      Tindex
                                                    )
                                                  }
                                                >
                                                  Delete
                                                </Link>
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                        )}
                      </tbody>
                    </Table>
                    <AddTaskModal
                      isOpen={modal}
                      rcmData={currentAnalysisData}
                      tags={categorizedEquipment?.[taskModal?.mode]
                        ?.map((a: any) => a?.equipmentNumber)
                        ?.join(', ')}
                      mode={taskModal?.mode}
                      index={taskModal?.index}
                      entry={taskModal?.entry}
                      setSelected={setSelected}
                      selected={selected}
                      onClose={() => toggleModal()}
                      onSave={async (mode: any, index: any, selected: any) => {
                        const tasks = selected?.map((task: any) => ({
                          taskname: task?.name,
                          taskType: task?.type,
                          taskStatus: task?.status,
                          taskInterval: task?.interval,
                          givenTaskInterval: task?.interval
                        }))
                        const updatedEntries = [...entries[mode]]
                        updatedEntries[index].tasks = tasks
                        setEntries((prev: any) => ({
                          ...prev,
                          [mode]: updatedEntries
                        }))

                        toggleModal()
                      }}
                    />
                  </div>
                )
            )}
          </div>
          {currentAnalysisData?.status === 'Stage6' && (
            <ModalFooter>
              <Button onClick={() => setSubmit(true)} color="warning">
                Submit
              </Button>
            </ModalFooter>
          )}
          <SubmitModalWithForm
            isOpen={submit}
            onClose={(val: any) => {
              setSubmit(false)
            }}
            onSubmit={async (e: any) => {
              const body: any = {
                ...currentAnalysisData,
                riskDetails: entries,
                status: 'Stage7'
              }

              const res = await makeAuthenticatedRequest(
                `${rcmApi}/rcmAnalysis/${id}`,
                'PATCH',
                body,
                accounts,
                instance
              )

              if (res.status === 'success') {
                await refetch()
                setResult(res?.analysis)
              }

              return res.status
            }}
            sucessView={<p>RCM Number: {result?.number}</p>}
            size="xs"
            header="Failure Modes"
          />
        </Col>
      </Row>
    </RcmLayout>
  )
}
