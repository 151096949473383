import ReactToPrint from 'react-to-print'
import { Button, ModalFooter, Row, Table } from 'reactstrap'
import React, { useRef } from 'react'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import '@fontsource/libre-baskerville' // Defaults to weight 400
import '@fontsource/libre-baskerville/400.css' // Specify weight
import '@fontsource/libre-baskerville/400-italic.css'

import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import RcmLayout from '../../components/rcm/RcmLayout'
import './../../../src/components/css/print.css'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import {
  HIGH_RISK_COLOR,
  LOW_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  NO_RISK_COLOR
} from '../../components/constants/riskMatrixColors'
import { BsGlobe2 } from 'react-icons/bs'
import { getRcmRiskColor } from './rcmLib'
import { theme } from './constants/brandkit'
import Spacer from '../../components/Spacer'

const getRiskColor = (score: any) => {
  if (score >= 1 && score <= 15) {
    return NO_RISK_COLOR
  } else if (score >= 16 && score <= 35) {
    return LOW_RISK_COLOR
  } else if (score >= 36 && score <= 50) {
    return MEDIUM_RISK_COLOR
  } else if (score >= 51 && score <= 75) {
    return HIGH_RISK_COLOR
  } else {
    return '#fff'
  }
}

const getColor = (score: any) => {
  if (score >= 1 && score <= 6) {
    return NO_RISK_COLOR
  } else if (score > 7 && score <= 15) {
    return MEDIUM_RISK_COLOR
  } else if (score >= 16 && score <= 25) {
    return HIGH_RISK_COLOR
  } else {
    return '#fff'
  }
}

export const Header = ({ number, isAban }: any) => {
  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <img
          src={require('./../../images/Clamp_RCM.png')}
          alt="gi-rcm"
          style={{ height: '6vh', width: 'auto' }}
        />
        {isAban && (
          <img
            src={require('./../../images/aban.png')}
            alt="aban"
            style={{ height: '7vh', width: 'auto', marginLeft: 20 }}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <h4 style={{ fontFamily: 'Libre Baskerville', fontWeight: 'bold' }}>
            FMEA REPORT
          </h4>
          <img
            src={require('./../../images/Icons/Corner_Designs.png')}
            alt="gi-rcm"
            style={{ height: 'auto', width: '8vh' }}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: 10,
          backgroundColor: '#e8ac01',
          marginTop: 4,
          clipPath: 'polygon(17% 0%, 100% 0%, 100% 100%,18% 100%)'
        }}
      />
      <div
        style={{
          width: '100%',
          height: 10,
          backgroundColor: '#000',
          paddingLeft: 20,
          marginTop: 3,
          clipPath: 'polygon(18% 0%, 100% 0%, 100% 100%, 19% 100%)'
        }}
      />
    </div>
  )
}

const Footer = ({ data }: { data: any }) => {
  return (
    <div className="print-footer">
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '50%',
              justifyContent: 'space-between'
            }}
          >
            <p className="m-0" style={{ fontSize: 10, fontWeight: 'bold' }}>
              Date Created -{' '}
              <span
                style={{
                  backgroundColor: '#e8ac01',
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 2,
                  fontWeight: 'bold'
                }}
              >
                {getReadableTime(data?.createdAt)}
              </span>
            </p>
            <p className="m-0" style={{ fontSize: 10, fontWeight: 'bold' }}>
              RCM INITIATOR -{' '}
              <span
                style={{
                  backgroundColor: '#e8ac01',
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 2,
                  fontWeight: 'bold'
                }}
              >
                {data?.initiator || 'TOM ANDERSON'}
              </span>
            </p>
          </div>
          <div>
            <div
              style={{
                width: '95%',
                height: 20,
                backgroundColor: '#e8ac01',
                paddingLeft: 4,
                marginTop: 3,
                display: 'flex',
                alignItems: 'center',
                clipPath: 'polygon(0 0%, 94% 0%, 93% 100%, 0% 100%)',
                justifyContent: 'space-between'
              }}
            >
              <p
                className="m-0"
                style={{
                  fontSize: 10,
                  fontFamily: 'Libre Baskerville',
                  color: '#000',
                  fontWeight: 'bold'
                }}
              >
                The analysis presented in the report is based on the guidelines
                of SAE JA1012, "A Guide to the Reliability-Centered Maintenance
                (RCM) Standrard
              </p>
              <p
                className="m-0"
                style={{
                  fontSize: 10,
                  fontFamily: 'Libre Baskerville',
                  color: '#000',
                  display: 'flex'
                }}
              >
                <BsGlobe2 />
                www.e73.ai
              </p>
            </div>
          </div>
        </div>
        <img
          src={require('./../../images/Icons/Logo.png')}
          alt="Logo"
          style={{ height: '6vh', width: 'auto' }}
        />
      </div>
    </div>
  )
}
export const renderData = (
  key: any,
  value: any,
  fontSize = 14,
  left: any,
  right: any
) => (
  <div
    style={{
      marginBottom: 3,
      width: '100%',
      fontSize: fontSize
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <p
        style={{
          width: left,
          margin: 0,
          fontSize: 14,
          fontWeight: 'bold'
          // color: '#e8ac01',
          // fontFamily: 'Libre Baskerville'
        }}
      >
        {key}
      </p>
      <div style={{ display: 'flex', width: right }}>
        <p
          style={{
            margin: 0,
            fontSize: 14,
            textOverflow: 'ellipsis',
            // fontFamily: 'Libre Baskerville',
            marginRight: 15
          }}
        >
          :
        </p>
        <p
          style={{
            margin: 0,
            fontSize: fontSize,
            textOverflow: 'ellipsis',
            // border: '1px solid #000',
            // fontFamily: 'system-ui',
            paddingLeft: 10,
            borderRadius: 2
          }}
        >
          {value}
        </p>
      </div>
    </div>
  </div>
)

export default function RcmAnalysisPrint() {
  const { accounts, instance } = useMsal()
  const { id } = useParams()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis/${id}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch }: any = useQuery('fetchRcm', fetchRcm)

  const fetchRiskMatrix = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/riskMatrix`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: riskData, isLoading }: any = useQuery(
    'fetchRiskMatrix',
    fetchRiskMatrix
  )
  const riskMatrixData = riskData?.riskMatrix?.[0]
  const riskToleranceLevel = Number(riskMatrixData?.riskToleranceLevel) || 16

  const currentAnalysisData = data?.analysis
  const hasCurrentRcm = currentAnalysisData?.number
  const riskDetails = currentAnalysisData?.riskDetails || []
  const categorizedEquipment = currentAnalysisData?.categorizedEquipment
  const componentRef: any = useRef()

  const rcmDetails: any = {
    'Plant Unit': currentAnalysisData?.plantUnit,
    'System Description': currentAnalysisData?.system,
    System: currentAnalysisData?.unit || currentAnalysisData?.plantUnit,
    'Sub System': currentAnalysisData?.subSystem,
    'System Location': currentAnalysisData?.location,
    'Analysis Description': currentAnalysisData?.analysisDescription
  }

  const insights: any = {
    'Maintenance cost reduced in USD': '25,750',
    'Equipment Uptime': '95%',
    'Above Tolerable Risk': 37,
    'Below Tolerable Risk': 10,
    'Revised Criticality': (
      <p style={{ padding: 5, backgroundColor: MEDIUM_RISK_COLOR, margin: 0 }}>
        MEDIUM
      </p>
    )
  }

  const getPageMargins = () => {
    return `@page { margin: 5px 5px 5px 5px !important; }`
  }

  const totalFailureModes = Object.keys(riskDetails)
    ?.map((risk: any) => riskDetails[risk]?.length)
    ?.reduce((acc, curr) => acc + curr, 0)

  const totalFailureTasks: any = Object.keys(riskDetails)?.map((risk: any) => {
    return riskDetails[risk]?.map((f: any) => f?.tasks?.length)
  })

  const totalTasksModes = totalFailureTasks?.[0]?.reduce(
    (acc: any, curr: any) => acc + curr,
    0
  )

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <style>
        {`
          @media print {
            @page {
              size: landscape;
            }
              
            .print-header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            heihgt: 50px;
            text-align: center;
            background: #fff;
            padding: 10px;
            overflow: 'hiddden'
            font-size: 18px;
            z-index: 1000;
          }

            .print-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            text-align: center;
            background: #fff;
            paddingBottom: 20px;
            font-size: 18px;
            z-index: 1000;
          }

          .content {
          }

          }

          :global {
    .patchPrintStyle {
        section {
            display: block !important;
        }
        article {
            height: 100% !important;
        }
        * {
            border: 0 none !important;
        }
    }
}
        `}
        {getPageMargins()}
      </style>

      <div
        ref={componentRef}
        style={{
          overflow: 'hiddden',
          display: 'flex',
          justifyContent: 'space-around',
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: '#fff'
        }}
      >
        <Row style={{ maxWidth: '87vw' }}>
          <Header number={currentAnalysisData?.number} />
          <div className="content">
            <h6
              style={{
                textAlign: 'center',
                fontFamily: 'Libre Baskerville',
                fontWeight: 700,
                // color: '#e8ac01',
                textDecoration: 'underline',
                textUnderlineOffset: 9,
                marginBottom: 10,
                marginTop: 10
              }}
            >
              CLAMPRCM ID: {currentAnalysisData?.number}
            </h6>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{
                width: '50%',
                border: '1px solid #000',
                marginRight: 5,
                borderRadius: 5,
                padding: 10
              }}
            >
              <h6
                style={{
                  fontFamily: 'Libre Baskerville',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textUnderlineOffset: 3,
                  marginBottom: 10
                }}
              >
                RCM BASIC DETAILS :
              </h6>
              {Object.keys(rcmDetails)?.map((k: any) =>
                renderData(k, rcmDetails[k], 14, '40%', '60%')
              )}
            </div>
            <div
              style={{
                width: '50%',
                border: '1px solid #000',
                borderRadius: 5,
                padding: 10
              }}
            >
              <h6
                style={{
                  fontFamily: 'Libre Baskerville',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  textUnderlineOffset: 3,
                  marginBottom: 10
                }}
              >
                POST RCM INSIGHTS :
              </h6>
              {Object.keys(insights)?.map(
                (k: any) => renderData(k, insights[k], 14, '40%', '60%'),
                8
              )}
            </div>
          </div>
          <div>
            <Spacer height={10} />
            <div
              style={{
                border: '1px solid #000',
                borderRadius: 5,
                padding: 12,
                display: 'flex',
                gap: 4
              }}
            >
              {[
                {
                  value: currentAnalysisData?.equipmentList?.length,
                  label: 'Equipments Analysed'
                },
                { value: totalFailureModes, label: 'Failure Modes' },
                { value: totalTasksModes, label: 'Task analysed' },
                { value: '12%', label: 'cost Optimized' }
              ]?.map((a: any) => (
                <div
                  style={{
                    height: '15vh',
                    width: '25%',
                    border: '1px solid #000',
                    borderRadius: 5
                  }}
                >
                  <div
                    style={{
                      height: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 48,
                        fontWeight: 700
                      }}
                    >
                      {a?.value}
                    </p>
                  </div>
                  <div
                    style={{
                      height: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.colors.primaryLight
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 18,
                        fontWeight: 700,
                        textAlign: 'center'
                      }}
                    >
                      {a?.label?.toUpperCase()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h6
            style={{
              fontFamily: 'Libre Baskerville',
              fontWeight: 'bold',
              textDecoration: 'underline',
              textUnderlineOffset: 3,
              marginTop: 10
            }}
          >
            TEAM MEMBERS :
          </h6>
          <Table
            className="min-vh-50"
            style={{
              fontSize: 12,
              borderCollapse: 'separate',
              borderSpacing: 3
            }}
          >
            <thead>
              <tr className="text-center">
                {['S.No', 'Name', 'Emp No', 'Department', 'Designation']?.map(
                  (h: any, ThIndex: any) => (
                    <th
                      key={h}
                      style={{
                        // background: ThIndex % 2 === 0 ? '#000' : '#e8ac01',
                        // color: ThIndex % 2 === 0 ? '#e8ac01' : '#000',
                        background: '#e8ac01',
                        color: '#000',
                        fontFamily: 'Libre Baskerville'
                      }}
                    >
                      {h?.toUpperCase()}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {currentAnalysisData?.team?.map((e: any, Eindex: number) => (
                <tr
                  key={e?.value}
                  style={{
                    fontFamily: 'system-ui'
                  }}
                >
                  {[
                    Eindex + 1,
                    'givenName',
                    'employeeId',
                    'department',
                    'jobTitle'
                  ]?.map((a: any, DIndex: any) => (
                    <td
                      key={DIndex}
                      style={{
                        border: '1px solid #000',
                        color: '#000',
                        textAlign: 'center'
                      }}
                    >
                      {e?.[a] || a}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          {/* <div className="content" style={{ pageBreakBefore: 'always' }}>
            <h6
              style={{
                fontFamily: 'Libre Baskerville',
                fontWeight: 'bold',
                textDecoration: 'underline',
                textUnderlineOffset: 3,
                marginBottom: 10,
                marginTop: 20
              }}
            >
              {'System Boundary Considered :'?.toUpperCase()}
            </h6>
            <Table
              style={{
                fontSize: 12,
                borderCollapse: 'separate',
                borderSpacing: 3
              }}
            >
              <thead>
                <tr className="text-center">
                  {[
                    'S.No',
                    'Eqipment Number',
                    'System Name',
                    'System Number',
                    'Criticality'
                  ]?.map((h: any, ThIndex: any) => (
                    <th
                      key={h}
                      style={{
                        background: '#e8ac01',
                        color: '#000',
                        fontFamily: 'Libre Baskerville',
                        fontWeight: 'bold'
                      }}
                    >
                      {h?.toUpperCase()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {equipmentList?.length > 0 &&
                  equipmentList?.map((e: any, Eindex: any) => (
                    <tr key={e?.equipmentNumber} className="text-center">
                      {[
                        Eindex + 1,
                        'equipmentNumber',
                        'systemName',
                        'systemNumber',
                        'criticality'
                      ]?.map((a: any, DIndex: any) => (
                        <td
                          key={DIndex}
                          style={{
                            border: `1px solid #000`,
                            color: '#000',
                            textAlign: 'center'
                          }}
                        >
                          {e?.[a] || a}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div> */}

          <div className="content" style={{ pageBreakBefore: 'always' }}>
            <h6
              style={{
                fontFamily: 'Libre Baskerville',
                fontWeight: 'bold',
                textDecoration: 'underline',
                textUnderlineOffset: 3,
                marginBottom: 10
              }}
            >
              {'Recommendation :'?.toUpperCase()}
            </h6>
            {Object.keys(riskDetails).map(
              (mode: any, modeIndex: number) =>
                riskDetails?.[mode]?.some(
                  (entry: any) => Number(entry.riskScore) > riskToleranceLevel
                ) && (
                  <div key={modeIndex}>
                    <h4>
                      <strong>{mode}</strong>
                    </h4>
                    <p className="m-0">
                      <strong>Associated Equipment:</strong>{' '}
                      {categorizedEquipment[mode]
                        ?.map((a: any) => a?.equipmentNumber)
                        ?.join(', ')}{' '}
                    </p>
                    <p>
                      <strong>Functions:</strong>{' '}
                      {currentAnalysisData?.functions?.[mode]}
                    </p>

                    {riskDetails[mode]?.map(
                      (failureMode: any, DIndex: number) =>
                        Number(failureMode.riskScore) > riskToleranceLevel && (
                          <>
                            {/* <Table>
                              <tbody>
                                <tr style={{ height: 5, padding: 0 }}>
                                  <td
                                    rowSpan={2}
                                    className="align-content-center text-center"
                                    colSpan={11}
                                    style={{
                                      backgroundColor:
                                        theme.colors.primaryLight,
                                      height: 5,
                                      overflow: 'hidden',
                                      padding: 0
                                    }}
                                  >
                                    <h6 className="m-0">
                                      FAILURE MODE:{' '}
                                      <b>
                                        {failureMode?.failureMode?.toUpperCase()}
                                      </b>
                                    </h6>
                                  </td>
                                </tr>
                                <tr></tr>
                              </tbody>
                            </Table> */}
                            <Table
                              bordered
                              style={{
                                borderColor: '#000',
                                border: '1px solid'
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    rowSpan={2}
                                    className="align-content-center"
                                    style={{ width: '9.6vw' }}
                                  >
                                    <p className="m-0">Failure Mode</p>
                                    <h5 className="m-0">
                                      <b>{failureMode?.failureMode}</b>
                                    </h5>
                                  </td>
                                  <td
                                    colSpan={3}
                                    className="text-center"
                                    style={{
                                      fontWeight: 700,
                                      border: '1px solid #000'
                                    }}
                                  >
                                    {' '}
                                    OLD RISK SCORE
                                  </td>
                                  <td
                                    colSpan={3}
                                    className="text-center"
                                    style={{
                                      fontWeight: 700,
                                      border: '1px solid #000'
                                    }}
                                  >
                                    {' '}
                                    NEW RISK SCORE
                                  </td>
                                </tr>
                                <tr>
                                  {[
                                    {
                                      value: failureMode?.healthSafetyScore,
                                      label: 'HS'
                                    },
                                    {
                                      value: failureMode?.environmentScore,
                                      label: 'EV'
                                    },
                                    {
                                      value: failureMode?.economicScore,
                                      label: 'EC'
                                    },
                                    {
                                      value: failureMode?.newHealthScore,
                                      label: 'HS'
                                    },
                                    {
                                      value: failureMode?.newEnvironmentScore,
                                      label: 'EV'
                                    },
                                    {
                                      value: failureMode?.newEcoScore,
                                      label: 'EC'
                                    }
                                  ]?.map((s: any, index: any) => (
                                    <td
                                      key={`${s}-${s?.label}-${index}`}
                                      style={{ width: '8vw' }}
                                      className="text-center align-content-center"
                                    >
                                      <p
                                        className="m-0"
                                        style={{
                                          textAlign: 'center',
                                          alignContent: 'center',
                                          height: '5vh',
                                          backgroundColor: getRcmRiskColor(
                                            s?.value
                                          ),
                                          color: '#fff',
                                          fontWeight: 900,
                                          border: '1px solid #000',
                                          fontSize: 20
                                        }}
                                      >
                                        {s?.label}: {s?.value}
                                      </p>
                                    </td>
                                  ))}
                                </tr>
                              </tbody>
                            </Table>
                            <Table
                              bordered
                              hover
                              style={{
                                borderColor: '#000',
                                fontSize: 12,
                                pageBreakInside: 'auto',
                                width: '100%'
                                // borderCollapse: 'separate',
                                // borderSpacing: 3
                              }}
                            >
                              <thead
                                style={{
                                  display: 'table-header-group '
                                }}
                              >
                                <tr
                                  className="text-center"
                                  style={{
                                    pageBreakInside: 'avoid',
                                    pageBreakAfter: 'auto'
                                  }}
                                >
                                  {[
                                    // 'Failure Modes',
                                    'Task',
                                    'Task Type',
                                    'Associated Tags',
                                    'Current Interval',
                                    'New Interval',
                                    'Task Status',
                                    'Client Decision',
                                    'Remarks',
                                    'Mtbf'
                                    // 'Old Risk',
                                    // 'New Risk'
                                  ]?.map((h: any, ThIndex: any) => (
                                    <th
                                      key={h}
                                      style={{
                                        background: '#e8ac01',
                                        color: '#000',
                                        fontFamily: 'Libre Baskerville',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {h?.toUpperCase()}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody
                                style={{
                                  display: 'table-footer-group '
                                }}
                              >
                                <>
                                  {failureMode?.tasks?.map(
                                    (task: any, Tindex: any) => (
                                      <tr
                                        className="align-content-center"
                                        key={Tindex}
                                        style={{ verticalAlign: 'center' }}
                                      >
                                        {/* <td
                                    style={{
                                      alignContent: 'center'
                                    }}
                                  >
                                    {failureMode?.failureMode?.toUpperCase()}
                                  </td> */}
                                        <td style={{ width: '14vw' }}>
                                          {task?.taskname}
                                        </td>
                                        <td className="text-center align-content-center">
                                          {task?.taskType}
                                        </td>
                                        <td className="text-center align-content-center">
                                          <ul
                                            style={{
                                              listStyleType: 'none',
                                              margin: 0,
                                              padding: 0
                                            }}
                                          >
                                            {task?.tags?.map((t: any) => (
                                              <li>
                                                {t?.itemNumber ||
                                                  t?.tagNo ||
                                                  t?.equipmentNumber}
                                              </li>
                                            ))}
                                          </ul>
                                        </td>
                                        <td className="text-center align-content-center">
                                          {task?.givenTaskInterval ||
                                            task?.taskInterval}
                                        </td>
                                        <td className="text-center align-content-center">
                                          {task?.recommendedInterval}
                                        </td>
                                        <td className="text-center align-content-center">
                                          {task?.taskStatus}
                                        </td>
                                        <td className="text-center align-content-center">
                                          {task?.clientDecision}
                                        </td>
                                        <td className="text-center">
                                          {task?.remarks}
                                        </td>
                                        <td className="align-content-center">
                                          <p
                                            className="m-0"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            Old: {failureMode?.mtbf}
                                          </p>
                                          <hr className="m-0 p-0" />
                                          <p
                                            className="m-0"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            New: {failureMode?.newMtbf}
                                          </p>
                                        </td>
                                        {/* <td className="align-content-center">
                                          <div
                                            style={{ display: 'flex', gap: 2 }}
                                          >
                                            {[
                                              {
                                                value:
                                                  failureMode?.healthSafetyScore,
                                                label: 'HS'
                                              },
                                              {
                                                value:
                                                  failureMode?.environmentScore,
                                                label: 'EV'
                                              },
                                              {
                                                value:
                                                  failureMode?.economicScore,
                                                label: 'EC'
                                              }
                                            ]?.map((s: any, index: any) => (
                                              <p
                                                key={`${s}-${s?.label}-${index}`}
                                                className="m-0 text-center align-content-center"
                                                style={{
                                                  textAlign: 'center',
                                                  alignContent: 'center',
                                                  height: '5vh',
                                                  backgroundColor:
                                                    getRcmRiskColor(s?.value),
                                                  color: '#fff',
                                                  fontWeight: 600,
                                                  border: '1px solid #FFF',
                                                  padding: 3
                                                }}
                                              >
                                                {s?.label}: {s?.value}
                                              </p>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="align-content-center">
                                          <div
                                            style={{ display: 'flex', gap: 2 }}
                                          >
                                            {[
                                              {
                                                value:
                                                  failureMode?.newHealthScore,
                                                label: 'HS'
                                              },
                                              {
                                                value:
                                                  failureMode?.newEnvironmentScore,
                                                label: 'EV'
                                              },
                                              {
                                                value: failureMode?.newEcoScore,
                                                label: 'EC'
                                              }
                                            ]?.map((s: any, index: any) => (
                                              <p
                                                key={`${s}-${s?.label}-${index}`}
                                                className="m-0 text-center align-content-center"
                                                style={{
                                                  textAlign: 'center',
                                                  alignContent: 'center',
                                                  height: '5vh',
                                                  backgroundColor:
                                                    getRcmRiskColor(s?.value),
                                                  color: '#fff',
                                                  fontWeight: 600,
                                                  border: '1px solid #FFF',
                                                  padding: 3
                                                }}
                                              >
                                                {s?.label}: {s?.value}
                                              </p>
                                            ))}
                                          </div>
                                        </td> */}
                                      </tr>
                                    )
                                  )}
                                </>
                              </tbody>
                            </Table>
                          </>
                        )
                    )}
                  </div>
                )
            )}
          </div>
          <Footer data={currentAnalysisData} />
        </Row>
      </div>
      {currentAnalysisData?.status === 'Stage9' && (
        <ReactToPrint
          bodyClass="patchPrintStyle"
          documentTitle={hasCurrentRcm}
          trigger={() => (
            <ModalFooter style={{ marginTop: 10 }}>
              <Button color="warning" children="Print" />
            </ModalFooter>
          )}
          content={() => componentRef.current}
        />
      )}
    </RcmLayout>
  )
}
