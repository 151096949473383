import {
  Button,
  ButtonProps,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { theme } from './constants/brandkit'
import { SubHeader } from '../VerifyCM'
import { useState } from 'react'
import ModalTitle from '../../components/ModalTitle'

export const RcmSideHeader = ({ children }: { children: any }) => (
  <h4 style={{ fontWeight: 700 }}>{children}</h4>
)

export const RCMButton = ({ ...props }: ButtonProps) => {
  const buttonStyles = {
    backgroundColor: theme.colors.button.background,
    border: theme.borders.button,
    color: theme.colors.button.text
  }

  return (
    <Button style={buttonStyles} {...props}>
      {props?.children}
    </Button>
  )
}

export const RcmHeader = ({ children }: { children: any }) => {
  return <SubHeader header={children} permitColor={theme.colors.subHeader} />
}

const DARK_GREEN = '#008000'
const LIGHT_GREEN = '#90EE90'
const YELLOW = '#FFFF00'
const ORANGE = '#FFA500'
const RED = '#FF0000'

const sodColors = (s: any) => {
  const score = Number(s)
  if (score <= 1) {
    return DARK_GREEN
  } else if (score >= 2 && score <= 3) {
    return LIGHT_GREEN
  } else if (score >= 4 && score <= 6) {
    return YELLOW
  } else if (score >= 7 && score <= 8) {
    return ORANGE
  } else if (score >= 8 && score <= 10) {
    return RED
  }
}

const sodLevel = (s: any) => {
  const score = Number(s)
  if (score <= 1) {
    return 'VERY LOW'
  } else if (score >= 2 && score <= 3) {
    return 'LOW'
  } else if (score >= 4 && score <= 6) {
    return 'MODERATE'
  } else if (score >= 7 && score <= 8) {
    return 'HIGH'
  } else if (score >= 8 && score <= 10) {
    return 'CRITICAL'
  }
}

export function TableForm({
  values,
  onSave,
  title,
  headers,
  data
}: {
  values: any
  onSave: any
  title: any
  headers: any
  data: any
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [currentScore, setCurrentScore]: any = useState(values)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      {
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            cursor: 'pointer',
            // backgroundColor: sodColors(values) || 'gray',
            background: `linear-gradient(.25turn,${sodColors(
              values
            )},90% ,#F5F5F5)`,
            opacity: 1,
            fontSize: Boolean(values) ? 22 : 18,
            fontWeight: Boolean(values) ? 900 : 500,
            border: '5px solid #FFF'
          }}
          onClick={() => toggle()}
        >
          {Boolean(values) ? values : 'Add Rating'}
        </Col>
      }
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        style={{ minWidth: '90vw' }}
      >
        <ModalBody>
          <ModalTitle title={title} height={40} />
          <Row>
            <Col xs="12">
              <Table style={{ backgroundColor: theme.colors.gray100 }}>
                <style>
                  {`
                    table {
                      width: 100%;
                      border-spacing: 0; 
                      border-collapse: separate;
                      font-family: Helvetica;
                    }

                    .top-left {
                      border: 2px solid lightgray;
                      border-top-left-radius: 10px;
                    }

                    .top-right {
                      border: 2px solid lightgray;
                      border-top-right-radius: 10px;
                    }
                    `}
                </style>
                <thead>
                  <tr style={{ backgroundColor: theme.colors.table.header }}>
                    {headers?.map((h: any, index: any) => (
                      <th
                        className={`${index === 0 && 'top-left'} ${
                          index === 4 && 'top-right'
                        } `}
                        key={h}
                      >
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data?.map(
                      ({ rating, category, description, example }: any) => (
                        <tr key={rating} className="align-content-center">
                          <td
                            className="text-center align-content-center"
                            style={{
                              cursor: 'pointer',
                              backgroundColor: sodColors(rating),
                              fontSize: 18,
                              fontWeight: 900,
                              border:
                                rating === currentScore
                                  ? '5px solid #000'
                                  : '1px solid #FFF'
                            }}
                          >
                            {sodLevel(rating)}
                          </td>
                          <td className="align-content-center">{category}</td>
                          <td
                            className="align-content-center"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {description}
                          </td>
                          <td>{example}</td>
                          <td
                            className="text-center align-content-center"
                            style={{
                              cursor: 'pointer',
                              backgroundColor: sodColors(rating),
                              fontSize: 22,
                              fontWeight: 900,
                              border:
                                rating === currentScore
                                  ? '5px solid #000'
                                  : '1px solid #FFF'
                            }}
                            onClick={() => {
                              setCurrentScore(rating)
                              onSave(rating)
                              toggle()
                            }}
                          >
                            {rating}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <RCMButton onClick={toggle}>Close</RCMButton>
          <RCMButton
            onClick={() => {
              onSave(currentScore)
              toggle()
            }}
          >
            Save
          </RCMButton>
        </ModalFooter>
      </Modal>
    </>
  )
}
