/* eslint-disable jsx-a11y/anchor-is-valid */
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import { FaRegUser } from 'react-icons/fa'
import { GoGear } from 'react-icons/go'
import { HiHome } from 'react-icons/hi2'
import { MdBorderColor, MdOutlineSpaceDashboard } from 'react-icons/md'
import { Row, Col } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoTicket } from 'react-icons/io5'

function isID(str: any) {
  const idPattern = /^[0-9a-fA-F]{24}$/
  return idPattern.test(str)
}

const crumbs: any = {
  Cmms: 'CMMS',
  Home: <HiHome />,
  Pm: 'PM',
  heCard: 'SHE CARD',
  Rcm: 'RCM',
  Floc: 'FLOC',
  CmmsDashboard: 'CMMS Dashboard',
  incidentManagement: 'Incident Managment',
  Stage1: 'Boundary Selection',
  Stage2: 'BOUNDARY',
  Stage3: 'FUNCTION',
  Stage4: 'FUNCTION FAILURE',
  Stage5: 'RISK',
  Stage6: 'ASSIGN TASK',
  Stage7: 'TASK DECISION',
  Stage8: 'RECOMMENDATION',
  Stage9: 'APPROVED RCM',
  StageA: 'TASK APPROVAL',
  SheCard: 'TSHE Card',
  AshokLeyland: 'Ashok Leyland',
  Mms: 'Gi - MMS',
  Incidentreporting: 'Incident Reporting',
  Tshe: 'TSHE Card',
  Firs: 'FIRS',
  TrainingData: 'Training Data',
  Peptalkawareness: 'Pep Talking Awareness',
  Traininglist: 'Training List',
  Warninglist: 'Warning List',
  UpdateRunningHours: 'Update Running Hours',
  SupplyChain: 'Supply Chain',
  Itemmaster: 'Item Master'
}

export default function Breadcrumbs() {
  const breadcrumbs = useReactRouterBreadcrumbs()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const service = pathname?.split('/')?.[1]

  return (
    <>
      <Row
        className="d-none d-md-flex"
        style={{ margin: 0, borderTop: '2px solid #000' }}
      >
        <Col
          xs="12"
          style={{
            paddingTop: 12,
            paddingBottom: 10,
            display: 'flex',
            width: '100%',
            borderBottom: '2px solid #000'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <div className="breadcrumb flat">
              {breadcrumbs.map(({ match, breadcrumb }: any) => (
                <a
                  className=""
                  key={match.pathname}
                  onClick={() => navigate(match?.pathname)}
                >
                  {crumbs[breadcrumb?.props?.children] ? (
                    crumbs[breadcrumb?.props?.children]
                  ) : isID(breadcrumb?.props?.children) ? (
                    <MdBorderColor />
                  ) : (
                    breadcrumb
                  )}
                </a>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                gap: 10,
                cursor: 'pointer',
                fontWeight: 700,
                fontSize: 18
              }}
            >
              <div
                onClick={() =>
                  service === 'eptw'
                    ? navigate('/eptw/dashboard/main')
                    : navigate('/cmmsDashboard')
                }
              >
                <MdOutlineSpaceDashboard
                  size={25}
                  onClick={() => navigate('/cmmsDashboard')}
                />
                Dashboard
              </div>
              <>
                <FaRegUser size={25} onClick={() => navigate('/myProfile')} />
                My Profile
              </>
              <>
                <IoTicket size={25} onClick={() => navigate('/tickets')} />
                Tickets
              </>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="d-sm-flex d-md-none"
        style={{ margin: 0, borderTop: '2px solid #000' }}
      >
        <Col
          xs="12"
          style={{
            paddingTop: 12,
            paddingBottom: 10,
            display: 'flex',
            width: '100%',
            borderBottom: '2px solid #000'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <div className="breadcrumb flat">
              {breadcrumbs.map(({ match, breadcrumb }: any) => (
                <a
                  className=""
                  key={match.pathname}
                  onClick={() => navigate(match?.pathname)}
                >
                  {crumbs[breadcrumb?.props?.children] ? (
                    crumbs[breadcrumb?.props?.children]
                  ) : isID(breadcrumb?.props?.children) ? (
                    <MdBorderColor />
                  ) : (
                    breadcrumb
                  )}
                </a>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
