import React, { useState } from 'react'
import axios from 'axios'
import * as XLSX from 'xlsx'
import { RCMButton } from '../rcm/RcmComponents'
import Spacer from '../../components/Spacer'

const FailureModes = () => {
  const [file, setFile] = useState<File | null>(null)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
    }
  }

  const handleProcessFile = async () => {
    if (!file) return
    setLoading(true)

    try {
      // Read and parse the Excel file
      const reader = new FileReader()
      reader.onload = async (event) => {
        const binaryStr = event.target?.result
        const workbook = XLSX.read(binaryStr, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
        const parsedData = XLSX.utils.sheet_to_json(sheet)

        setData(parsedData)

        // Process each row with GPT
        const updatedData = await Promise.all(
          parsedData.map(async (row: any) => {
            const response = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: 'gpt-3.5-turbo',
                messages: [
                  {
                    role: 'system',
                    content:
                      'You are an AI expert in failure mode analysis. Generate a failure mode based on the following row data.'
                  },
                  {
                    role: 'user',
                    content: `Row data: ${JSON.stringify(row)}`
                  }
                ],
                max_tokens: 50
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${process.env.REACT_APP_CHAT_API}`
                }
              }
            )

            const failureMode =
              response.data.choices[0]?.message?.content || 'N/A'
            return { ...row, failureMode }
          })
        )

        setData(updatedData)

        // Convert updated data back to Excel
        const worksheet = XLSX.utils.json_to_sheet(updatedData)
        const newWorkbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Updated Data')

        // Create download link
        const excelBuffer = XLSX.write(newWorkbook, {
          bookType: 'xlsx',
          type: 'array'
        })
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream'
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'Updated_Failure_Modes.xlsx'
        a.click()

        setLoading(false)
      }
      reader.readAsBinaryString(file)
    } catch (error) {
      console.error('Error processing file:', error)
      setLoading(false)
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <h3>Failure Modes Generator</h3>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <Spacer height={20} />
      <RCMButton onClick={handleProcessFile} disabled={!file || loading}>
        {loading ? 'Processing...' : 'Generate Failure Modes'}
      </RCMButton>

      {data.length > 0 && (
        <div>
          <h4>Preview of Updated Data:</h4>
          <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map((key, idx) => (
                  <th key={idx}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, idx) => (
                <tr key={idx}>
                  {Object.values(row).map((value: any, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default FailureModes
