import moment from 'moment'
import { Button, Col, ModalFooter, Row, Table } from 'reactstrap'
import { Form } from 'react-final-form'
import { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'

import DropDownFetch from '../../components/InputFields/DropDownFetch'
import LabeledTextInput from '../../components/InputFields/LabeledTextInput'
import LabledParagraphInput from '../../components/InputFields/LabledParagraph'
import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import SubmitModalWithForm from '../../components/Modals/SubmitModalWithForm'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { LabeledDropdown } from '../../ashokleyland/AlViewWo'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi, usersEndpoint } from '../../components/serverurl'
import { renderData } from '../workManagement/PmTree'
import { UserContext } from '../../App'
import { RCMButton, RcmSideHeader } from './RcmComponents'
import { theme } from './constants/brandkit'

export default function RcmCreateAnalysis() {
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()
  const [usersList, setUsersList]: any = useState()
  const [createAnalysis, setCreateAnalysis]: any = useState(false)
  const [formValues, setFormValues]: any = useState({})
  const [result, setResult]: any = useState()

  return (
    <RcmLayout onBack={'/cmms/rcm'}>
      <Row style={{ margin: 15 }}>
        <RcmSideHeader children={'Create Analysis'} />
        <div style={{ backgroundColor: '#fff', borderRadius: 10 }}>
          <Form
            onSubmit={(values) => {}}
            initialValues={{
              ...formValues,
              rcmInitiator: userData?.givenName,
              date: new Date()
            }}
            render={({ form, values }) => (
              <form>
                <Row>
                  <LabeledDropdown
                    name="plantUnit"
                    label="Client Unit"
                    occupy={3}
                    options={[
                      'Aban VIII',
                      'Platform',
                      'Hydrocracker',
                      'FPSO',
                      'FSO',
                      'Drilling Rigs'
                    ]}
                  />
                  <LabeledDropdown
                    name="system"
                    label="System"
                    occupy={3}
                    options={[
                      'Power Generation',
                      'System 23',
                      'System 27',
                      'System 43',
                      'System 68',
                      'System 71'
                    ]}
                  />
                  <LabeledDropdown
                    name="subSystem"
                    label="Sub System"
                    occupy={3}
                    options={[
                      'Main Engine 1',
                      'Main Engine 2',
                      'Main Engine 3',
                      'Main Engine 4'
                    ]}
                  />
                  <LabeledDropdown
                    name="location"
                    label="System Location"
                    occupy={3}
                    options={['Top Side', 'Marine', 'Engine Room']}
                  />
                  <LabeledTextInput
                    name="initiator"
                    label="RCM Initiator"
                    value={userData?.givenName}
                    isDisabled
                    occupy={3}
                  />
                  <LabeledTextInput
                    name="date"
                    label="Date"
                    md={3}
                    sm={12}
                    value={moment().format('YYYY-MM-DD')}
                  />
                  <LabeledDropdown
                    name="type"
                    label="Type"
                    occupy={3}
                    options={['RCM Analysis', 'FMEA']}
                  />
                  <LabledParagraphInput
                    name="analysisDescription"
                    label="Analysis Description"
                    placeholder="Type Analysis Description..."
                    occupy={12}
                    height={120}
                  />

                  <Spacer height={20} />
                  <ModalFooter>
                    <RCMButton
                      color="warning"
                      style={{
                        backgroundColor: '#FAFAD2',
                        border: '2px solid gray'
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setFormValues(values)
                        setCreateAnalysis(true)
                      }}
                    >
                      Select Team Member
                    </RCMButton>
                  </ModalFooter>
                </Row>
                <SubmitModalWithForm
                  isOpen={createAnalysis}
                  onClose={(val: any) => {
                    setCreateAnalysis(false)
                    navigate(`/cmms/rcm/createAnalysis`)
                  }}
                  onSubmit={async (e: any) => {
                    e?.preventDefault()
                    const body: any = {
                      ...values,
                      status: 'Stage2',
                      team: usersList?.map((u: any) => u?.id)
                    }
                    const res = await makeAuthenticatedRequest(
                      `${rcmApi}/rcmAnalysis`,
                      'POST',
                      body,
                      accounts,
                      instance
                    )

                    if (res.status === 'success') {
                      setResult(res?.analysis)
                      console.log(result)
                      setFormValues({})
                      setUsersList()
                      form.reset()
                    }

                    return res.status
                  }}
                  sucessView={
                    <p>
                      RCM Number: <b> {result?.number}</b>
                    </p>
                  }
                  size="lg"
                  header="Create RCM Analysis"
                  form={
                    <>
                      <Col>
                        {renderData(<b>Client Unit</b>, values?.plantUnit)}
                        {renderData(<b>System</b>, values?.system)}
                        {renderData(<b>Sub System</b>, values?.subSytem)}
                        {renderData(<b>System Location</b>, values?.location)}
                        {renderData(<b>RCM Initiator</b>, values?.rcmInitiator)}
                        {renderData(<b>Date</b>, getReadableTime(values?.date))}
                        {renderData(
                          <b>Analysis Description</b>,
                          values?.analysisDescription
                        )}
                      </Col>
                      <Col xs="12">
                        <p className="text-center fw-bold">
                          Select Team Members
                        </p>
                        <DropDownFetch
                          name="usersList"
                          value={usersList}
                          setValue={setUsersList}
                          url={`${usersEndpoint}/filters`}
                          isMulti
                          needSelect={false}
                        />
                      </Col>
                      <Col xs="12">
                        <Spacer height={20} />
                        <style>
                          {`
                              table {
                                width: 100%;
                                border-spacing: 0; 
                                border-collapse: separate;
                                font-family: Helvetica;
                              }

                              .top-left {
                                border: 2px solid lightgray;
                                border-top-left-radius: 10px;
                              }

                              .top-right {
                                border: 2px solid lightgray;
                                border-top-right-radius: 10px;
                              }
                            `}
                        </style>
                        <Table striped>
                          <thead>
                            <tr
                              style={{
                                backgroundColor: theme.colors.table.header
                              }}
                            >
                              <th className="top-left">S.No</th>
                              {['Name', 'Emp No', 'Department']?.map(
                                (h: any) => <th key={h}>{h}</th>
                              )}
                              <th className="top-right">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersList?.map((e: any, Eindex: number) => (
                              <tr key={e?.value}>
                                <td>{Eindex + 1}</td>
                                <td>{e?.value}</td>
                                <td>{e?.employeeId}</td>
                                <td>{e?.department}</td>
                                <td>{e?.jobTitle}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </>
                  }
                />
              </form>
            )}
          />
        </div>
      </Row>
    </RcmLayout>
  )
}
