export const severityData = [
  {
    rating: '1',
    category: 'Insignificant',
    description: 'No noticeable effect on operation or safety.',
    example:
      'Minor equipment cosmetic damage (e.g., paint chipping on non-critical structures).\nNo downtime or safety risk.'
  },
  {
    rating: '2',
    category: 'Very Minor',
    description: 'Slight impact; no repair needed.',
    example:
      'Slight delay in operations, e.g., a sensor reporting intermittent false readings but no operational impact.'
  },
  {
    rating: '3',
    category: 'Minor',
    description: 'Small performance reduction; easily corrected.',
    example:
      'Minor fluid leakage in non-critical lines (e.g., lubrication system).\nEasily rectifiable within routine maintenance.'
  },
  {
    rating: '4',
    category: 'Noticeable',
    description: 'Noticeable degradation in performance.',
    example:
      'Equipment underperformance (e.g., pump delivering reduced flow rate).\nEarly signs of corrosion on marine risers requiring closer monitoring.'
  },
  {
    rating: '5',
    category: 'Moderate',
    description: 'Significant reduction in functionality.',
    example:
      'Temporary halt in non-critical operations, e.g., delay in running casing due to hoist malfunction.\nReduced drilling efficiency due to some equipment or operational issues.'
  },
  {
    rating: '6',
    category: 'Significant',
    description: 'Severe operational disruption; safety concern.',
    example:
      'Critical component failure, such as a generator malfunction causing power limitations.\nDegraded BOP performance requiring immediate repairs'
  },
  {
    rating: '7',
    category: 'Major',
    description: 'System shutdown or major repair required.',
    example:
      'Drill string failure; hours of downtime. Blowout preventer (BOP) valve partial failure leading to a high-pressure leak.\nModerate gas release detected in confined space (minor environmental impact).'
  },
  {
    rating: '8',
    category: 'Critical',
    description: 'Severe impact; serious injuries possible.',
    example:
      'Blowout preventer malfunction; safety at risk.Major equipment damage, e.g., top drive failure stopping all drilling operations.\nMajor fluid loss resulting in well instability.'
  },
  {
    rating: '9',
    category: 'Very Critical',
    description: 'Major hazards; long-term operational impact.',
    example:
      'Structural damage to derrick; injury potential.Large hydrocarbon release causing significant environmental pollution.\nInjury to personnel due to equipment malfunction'
  },
  {
    rating: '10',
    category: 'Catastrophic',
    description: 'Fatalities, environmental disaster, or total loss.',
    example:
      'Well blowout resulting in LTI or environmental disaster (e.g., oil spill).\nComplete rig evacuation due to fire or explosion.'
  }
]?.reverse()

export const occurenceData = [
  {
    rating: '1',
    category: 'Remote',
    description: 'Failure is highly improbable',
    example:
      'Failure has never occurred in similar operations.A new, proven design with no history of failures (e.g., over-engineered drill pipe connection).'
  },
  {
    rating: '2',
    category: 'Very Low',
    description: 'Failure is unlikely.',
    example:
      'Extremely rare, may occur under exceptional conditions. Failure has occurred once in several years across multiple rigs.\nNew equipment with advanced technology and minimal stress during normal operations.'
  },
  {
    rating: '3',
    category: 'Low',
    description: 'Failure is rare.',
    example:
      'Unlikely but possible during extended operation.Minor seal leakage in well-maintained pumps after long service intervals.'
  },
  {
    rating: '4',
    category: 'Moderate-Low',
    description: 'Failure is occasional.',
    example:
      'Rare failure, but credible possibility exists.Corrosion-related valve degradation detected during routine inspections.'
  },
  {
    rating: '5',
    category: 'Moderate',
    description: 'Failure is moderately likely.',
    example:
      'Occasionally observed failure for similar components.Drill bit wear requiring premature replacement.\nIntermittent electrical faults in aging power distribution systems.'
  },
  {
    rating: '6',
    category: 'Moderate-High',
    description: 'Failures are regular',
    example:
      'Occurs under specific but not extreme conditions.Pump bearing failure due to improper lubrication every few months.\nNon-critical hydraulic hose leaks during high-pressure operations.'
  },
  {
    rating: '7',
    category: 'High',
    description: 'Failure happens often',
    example:
      'Frequent failure within expected asset lifespan.Recurring control system malfunctions (e.g., PLC errors) requiring frequent resets.'
  },
  {
    rating: '8',
    category: 'Very High',
    description: 'Failures are frequent.',
    example:
      'Failures common during normal operation.Frequent riser tensioner malfunctions due to extreme environmental forces.'
  },
  {
    rating: '9',
    category: 'Almost Certain',
    description: 'Failure happens almost daily.',
    example:
      'Multiple failures expected annually.Regularly recurring power supply interruptions due to improper load balancing.'
  },
  {
    rating: '10',
    category: 'Certain',
    description: 'Continuous failures',
    example:
      'Failure is inherent to the design or process.Catastrophic equipment breakdown imminent under operating conditions (e.g., structurally compromised components continuing operation despite severe fatigue cracks).'
  }
]?.reverse()

export const detectData = [
  {
    rating: '1',
    category: 'Almost Certain',
    description: 'Easily and immediately detected.',
    example:
      'Highly automated systems with redundant safety alarms.                             Automated sensors trigger alarms for pressure fluctuations in mud circulation systems. Advanced diagnostic tools instantly detect bearing wear.'
  },
  {
    rating: '2',
    category: 'Very High',
    description: 'Detected with high reliability.',
    example:
      'Regular monitoring and early detection through sensors. Routine inspections frequently identify early corrosion on marine risers.'
  },
  {
    rating: '3',
    category: 'High',
    description: '80%–90% chance of detection. Likely to be detected.',
    example:
      'Semi-automated inspection or highly reliable tests.  Vibration analysis identifies misalignment in rotary equipment before significant wear occurs.'
  },
  {
    rating: '4',
    category: 'Moderate-High',
    description:
      '70%–80% chance of detection. Detected during regular maintenance.',
    example:
      'Manual checks combined with periodic inspections. Lubricant degradation detected only during scheduled oil sampling.'
  },
  {
    rating: '5',
    category: 'Moderate',
    description:
      '60%–70% chance of detection. Possible to detect but not reliable.',
    example:
      'Detection through routine but not continuous monitoring. Early signs of structural fatigue on subsea equipment visible during underwater inspections but prone to being overlooked.'
  },
  {
    rating: '6',
    category: 'Moderate-Low',
    description: '50%–60% chance of detection. Unlikely to be detected.',
    example:
      'Detection possible but often missed without targeted checks. Slow hydraulic leaks detected only when performance noticeably declines.'
  },
  {
    rating: '7',
    category: 'Low',
    description: '40%–50% chance of detection. Rarely detected.',
    example:
      'Rarely detected unless failure occurs. Minor cracks in BOP seals detected only during teardown or major overhaul.'
  },
  {
    rating: '8',
    category: 'Very Low',
    description:
      '20%–40% chance of detection. Hard to detect until failure occurs.',
    example:
      'Limited or unreliable detection systems in place. Gradual electrical insulation degradation leading to short circuits, not detected during normal operation.'
  },
  {
    rating: '9',
    category: 'Almost Impossible',
    description: '5%–20% chance of detection. Nearly impossible to detect.',
    example:
      'Detection highly unlikely before the failure. Subsea equipment damage due to external impacts (e.g., dropped objects) unnoticed until failure occurs.'
  },
  {
    rating: '10',
    category: 'Impossible',
    description: '<5% chance of detection. No means of detection.',
    example:
      'No detection mechanisms available. Internal component fracture in a sealed, unmonitored system (e.g., riser bolt failure in inaccessible zones).'
  }
]?.reverse()

export const tabs: any = {
  DETAILS: 'DETAILS',
  STAGE2: 'STAGE 2',
  STAGE3: 'STAGE3',
  PRINT: 'PRINT'
}
