import { Col, Row } from 'reactstrap'
import Spacer from './Spacer'
import { theme } from '../pages/rcm/constants/brandkit'

export default function ModalTitle({
  title,
  height = 40
}: {
  title: string
  height?: number
}) {
  return (
    <>
      <Row
        style={{
          textAlign: 'center',
          backgroundColor: theme.colors.modal.title,
          height: height,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 24,
          margin: 0
        }}
      >
        <b>{title}</b>
      </Row>
      <Spacer height={10} />
    </>
  )
}
