import { table } from 'console'

const colors = {
  gray: 'gray',
  primary: '#A4A3A3',
  white: '#FFFFFF',
  accent: '#FAFAD2',
  black: '#000000',
  lightgray: 'lightgray',
  gray100: '#F5F5F5'
}

export const theme = {
  colors: {
    primary: colors.primary,
    gray: colors.gray,
    primaryLight: colors.primary,
    white: colors.white,
    accent: colors.accent,
    subHeader: colors.primary,
    gray100: colors.gray100,
    modal: {
      title: colors.primary
    },
    button: {
      background: colors.accent,
      text: colors.black
    },
    stepper: {
      completedBgColor: colors.accent,
      activeBgColor: 'green'
    },
    table: {
      header: colors?.lightgray
    }
  },
  corners: {
    sm: 10
  },
  borders: {
    button: `2px solid ${colors.gray}`,
    dataContainer: `2px solid ${colors.gray}`
  },
  units: {
    table: {
      serial: '3vw'
    }
  }
}
