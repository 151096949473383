import { Row, Spinner, Table } from 'reactstrap'
import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import RcmLayout from '../../components/rcm/RcmLayout'
import Spacer from '../../components/Spacer'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { rcmApi } from '../../components/serverurl'
import { theme } from './constants/brandkit'

const analysisStatus: any = {
  Stage2: 'BOUNDARY',
  Stage3: 'FUNCTION',
  Stage4: 'FUNCTION FAILURE',
  Stage5: 'RISK',
  Stage6: 'ASSIGN TASK',
  Stage7: 'TASK DECISION',
  Stage8: 'RECOMMENDATION',
  Stage9: 'APPROVED RCM',
  Fmea: 'FMEA '
}

export default function RcmAnalysisList({
  stage,
  title
}: {
  stage: any
  title: any
}) {
  const { accounts, instance } = useMsal()
  const navigate = useNavigate()

  const fetchRcm = async () => {
    const response = await makeAuthenticatedRequest(
      `${rcmApi}/rcmAnalysis?stage=${stage}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data, refetch, isLoading, isRefetching }: any = useQuery(
    'fetchRcmList',
    fetchRcm
  )

  useEffect(() => {
    refetch()
  }, [stage])

  if (isLoading || isRefetching) {
    return (
      <RcmLayout onBack={'/cmms/rcm/dashboard'}>
        <style>
          {`
          table {
            width: 100%;
            border-spacing: 0; 
            border-collapse: separate;
            font-family: Helvetica;
          }

          .top-left {
            border: 2px solid lightgray;
            border-top-left-radius: 10px;
          }

          .top-right {
            border: 2px solid lightgray;
            border-top-right-radius: 10px;
          }
          `}
        </style>
        <Row style={{ margin: 15 }}>
          <h4 style={{ fontWeight: 700 }}>{title?.toUpperCase()}</h4>
          <div
            style={{
              backgroundColor: '#fff',
              padding: 0,
              margin: 0,
              borderRadius: 10
            }}
          >
            <Table striped style={{ fontFamily: 'Helvetica' }}>
              <thead>
                <tr style={{ backgroundColor: 'lightgray' }}>
                  <th className="top-left">S.No</th>
                  <th>Analysis Number</th>
                  <th>System</th>
                  <th>Unit</th>
                  <th>Location</th>
                  <th>Analysis Description</th>
                  <th>Created on</th>
                  <th className="top-right">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>
                    <div
                      style={{
                        display: 'flex',
                        height: '70vh',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Spinner />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Row>
      </RcmLayout>
    )
  }

  if (!data || data?.analysis?.length === 0) {
    return (
      <RcmLayout onBack={'/cmms/rcm/dashboard'}>
        <style>
          {`
              table {
                width: 100%;
                border-spacing: 0; 
                border-collapse: separate;
              }
              .top-left {
                border: 2px solid lightgray;
                border-top-left-radius: 10px;
              }

              .top-right {
                border: 2px solid lightgray;
                border-top-right-radius: 10px;
              }
          `}
        </style>
        <Row style={{ margin: 15 }}>
          <h4 style={{ fontWeight: 700 }}>{title?.toUpperCase()}</h4>
          <Table striped style={{ borderColor: '#000' }}>
            <thead>
              <tr style={{ backgroundColor: 'lightgray' }}>
                <th className="top-left">S.No</th>
                <th>Analysis Number</th>
                <th>System</th>
                <th>Unit</th>
                <th>Location</th>
                <th>Analysis Description</th>
                <th>Created on</th>
                <th className="top-right">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={12}>
                  <div
                    style={{
                      display: 'flex',
                      height: '40vh',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <h5>No Analysis Found</h5>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </RcmLayout>
    )
  }

  return (
    <RcmLayout onBack={'/cmms/rcm/dashboard'}>
      <Row style={{ margin: 15 }}>
        {/* <SubHeader header={title} permitColor="gold" /> */}
        <style>
          {`
              table {
                width: 100%;
                border-spacing: 0; 
                border-collapse: separate;
              }
              .top-left {
                border: 2px solid lightgray;
                border-top-left-radius: 10px;
              }

              .top-right {
                border: 2px solid lightgray;
                border-top-right-radius: 10px;
              }
          `}
        </style>
        <h4 style={{ fontWeight: 700 }}>{title?.toUpperCase()}</h4>
        <Spacer height={10} />
        <div style={{ height: '80vh', overflow: 'scroll', borderRadius: 10 }}>
          <Table striped style={{ backgroundColor: '#gray' }}>
            <thead style={{ borderRadius: 20, backgroundColor: 'lightgray' }}>
              <tr style={{ borderRadius: 20 }}>
                <th
                  className="top-left text-center"
                  style={{ width: theme.units.table.serial }}
                >
                  S.No
                </th>
                <th>Analysis Number</th>
                <th>System</th>
                <th>Unit</th>
                <th>Location</th>
                <th>Analysis Description</th>
                <th>Created on</th>
                <th className="top-right">Status</th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: '#fff' }}>
              {data?.analysis?.map((a: any, rIndex: any) => (
                <tr key={a?._id}>
                  <td className="text-center">{rIndex + 1}</td>
                  <td>
                    <Link size={16} onClick={() => navigate(a?._id)}>
                      {a?.number}
                    </Link>
                  </td>
                  <td>{a?.system}</td>
                  <td>{a?.plantUnit}</td>
                  <td>{a?.location}</td>
                  <td>{a?.analysisDescription}</td>
                  <td>{getReadableTime(a?.createdAt)}</td>
                  <td>{analysisStatus[a?.status]}</td>
                  {/* <td>{a?.status === stage ? 'Pending' : 'Completed'}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </RcmLayout>
  )
}
