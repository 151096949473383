import { Row } from 'reactstrap'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import RcmSideBar from '../../components/rcm/RcmSidebar'
import { SubHeader } from '../../pages/VerifyCM'

export default function RcmLayout({
  onBack,
  title,
  children,
  tabHeader,
  color = '#fff',
  counts
}: {
  onBack?: any
  title?: any
  children: any
  tabHeader?: any
  color?: any
  counts?: any
}) {
  return (
    <ProtectedLayout onBack={'/cmms/rcm'} backButtonVisible={false}>
      <Row style={{ backgroundColor: 'gray' }}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <RcmSideBar />
          <div
            style={{
              marginLeft: 10,
              width: '100%',
              marginRight: 22,
              minHeight: '88vh',
              backgroundColor: 'gray'
            }}
          >
            {tabHeader && (
              <SubHeader header={tabHeader} permitColor="#FFD700" />
            )}
            <div style={{ height: '85vh', overflow: 'scroll' }}>{children}</div>
          </div>
        </div>
      </Row>
    </ProtectedLayout>
  )
}
